/**
 * Common utility functions
 * Version: 1.0
 * Build: Manual
 */
import {
	AccessorialUnitOfMeasureCd,
	ActionCd,
	AdvanceBeyondTypeCd,
	BillClassCd,
	BillStatusCd,
	BillToRelationCd,
	BondReasonCd,
	BondStatusCd,
	BondTypeCd,
	CarrierTenderStatusCd,
	ChargeToCd,
	ConsigneeUnloadedTrailerCd,
	CountryCd,
	CommodityClassCd,
	CommodityPackageCd,
	CrcAuditCd,
	CubicFeetProfileMthdCd,
	CubicFeetProfileTypeCd,
	CurrencyCd,
	CustomerContactRoleCd,
	CustOperationNoteTypeCd,
	CustomerFunctionCd,
	CustomerPaymentTermsCd,
	DeliveryInfoRequiredCd,
	DeliveryQualifierCd,
	DimensionUomCd,
	EarlyCutoffCd,
	EquipmentTypeCd,
	ExemptReasonCd,
	FoodPoisonCd,
	GeoLocationTypeCd,
	HandlingUnitMovementTypeCd,
	HandlingUnitTypeCd,
	HazmatSourceCd,
	HttpMethod,
	InvoiceCurrencyCd,
	JobStateTypeCd,
	LateTenderCd,
	LineItemChargeToCd,
	MatchedPartySourceCd,
	MatchedPartyStatusCd,
	MatchedPartyTypeCd,
	MiscLineItemCd,
	MiscLineItemPaymentMethodCd,
	MovementExceptionTypeCd,
	MovementStatusCd,
	NetExplosiveMassUomCd,
	NotificationCd,
	OriginalSourceCd,
	PickupRequesterRoleCd,
	PickupShipmentSourceCd,
	PickupShipmentStatusCd,
	PickupSourceCd,
	PickupStatusCd,
	PickupTypeCd,
	RadioactivityUomCd,
	RateRequestTypeCd,
	RatingCurrencyCd,
	RatingPartyLocationTypeCd,
	RouteTypeCd,
	RschCnclReasonCd,
	ServiceCalculationStatusCd,
	ServiceStatusCd,
	ServiceTypeCd,
	ShiftCd,
	ShipmentAcquiredTypeCd,
	ShipmentCreditStatusCd,
	ShipmentDirectionCd,
	ShipmentRemarkTypeCd,
	ShipmentSourceCd,
	ShipmentSpecialServiceCd,
	ShipmentVolumeMethodCd,
	ShipmentVolumeTypeCd,
	ShipperCountryCd,
	ShipperCreditStatusCd,
	ShipperCustomerTypeCd,
	ShipperLoadedTrailerCd,
	ShipperPhoneCountryCd,
	SpecialEquipmentCd,
	StopWindowCd,
	StopWindowNoteTypeCd,
	StrandReasonCd,
	TransactionEventDetailTypeCd,
	WarrantyStatusCd
} from './enum'
export class Utils {
	/**
	 * Reviver for JSON.parse
	 * - Converts dates & timestamps in API response to Date type
	 * - Converts true/false to boolean
	 * Usage: shipment = JSON.parse(apiResponse, Utils.reviver);
	 */
    public static reviver(key, value): any {
        if (typeof key === "string") {
            if ((<string>key).match(".*Timestamp|.*Date")) {
                return new Date(parseInt(value.toString()));
            }
        }

        if (typeof value === "string") {
            if ((<string>value).toLowerCase().match("true")) {
                return true;
            } else if ((<string>value).toLowerCase().match("false")) {
                return false;
            }
        }
       return value;
    }
}

export class FileContents {
	contentType:string = undefined;
	fileName:string = undefined;
	contents: Uint8Array = undefined;
}

export class Envelope<T> {
	/**
	 * The HTTP code for this response. E.g. 200 for Ok, 500 for Internal server error.
	 */
	code:string = undefined;
	/**
	 * Contains the date and time of when this transaction occured. Pass this in the header of PUT and DELETE requests to enable concurrent update checks.
	 */
	transactionTimestamp:Date = undefined;
	/**
	 * Contains the data in this response.
	 */
	data:T = undefined;
	/**
	 * Contains file attachments.
	 */
	attachment:File[] = undefined;
	/**
	 * Contains the error details if there was any failure
	 */
	error:FaultResponse = undefined;
	/**
	 * Contains links to resources
	 */
	link:HyperMedia[] = undefined;
}


/**
 * Master list of partner carriers.  Added 04/01/2021 LISG-11106
 */
export class CarrierMaster {
	/**
	 * Unique identifier
	 */
	carrierId:number = undefined;
	/**
	 * Standard Carrier Alpha Code is a unique two-to-four-letter code useed to identify transporation companies.  They are maintained by the National Motor Freight Traffic Association (NMFTA)
	 */
	scacCd:string = undefined;
	/**
	 * Name of carrier
	 */
	carrierName:string = undefined;
	/**
	 * Same value for CORRELATION id 
	 * 
	 * is assigned to various rows from different tables that form a single business 
	 * 
	 * transactions. 
	 * In other words, this groups multipple rows across various tables for a 
	 * 
	 * single business transaction.
	 */
	correlationId:string = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * Record of the individual shipments that are part of a tender.   Added 06/24/2021  LISG-13075
 */
export class TenderDetail {
	/**
	 * Identifier for the carrier tendered to
	 */
	carrierId:number = undefined;
	/**
	 * Part of Primary key: system generated
	 */
	tenderId:number = undefined;
	/**
	 * Makes this tender detail unique
	 */
	tenderDetailSequenceNbr:number = undefined;
	/**
	 * If an individual shipment was tendered for delivery, this is the shipment id
	 */
	shipmentInstId:number = undefined;
	/**
	 * PRO number/business key of the shipment tendered for delivery
	 */
	proNbr:string = undefined;
	/**
	 * If an individual pickup request was tendered, this the id of that request
	 */
	pkrPickupRequestId:number = undefined;
	/**
	 * If an individual pickup request was tendered, this identifies the individual shipment on the request
	 */
	pkrShipmentId:number = undefined;
	/**
	 * Call number/business key of the tendered pickup request
	 */
	callNbr:string = undefined;
	/**
	 * Status of the tender detail: 
	 * 
	 * Valid Values:
	 * TENDERED 
	 * ACKOWLEDGED 
	 * ACCEPTED
	 * REJECTED
	 * WITHDRAWN
	 */
	statusCd:CarrierTenderStatusCd = undefined;
	/**
	 * Same value for CORRELATION id 
	 * 
	 * is assigned to various rows from different tables that form a single business 
	 * 
	 * transactions. 
	 * In other words, this groups multiple rows across various tables for a 
	 * 
	 * single business transaction.
	 */
	correlationId:string = undefined;
	/**
	 * Provides detailed information about why a shipment tender failed (in XPO systems) or was rejected (by partner carrier).  Added 07/13/2021  LISG-13654
	 */
	statusMessage:string = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * Contains identifying information for a customer account
 */
export class AcctId {
	acctInstId:string = undefined;
	acctMadCd:string = undefined;
	/**
	 * The name of the customer at the detail account level
	 */
	acctName:string = undefined;
	/**
	 * roles for this account. p2D, billTo, corp, supplier, pay agent
	 */
	acctPartyRole:string = undefined;
	/**
	 * Indicates the current state of the customer's credit. It may include bad debt, credit, non-credit, PCO control, voluntary, cash only or non-rated
	 */
	creditStatCd:string = undefined;
	/**
	 * Indicates if the Account is a national account. True or false
	 */
	nationalAccountInd:boolean = undefined;
	/**
	 * unique id of the parent customer account
	 */
	parentAcctInstId:string = undefined;
}
/**
 * Contains identifying information for a customer account
 */
export class AcctIdBasic {
	acctInstId:string = undefined;
	acctMadCd:string = undefined;
	/**
	 * The name of the customer at the detail account level
	 */
	acctName:string = undefined;
}
export class Address {
	/**
	 * When there is a list of addresses, the type will be used to communicate what type of address it is - for example: home, work, headquarters, admin offices, etc., billing. If there is just one, then this field may be empty
	 */
	addressTypeCd:string = undefined;
	/**
	 * Primary name
	 */
	name:string = undefined;
	/**
	 * Applies to mailing address of a person, where the address is actually in another entity's name.
	 */
	careOfName:string = undefined;
	/**
	 * Address line that includes Street name, direction and optionally building number, unit# etc. 
	 * e.g. 1000 SW Broadway st
	 */
	addressLine1:string = undefined;
	/**
	 * Address line that includes Street name, direction and optionally building number, unit# etc. 
	 * e.g. 1000 SW Broadway st
	 */
	addressLine2:string = undefined;
	/**
	 * PO Box typically applies to a mailing address. This is the standard PO Box# of an address.
	 */
	postOfficeBox:string = undefined;
	/**
	 * City Name part of the address.
	 */
	cityName:string = undefined;
	/**
	 * Standard Postal abbreviation for state name. Example: OR for Oregon.
	 */
	stateCd:string = undefined;
	/**
	 * standard Postal abbreviation for a country. Example: CA for Canada.
	 */
	countryCd:string = undefined;
	/**
	 * Postal Code are aka Zip code in USA. US and Mexico use 5 digit postal codes. Canada uses 6 char postal codes.
	 */
	postalCd:string = undefined;
	/**
	 * This is a 4 digit extension to US 5 digit zip code.
	 */
	usZip4:string = undefined;
}
export class Area {
	area:number = undefined;
	areaUom:string = undefined;
}
/**
 * To be used as type for attaching files.
 */
export class Attachment {
	/**
	 * Name of the file.
	 */
	fileName:string = undefined;
	/**
	 * Mime type representing the contents of the file.
	 */
	contentType:string = undefined;
	/**
	 * Base 64 Formatted data.
	 */
	base64Data:string = undefined;
}
export class AuditInfo {
	createdById:string = undefined;
	createdTimestamp:Date = undefined;
	updateById:string = undefined;
	updatedTimestamp:Date = undefined;
	createByPgmId:string = undefined;
	updateByPgmId:string = undefined;
	correlationId:string = undefined;
	createdApplicationId:string = undefined;
	lastUpdateApplicationId:string = undefined;
}
export class Charge {
	/**
	 * linehaul, accessorial, tax, discount, cod, cash ppd, cash coll, part ppd, part coll
	 */
	chargeTypeCd:string = undefined;
	/**
	 * sequence of charge. This is used for accessorials and miscellaneous line items
	 */
	seq:number = undefined;
	/**
	 * a descriptive code identifying the accessorial, tax type, discount, etc. For chargeType codes that have sub codes, this will be used and include the description for them. For example - NotifyOnArrival.
	 */
	chargeCd:string = undefined;
	/**
	 * Expanded Name :  Charge Amount                
	 * 																																														
	 * Desc : The dollar amount of the charge for the
	 * commodity.
	 */
	chargeAmt:MonetaryAmount = undefined;
	/**
	 * Expanded Name : Minimum charge indicator           
	 * 																																																			
	 * Desc : Indicates if the charges associated with the
	 * line are the minimum charges for the commodity.
	 */
	minChargeInd:boolean = undefined;
	/**
	 * True if the charge associated with the accessorial line is the maximum charge
	 */
	maxChargeInd:boolean = undefined;
	/**
	 * The method to calculate the charges including exchangeRate, percentage, flat rate, flat fees, etc.
	 */
	calcMethod:PaymentCalcMethod = undefined;
	/**
	 * A code indicating which party or parties get the revenue from the accessorial - PayToAdvance, PayToPrimary, SplitBetweenAll, SplitAdvancePrimaryOnly, SplitPrimaryBeyondOnly
	 */
	responsibleParty:string = undefined;
	/**
	 * Textual description of charge other than a standard description represented by the description in ChargeTypeCd
	 */
	desc:string = undefined;
	reference:ReferenceNumber = undefined;
}
/**
 * The pair of business identifiers of the current and new child shipments.
 */
export class ChildProNbrReplacement {
	/**
	 * The business identifier of the current child shipment.
	 */
	currentChildProNbr:string = undefined;
	/**
	 * The business identifier of the new child shipment.
	 */
	newChildProNbr:string = undefined;
}
/**
 * The identifying information for a child shipment.
 */
export class ChildShipmentId {
	/**
	 * The unique identifier of the shipment.
	 */
	shipmentInstId:number = undefined;
	/**
	 * The system assigned number which is used to uniquely identify each child PRO of a shipment.
	 */
	sequenceNbr:number = undefined;
	/**
	 * The business identifier for the child shipment.
	 */
	childProNbr:string = undefined;
	/**
	 * The identifying information for the parent shipment.
	 */
	parentShipmentId:ShipmentId = undefined;
}
/**
 * Use this type to represent the delta list of fields that have been changed either as a before list or after list.
 */
export class Code {
	/**
	 * unique DB identifier of the Pricing Code
	 */
	pricingCodeId:string = undefined;
	/**
	 * highest grouping qualifier
	 */
	category:string = undefined;
	/**
	 * grouping wthin category
	 */
	subCategory:string = undefined;
	/**
	 * The string used to identify a code
	 */
	code:string = undefined;
	/**
	 * The description of the code
	 */
	name:string = undefined;
	/**
	 * Optionally a value for this code.
	 */
	value:string = undefined;
	/**
	 * An ordinal for displaying this code on a drop down list.
	 */
	displayOrder:number = undefined;
	/**
	 * effective date of the record
	 */
	effDate:Date = undefined;
	/**
	 * expiry date of the record
	 */
	expDate:Date = undefined;
}
export class Comment {
	/**
	 * Operational Alert, Special Handling Alert, Do not contact
	 */
	commentTypeCd:string = undefined;
	/**
	 * sub-category of comment
	 */
	commentSubTypeCd:string = undefined;
	/**
	 * The text of the comment.
	 */
	note:string = undefined;
	/**
	 * The identity of the entity that entered the comment. This could be an employee id, customer id or a name.
	 */
	enteredBy:string = undefined;
	/**
	 * The timestamp when the comment was created
	 */
	dateTime:Date = undefined;
}
export class CompanyLocation {
	locFullName:string = undefined;
	locAbbrName:string = undefined;
	/**
	 * Standard 3 character timezone - PST PDT, etc.
	 */
	locTimezone:string = undefined;
	locOpenTime:Date = undefined;
	locCloseTime:Date = undefined;
	/**
	 * Hours, minutes, days
	 */
	locationInfo:Location = undefined;
	locContactInfo:ContactInfo[] = undefined;
	locPhone:Phone[] = undefined;
}
/**
 * *** Needs Review The contact information including email, phone and preferences
 */
export class ContactInfo {
	/**
	 * In many cases, the ContactInfoType seems to be all that is needed within transactions. For customer contacts, however, it seems that the ContactType is needed which has more person information. This field will be populated when the ContactInfo type is used as the contact reference instead of the ContactType which has full person information. If the full ContactType is present, then this field need not be populated.
	 */
	contactFirstName:string = undefined;
	contactLastName:string = undefined;
	/**
	 * The title of the contact
	 */
	contactTitle:string = undefined;
	/**
	 * Email Address to communicate with the contact
	 */
	emailAddress:Email[] = undefined;
	/**
	 * Full telephone number with country code, extension etc. Used to communicate with the contact.
	 */
	telephone:Phone[] = undefined;
	/**
	 * IM addresses - Google chat, Microsoft, Chatter, etc.
	 */
	chatIMAddress:NameValue[] = undefined;
	/**
	 * ***Needs review - Does the preference need to have a way of saying - contact me this way for this purpose and this other way for another purpose...? ***The preferred contact method to use - for example email vs. phone. The name in the name value pair should hold the mode and the value, the specific instance within that mode. For example - email/work or phone/mobile or chat/Google
	 */
	contactModePreference:NameValue[] = undefined;
	/**
	 * Web URL. Typically applies to where a contact is a non-person entity such as business.
	 */
	contactWebSiteURL:NameValue = undefined;
}
/**
 * Basic Contact Information including name , email and phone
 */
export class ContactInfoBasic {
	/**
	 * Company Name of the Contact Person
	 */
	companyName:string = undefined;
	/**
	 * Contact email address
	 */
	email:Email = undefined;
	/**
	 * Full Name
	 */
	fullName:string = undefined;
	/**
	 * Contact Phone Number
	 */
	phone:Phone = undefined;
}
/**
 * Information for reporting record counts
 */
export class CountInfo {
	/**
	 * name for data to be counted
	 */
	name:string = undefined;
	/**
	 * description of the data
	 */
	description:string = undefined;
	/**
	 * count for the data
	 */
	count:number = undefined;
}
export class DataValidationError {
	/**
	 * Data validation error code
	 */
	errorCd:string = undefined;
	/**
	 * The message describing the validation error
	 */
	message:string = undefined;
	/**
	 * The name of the parameter or body field that is in error
	 */
	fieldName:string = undefined;
	/**
	 * The value of the parameter or body field that is in error
	 */
	fieldValue:string = undefined;
	/**
	 * Any additional information that will help in troubleshooting this fault. E.g. include any context information such as requesting application, user details, any identifying details from the API request such as customer number, PRO number etc.
	 */
	moreInfo:MoreInfo[] = undefined;
}
export class Density {
	density:number = undefined;
	densityUom:string = undefined;
}
/**
 * Information about the Transit time between origin and destination postal codes
 */
export class TransitTime {
	/**
	 * Destination State Code
	 */
	destStateCd:string = undefined;
	/**
	 * Destination Postal Code
	 */
	destPostalCd:string = undefined;
	/**
	 * Destination service center that is delivering the freight
	 */
	destSicCd:string = undefined;
	/**
	 * Delivery date derived from requested pickup date and transit days
	 */
	estdDlvrDate:Date = undefined;
	/**
	 * If true then guaranteed service is available in this lane
	 */
	garntInd:boolean = undefined;
	/**
	 * Latest date by which the freight should be picked up in order to meet the requested delivery date
	 */
	latestPkupDate:Date = undefined;
	/**
	 * Origin Postal Cd
	 */
	origPostalCd:string = undefined;
	/**
	 * Origin state code
	 */
	origStateCd:string = undefined;
	/**
	 * Origin service center that is picking up the freight
	 */
	origSicCd:string = undefined;
	/**
	 * Delivery Date as requested by customer
	 */
	requestedDlvrDate:Date = undefined;
	/**
	 * Customer requested pickup date. If not provided then defaults to todays date
	 */
	requestedPkupDate:Date = undefined;
	/**
	 * standard service transit (business) days(excluding weekend and holidays) to move from origin to destination.
	 */
	transitDays:number = undefined;
	/**
	 * Earliest date by which the freight should be picked up in order to meet the requested delivery date
	 */
	earliestPkupDate:Date = undefined;
	/**
	 * Notes about the transit time
	 */
	note:string = undefined;
	/**
	 * Set to True if the Requested pickup date is holiday or non-working day
	 */
	isPkupDateHoliday:boolean = undefined;
	/**
	 * Set to True if the Requested Delivery date is holiday or non-working day
	 */
	isrqstdDeliveryDateHoliday:boolean = undefined;
}
/**
 * The replacement for Dimensions.
 */
export class DimensionMeasurement {
	length:number = undefined;
	width:number = undefined;
	height:number = undefined;
	dimensionUomCd:DimensionUomCd = undefined;
}
/**
 * This has been deprecated.  Please use DimensionMeasurement instead.
 */
export class Dimensions {
	length:number = undefined;
	width:number = undefined;
	height:number = undefined;
	dimensionsUom:string = undefined;
}
export class Distance {
	distance:number = undefined;
	/**
	 * miles or kilometers
	 */
	distanceUOM:string = undefined;
	/**
	 * The description of the distance.
	 */
	description:string = undefined;
}
/**
 * Information about the supporting documents
 */
export class Document {
	/**
	 * Indicates if the document is restricted or not
	 */
	restrictedInd:boolean = undefined;
	/**
	 * Type of Document
	 */
	docTypeCd:string = undefined;
	/**
	 * File Name of the document
	 */
	docFile:string = undefined;
	/**
	 * URL Of the document in the DMS
	 */
	docUrl:string = undefined;
	/**
	 * Document folder name in DMS
	 */
	docFolder:string = undefined;
	/**
	 * Name of the document
	 */
	docName:string = undefined;
	/**
	 * content type of the document - pdf, or text or csv, etc
	 */
	contentType:string = undefined;
	/**
	 * size of document stored in DMS
	 */
	docLength:number = undefined;
	/**
	 * create date of document in DMS
	 */
	createdDate:Date = undefined;
	/**
	 * update date of document in DMS
	 */
	modifiedDate:Date = undefined;
	/**
	 * name of the author of the document.
	 */
	docAuthor:string = undefined;
}
/**
 * Information about sending email messages
 */
export class ElectronicMessage {
	/**
	 * bcc'd list of recipients email addresses separated by ;
	 */
	bcc:string = undefined;
	/**
	 * cc'd list of recipients email addresses separated by ;
	 */
	cc:string = undefined;
	/**
	 * sender email address
	 */
	from:string = undefined;
	/**
	 * Name of the sender of the message
	 */
	fromName:string = undefined;
	/**
	 * Email message body
	 */
	messageBody:string = undefined;
	/**
	 * priority of the message
	 * Low, Medium, High
	 */
	priority:string = undefined;
	/**
	 * Timestamp when the message was sent.
	 */
	sentDateTime:Date = undefined;
	/**
	 * subject of email message
	 */
	subject:string = undefined;
	/**
	 * recipients email address separated by ;
	 */
	to:string = undefined;
}
export class Email {
	emailAddr:string = undefined;
	emailTypeCd:string = undefined;
}
/**
 * Identification info for equipment
 */
export class EquipmentId {
	/**
	 * The unique identifier assigned to equipment. The ID is assigned in the EQP component.
	 */
	equipmentInstId:string = undefined;
	/**
	 * The type of equipment - Tractor, Trailer, Dolly, Straight Truck, etc.
	 */
	equipmentTypeCd:string = undefined;
	/**
	 * The prefix for the equipment number.
	 */
	equipmentPrefix:string = undefined;
	/**
	 * The sequencing or serial part of an equipment unit's identifying number.
	 */
	equipmentSuffix:string = undefined;
	/**
	 * The equipment suffix number which is stored to identify foreign trailers that may have been deleted from the system.
	 */
	equipmentIdSuffixNbr:number = undefined;
}
/**
 * Event Hdr Info
 */
export class EventHdr {
	/**
	 * Internal identifier for the event
	 */
	eventInstId:string = undefined;
	/**
	 * Sequence number of the event for a Pro
	 */
	seq:number = undefined;
	/**
	 * Identifies type of event that happened for a shipment
	 */
	eventTypeCd:string = undefined;
	/**
	 * Description  of the event that can be used for display or more information
	 */
	eventDesc:string = undefined;
	/**
	 * Time that the event occurred
	 */
	eventTmst:Date = undefined;
	/**
	 * This event has exception information
	 */
	isException:boolean = undefined;
}
export class ExchangeRateInfo {
	/**
	 * Currency Code in standard format e.g. USD. The exchange rate is to convert from this currentcy.
	 */
	fromCrncyCd:string = undefined;
	/**
	 * Currency Code in standard format e.g. USD. The exchange rate is to convert to this currentcy.
	 */
	toCrncyCd:string = undefined;
	/**
	 * Currency code that is the  basis for both the from and to conversions. For example - from SEK to USD and from USD to EUR
	 */
	baseCrncyCd:string = undefined;
	/**
	 * The exchange rate to convert amount given in fromCrncyCd to toCrncyCd.
	 */
	exchRate:number = undefined;
	/**
	 * The dates on which this exchange rate is effective.
	 */
	exchDate:Date = undefined;
	/**
	 * source of exchange rate
	 */
	src:string = undefined;
}
export class Fault {
	/**
	 * The HTTP error code for this fault. E.g. 404 for a Not Found condition
	 */
	code:string = undefined;
	/**
	 * Internal error code
	 */
	errorCd:string = undefined;
	/**
	 * The message describing the fault
	 */
	message:string = undefined;
	/**
	 * Program trace information to help troubleshoot this fault E.g. The Java Stack trace from the exception.
	 */
	trace:string = undefined;
	/**
	 * Any additional information that will help in troubleshooting this fault. E.g. include any context information such as requesting application, user details, any identifying details from the API request suchs customer number, PRO number etc.
	 */
	moreInfo:string = undefined;
}
/**
 * Information about a field for mapping records from file to DB columns
 */
export class FieldInfo {
	/**
	 * name of the field
	 */
	name:string = undefined;
	/**
	 * description of the field
	 */
	description:string = undefined;
	/**
	 * column number of the field
	 */
	columnNbr:number = undefined;
}
/**
 * Includes details about a field that was modified.
 */
export class FieldValueChange {
	/**
	 * The name of the field that was changed.
	 */
	fieldName:string = undefined;
	/**
	 * The previous value of the field.
	 */
	previousValue:string = undefined;
	/**
	 * The new value of the field.
	 */
	newValue:string = undefined;
	/**
	 * The timestamp for when the change occurred.
	 */
	modificationDateTime:Date = undefined;
	/**
	 * The username of the user who made the change.
	 */
	modifiedBy:string = undefined;
}
/**
 * To be used as type for uploading/downloading files.
 */
export class File {
	/**
	 * Name of the file.
	 */
	fileName:string = undefined;
	/**
	 * Mime type representing the contents of the file.
	 */
	contentType:string = undefined;
}
/**
 * The filter criteria. The different elements on the list represent AND conditions. Different values on the same element represent OR conditions.
 */
export class FilterCriteria {
	stringFilters:FilterString[] = undefined;
	dateFilters:FilterDate[] = undefined;
	numberFilters:FilterNumber[] = undefined;
}
export class FilterString {
	field:string = undefined;
	fields:string[] = undefined;
	values:string[] = undefined;
	between:FilterStringBetween = undefined;
}
export class FilterStringBetween {
	from:string = undefined;
	to:string = undefined;
}
export class FilterDate {
	field:string = undefined;
	fields:string[] = undefined;
	values:Date[] = undefined;
	between:FilterDateBetween = undefined;
}
export class FilterDateBetween {
	from:Date = undefined;
	to:Date = undefined;
}
export class FilterNumber {
	field:string = undefined;
	fields:string[] = undefined;
	values:number[] = undefined;
	between:FilterNumberBetween = undefined;
}
export class FilterNumberBetween {
	from:number = undefined;
	to:number = undefined;
}
/**
 * Deprecated. Use GeoCoordinates so that elastic search geo is enabled.
 */
export class LatLong {
	/**
	 * positive or negative number representing geopoint
	 */
	latitude:number = undefined;
	/**
	 * positive or negative number representing geopoint
	 */
	longitude:number = undefined;
}
/**
 * The geo coordinates for a location.
 */
export class GeoCoordinates {
	/**
	 * The positive or negative number representing geopoint.
	 */
	lat:number = undefined;
	/**
	 * The positive or negative number representing geopoint.
	 */
	lon:number = undefined;
}
export class HyperMedia {
	/**
	 * A relative link to the URI
	 */
	rel:string = undefined;
	/**
	 * The URI to the resource
	 */
	uri:string = undefined;
	/**
	 * Http method: GET, PUT, POST, etc
	 */
	method:string = undefined;
}
/**
 * The list type to be included as parameters in all list APIs. Response types will need to include a field for the totalRowCount
 */
export class ListInfo {
	levelOfDetail:string = undefined;
	/**
	 * page size - number of rows
	 */
	numberOfRows:number = undefined;
	/**
	 * start at row number
	 */
	startAt:number = undefined;
	/**
	 * List of fields for sorting
	 */
	sortFields:SortField[] = undefined;
	/**
	 * List of fields to include in response - if not populated, this will return all fields specified in the response type
	 */
	fields:string[] = undefined;
	/**
	 * The first response will populate this with the total query row count
	 */
	totalRowCount:number = undefined;
	/**
	 * start at row number for ID
	 */
	startAtId:number = undefined;
}
export class Location {
	/**
	 * Consignee, Receiver, Sender, Shipper, Warehouse, DC, Hub, Transit Point,Spot, Drop, Yard, Dock, Port
	 */
	locationTypeCd:string = undefined;
	/**
	 * Primary and alias names and addresses
	 */
	address:Address[] = undefined;
	coordinate:LatLong = undefined;
	note:string[] = undefined;
	reference:ReferenceNumber[] = undefined;
}
/**
 * Basic GeoLocation information
 */
export class GeoLocation {
	/**
	 * City Name part of the address.
	 */
	cityName:string = undefined;
	/**
	 * Standard Postal abbreviation for state name. Example: OR for Oregon.
	 */
	stateCd:string = undefined;
	/**
	 * standard Postal abbreviation for a country. Example: CA for Canada.
	 */
	countryCd:string = undefined;
	/**
	 * Postal Code are aka Zip code in USA. US and Mexico use 5 digit postal codes. Canada uses 6 char postal codes.
	 */
	postalCd:string = undefined;
	/**
	 * Preferred City Name
	 */
	preferredCityName:string = undefined;
}
export class MappingInfo {
	columnIdx:number = undefined;
	columnName:string = undefined;
}
export class MonetaryAmount {
	amt:number = undefined;
	/**
	 * ISO 3 Letter Currency codes
	 */
	currencyCd:string = undefined;
}
export class MoreInfo {
	/**
	 * Internal error code
	 */
	errorCode:string = undefined;
	/**
	 * A message describing the fault in more detail.
	 */
	message:string = undefined;
	/**
	 * Describes the location at which this fault occurred.
	 */
	location:string = undefined;
	/**
	 * The index of the element being described if it is part of an array.
	 */
	itemNbr:number = undefined;
	/**
	 * Has a value related to the error code. E.g. the PRO number on which the error/warning occurred.
	 */
	value:string = undefined;
}
export class NameValue {
	name:string = undefined;
	value:string = undefined;
}
export class PaymentCalcMethod {
	/**
	 * spot quote, rate-based
	 */
	calcMethodTypeCd:string = undefined;
	/**
	 * whatever if being multiplied by unit rate - hours, weight, miles
	 */
	quantity:number = undefined;
	quantityUOM:string = undefined;
	perUnitRate:number = undefined;
	perUnitRateEffDate:Date = undefined;
	exchRateInfo:ExchangeRateInfo = undefined;
	percentageFactor:number = undefined;
}
/**
 * The business identifiers for the child shipment and its new parent shipment.
 */
export class ParentProNbrReplacement {
	/**
	 * The business identifier of the child shipment.
	 */
	childProNbr:string = undefined;
	/**
	 * The business identifier of the new parent for the child shipment.
	 */
	newParentProNbr:string = undefined;
	/**
	 * Indicates whether the shipment has been split for the new parent PRO.
	 */
	splitInd:boolean = undefined;
}
export class Person {
	/**
	 * Full name is concatenation of  title, first, middle and last name. This is a denorm attribute.
	 */
	fullName:string = undefined;
	/**
	 * First name of the person.
	 */
	firstName:string = undefined;
	/**
	 * Either middle name of middle initials
	 */
	middleName:string = undefined;
	/**
	 * Last name or the Sir name of the person.
	 */
	lastName:string = undefined;
	/**
	 * Prefix used with the name such as Dr. Mr. Ms. etc.
	 */
	salutation:string = undefined;
	/**
	 * Title as appropriate including job title, professional title etc.
	 */
	title:string = undefined;
	/**
	 * Various addresses for the person including business, home etc.
	 */
	address:Address[] = undefined;
	/**
	 * email addresses, chat handles, phone numbers and preferences.
	 */
	contactInfo:ContactInfo = undefined;
	/**
	 * Preferered language that should be used to communicate with the person.
	 */
	preferredLanguage:string = undefined;
	/**
	 * Follows a person's full name and provides additional information about the person such as a position, educational degree, accreditation, office, or honor.
	 */
	suffix:string = undefined;
}
export class Phone {
	/**
	 * Daytime, Evening, Mobile, Home, Emergency
	 */
	phoneTypeCd:string = undefined;
	/**
	 * Country code of the full phone#. Example US country code is 1.
	 */
	countryCd:number = undefined;
	/**
	 * When a phone is on an internal company network, these are additional digits to reach a party after dialing the standard phone#.
	 */
	extension:number = undefined;
	/**
	 * This is phone number that might optionally include components such as country code, city code, area in addition to the standard within city phone#. Example: 450-6019 or 503-450-6019 or 1-503-450-6019. The phone# might include various delimiters such as - ( )
	 */
	phoneNbr:string = undefined;
}
/**
 * Contains identifying information for a pickup request.
 */
export class PickupRequestId {
	/**
	 * The unique identifier for the pickup request.
	 */
	pkupInstId:string = undefined;
	/**
	 * The sequence number associated with the pickup request item.
	 */
	pkupItemSeq:string = undefined;
}
/**
 * This type will be used for the management of postal code assignments to sic, network node or sales territory.
 */
export class PostalAssignment {
	/**
	 * US or Canadian postal code
	 */
	postalCode:string = undefined;
	/**
	 * Date assignment was or will be active
	 */
	effectiveDate:Date = undefined;
	/**
	 * Date assignment was or will be expired
	 */
	expirationDate:Date = undefined;
}
/**
 * The ReferenceType type is the type of all reference elements within
 * 								Conway schemas. This type provides a reference attribute, to reference an object
 * 								defined elsewhere.  It does not contain metadata,
 * 								as it does not itself establish the existence of an object; it relies on a definition
 * 								located elsewhere.
 * Data that is commonly understood, but often rendered with different edit masks will be preserved in original format as well as the Conway canonical format. This will be done by repeating the reference number type instance for both the canonical. This is only done for type where there is an industry standard and different ways of representing it, like phone number, etc...For anything where it is specific to domain/client, then the format/value will be preserved.
 * 
 */
export class ReferenceNumber {
	reference:string = undefined;
	referenceDescr:string = undefined;
	/**
	 * PO, Lading number, etc
	 */
	referenceTypeCd:string = undefined;
}
/**
 * This type will be used to return basic location information for service centers
 */
export class ServiceCenterLocation {
	/**
	 * company sic code
	 */
	sic:string = undefined;
	/**
	 * component that the sic is part of
	 */
	component:string = undefined;
	/**
	 * city for the sic
	 */
	cityName:string = undefined;
	/**
	 * 2 character state code
	 */
	stateCd:string = undefined;
	/**
	 * 2 character country code
	 */
	countryCd:string = undefined;
	/**
	 * A generated link that will display the service center location information on the XPO website.
	 */
	locationLink:string = undefined;
	/**
	 * The phone number of the service center
	 */
	phoneNbr:string = undefined;
	/**
	 * The postal code of the service center.
	 */
	postalCd:string = undefined;
	/**
	 * The name of the SIC.
	 */
	sicName:string = undefined;
}
/**
 * The identifying information for a shipment.
 */
export class ShipmentId {
	/**
	 * The uunique identifier of the shipment
	 */
	shipmentInstId:string = undefined;
	/**
	 * The business identifier for the shipment.
	 */
	proNumber:string = undefined;
	/**
	 * The pickup date for the shipment.
	 */
	pickupDate:Date = undefined;
}
/**
 * Information about Imaged Documents for a Pro Number
 */
export class ShipmentImagedDocument {
	/**
	 * unique business identifier of the shipment
	 */
	proNumber:string = undefined;
	/**
	 * type of image: BL (Bill of lading, DR(Delivery Receipt),CUST(Customs document),DRRR(Delivery Return Receipt), IR(Inspection Report), LOA(Letter of Authority),NCIC(NMFC Certificate),WI(Weight and Correction Certificate), WRFO(Weight and Research Photos)
	 */
	imageType:string = undefined;
	/**
	 * image format - pdf, jpg,png, tiff
	 */
	imageFormat:string = undefined;
	/**
	 * image files
	 */
	imageFiles:ShipmentImagedDocument_File[] = undefined;
	/**
	 * Date when the document was scanned and imaged
	 */
	scanDate:Date = undefined;
}
export class ShipmentImagedDocument_File {
	/**
	 * Base 64 Formatted image data.
	 */
	base64Data:string = undefined;
}
export class SortField {
	/**
	 * Name of the column for sort
	 */
	sortByFieldName:string = undefined;
	/**
	 * asc - ascending, desc - descending
	 */
	sortOrder:string = undefined;
}
export class Status {
	/**
	 * Examples: missed pickup, attempted delivery, damaged, disputed, paid short.
	 */
	statusCd:string = undefined;
	/**
	 * Examples: No answer, Office closed, Invalid address, Traffic Jam, Cow crossing, etc.
	 */
	statusReasonCd:string = undefined;
	reason:string = undefined;
	dateTime:Date = undefined;
	description:string = undefined;
	comment:Comment = undefined;
}
export class StatusException {
	/**
	 * Examples: missed pickup, attempted delivery, damaged.
	 */
	exceptionTypeCd:string = undefined;
	/**
	 * Examples: No answer, Office closed, Invalid address, Traffic Jam, Cow crossing, etc.
	 */
	exceptionReasonCd:string = undefined;
	reason:string = undefined;
	/**
	 * Resolution - New, Acknowledged, Resolved, Unresolved, Superseded, In-progress, Assigned, etc.
	 */
	rsltnCd:string = undefined;
	dateTime:Date = undefined;
	description:string = undefined;
	comment:Comment = undefined;
}
/**
 * The ReferenceType type is the type of all reference elements within
 * 								Conway schemas. This type provides a reference attribute, to reference an object
 * 								defined elsewhere.  It does not contain metadata,
 * 								as it does not itself establish the existence of an object; it relies on a definition
 * 								located elsewhere.
 * Data that is commonly understood, but often rendered with different edit masks will be preserved in original format as well as the Conway canonical format. This will be done by repeating the reference number type instance for both the canonical. This is only done for type where there is an industry standard and different ways of representing it, like phone number, etc...For anything where it is specific to domain/client, then the format/value will be preserved.
 * 
 */
export class SupplementalReference {
	/**
	 * PO, Lading number, etc
	 */
	suppRefTypeCd:string = undefined;
	/**
	 * sequence of supplemental reference on shipment
	 */
	seq:number = undefined;
	refValue:string = undefined;
	refDesc:string = undefined;
	sourceCd:string = undefined;
	printIncludeInd:boolean = undefined;
	mask:string = undefined;
	mandatoryInd:boolean = undefined;
}
/**
 * Basic information about the employee
 */
export class EmployeeId {
	/**
	 * User Employee Id
	 */
	employeeId:string = undefined;
	/**
	 * The given name of the Employee
	 */
	employeeName:string = undefined;
}
export class User {
	/**
	 * The ID of the user making this request
	 */
	userId:string = undefined;
	/**
	 * User Employee Id
	 */
	employeeId:string = undefined;
	/**
	 * RACF ID of the user
	 */
	racfId:string = undefined;
	/**
	 * Contain the instance id of the user prifile. Will be populated if user is an external registered user.
	 */
	profileInstId:string = undefined;
	/**
	 * The name to be displayed
	 */
	displayName:string = undefined;
	/**
	 * The given name of the user
	 */
	givenName:string = undefined;
	/**
	 * The last name of the user
	 */
	lastName:string = undefined;
	/**
	 * The job title of the user
	 */
	title:string = undefined;
	/**
	 * The email address of the user
	 */
	emailAddress:string = undefined;
	/**
	 * The mobile phone number of the user
	 */
	mobile:string = undefined;
	/**
	 * The telephone number of the user
	 */
	telephone:string = undefined;
	/**
	 * The address of the user
	 */
	streetAddress:string = undefined;
	/**
	 * Standard Postal abbreviation for state name. Example: OR for Oregon.
	 */
	stateCd:string = undefined;
	/**
	 * standard Postal abbreviation for a country. Example: CA for Canada.
	 */
	countryCd:string = undefined;
	/**
	 * The postal code for the street address
	 */
	postalCode:string = undefined;
	/**
	 * Department of the user
	 */
	department:string = undefined;
	/**
	 * Business unit of employee as defined in the HR system
	 */
	businessUnit:string = undefined;
	/**
	 * A description of the region. E.g. IT LTL Dev
	 */
	regionDescription:string = undefined;
	/**
	 * Employee Manager
	 */
	manager:string = undefined;
	/**
	 * Primary job role code
	 */
	jobRoleCode:string = undefined;
	/**
	 * A list of roles associated with this user
	 */
	roles:string[] = undefined;
	/**
	 * user location sic code
	 */
	sicCode:string = undefined;
}
export class Volume {
	volume:number = undefined;
	volumeUOM:string = undefined;
}
export class Weight {
	weight:number = undefined;
	weightUom:string = undefined;
}
export class GetHealthCheckResp {
	service:string = undefined;
	status:string = undefined;
	build:string = undefined;
}
/**
 * 
 */
export class AccountDetail {
	/**
	 * 
	 */
	acctInstId:number = undefined;
	/**
	 * 
	 */
	acctMadCd:string = undefined;
	/**
	 * 
	 */
	parentAcctInstId:number = undefined;
	/**
	 * 
	 */
	nationalAcctInd:boolean = undefined;
	/**
	 * 
	 */
	name1:string = undefined;
	/**
	 * 
	 */
	name2:string = undefined;
	/**
	 * 
	 */
	creditStatusCode:string = undefined;
	/**
	 * 
	 */
	locationTypeCode:string = undefined;
	/**
	 * 
	 */
	addressLine1:string = undefined;
	/**
	 * 
	 */
	addressLine2:string = undefined;
	/**
	 * 
	 */
	cityName:string = undefined;
	/**
	 * 
	 */
	stateCd:string = undefined;
	/**
	 * 
	 */
	postalCd:string = undefined;
	/**
	 * 
	 */
	countryCd:string = undefined;
	/**
	 * 
	 */
	deletedInd:boolean = undefined;
	/**
	 * 
	 */
	latitudeNbr:number = undefined;
	/**
	 * 
	 */
	longitudeNbr:number = undefined;
	/**
	 * 
	 */
	lastCreatedBy:string = undefined;
	/**
	 * 
	 */
	lastCreatedDateTime:Date = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * 
	 */
	geoCoordinates:LatLong = undefined;
	/**
	 * Geo coordinations
	 */
	geoCoordinatesGeo:GeoCoordinates = undefined;
	/**
	 * 
	 */
	zip4RestUs:string = undefined;
}
export class ConfigParm {
	ruleTypeCd:string = undefined;
	/**
	 * A grouping identifier for parms config parm sets that should be executed today
	 */
	groupID:string = undefined;
	parms:NameValue[] = undefined;
	desc:string = undefined;
	priority:number = undefined;
	seq:number = undefined;
	processContext:string = undefined;
	ruleFunctionName:string = undefined;
}
/**
 * Account Information in the CRM System
 */
export class CrmAccount {
	/**
	 * Database key for customer
	 */
	acctInstId:string = undefined;
	/**
	 * Unique Identifier of the Account in the CRM System
	 */
	accountId:string = undefined;
	/**
	 * Account Name in the CRM System
	 */
	accountName:string = undefined;
	/**
	 * Account Address in the CRM System
	 */
	accountAddress:Address = undefined;
}
/**
 * Account Owner Information in the CRM System
 */
export class CrmAccountOwner {
	/**
	 * Unique Identifier for the employee
	 */
	employeeId:string = undefined;
	/**
	 * First name of the employee
	 */
	firstName:string = undefined;
	/**
	 * Last name of the employee
	 */
	lastName:string = undefined;
	/**
	 * Full name of the employee
	 */
	fullName:string = undefined;
	/**
	 * Job title of the employee
	 */
	title:string = undefined;
	/**
	 * Job profile of the employee
	 */
	profile:string = undefined;
	/**
	 * Name of the role
	 */
	roleName:string = undefined;
}
/**
 * Information about the customer contact and roles
 */
export class CustomerContact {
	/**
	 * Role that is played by the contact person for this customer location function.
	 */
	contactRoleCd:CustomerContactRoleCd = undefined;
	/**
	 * Is this person a primary contact for this customer location function?
	 * Y - YES
	 * N - NO
	 */
	primaryInd:boolean = undefined;
	/**
	 * Is this person for inbound, outbound or both in and outbound freight?
	 * Valid values
	 * INB
	 * OUTB
	 * BOTH
	 */
	inboundOutbCd:string = undefined;
	/**
	 * This is foreign key from Cust Location Function. This identifies the cust location function to which the Contact person is assigned.
	 * Customer location function is identified by MAD Code
	 */
	customerLocationFuncId:number = undefined;
	/**
	 * This is a person belonging to customer that XPO can contact for various activities.
	 */
	contactPerson:ContactPerson = undefined;
	/**
	 * This is internal ID from legacy CIS application. This is required to keep data synced between this database and legacy CIS tables.
	 */
	legacyContactKey:number = undefined;
	/**
	 * Action to be executed.
	 */
	actionCd:ActionCd = undefined;
}
/**
 * Object to map the t_cust_hrchy_table, this will be used for GET_CUST_HRCHY_CHILDREN and GET_CUST_HRCHY_PARENTS DB Functions.
 */
export class CustomerHrchy {
	/**
	 * Mad code
	 */
	madCd:string = undefined;
	/**
	 * Party name 1
	 */
	partyName:string = undefined;
	/**
	 * Party Name 2
	 */
	name2:string = undefined;
	/**
	 * Legacy CIS Customer Number
	 */
	legacyCisCustNbr:number = undefined;
	/**
	 * Function code
	 */
	functionCd:CustomerFunctionCd = undefined;
	/**
	 * Customer Status Cd: ACTIVE, INACTIVE
	 */
	statusCd:string = undefined;
	/**
	 * Address
	 */
	addressTxt:string = undefined;
	/**
	 * City Name
	 */
	cityNm:string = undefined;
	/**
	 * State code
	 */
	stateCd:string = undefined;
	/**
	 * Zip code
	 */
	zipCd:string = undefined;
	/**
	 * Zip4 Code
	 */
	zip4Cd:string = undefined;
	/**
	 * Country code
	 */
	cntryCd:string = undefined;
	/**
	 * Customer location function ID
	 */
	custLocationFuncId:number = undefined;
	/**
	 * Parent corporate customer location function ID
	 */
	parentCorpCustLocFuncId:string = undefined;
	/**
	 * Address ID
	 */
	addresId:number = undefined;
	/**
	 * Party 1 ID
	 */
	party1Id:number = undefined;
	/**
	 * Party 2 ID
	 */
	party2Id:number = undefined;
	/**
	 * Display order serquence
	 */
	displayOrderSeq:number = undefined;
	/**
	 * Hierarchy level number
	 */
	levelNbr:number = undefined;
	/**
	 * Specifies number of days after which an invoice is due.  The due date is generally calculated by adding days associated with the payment term to Pickup Date. E.g. NET30 means 30 days after the pickup date.
	 */
	paymentTermCd:CustomerPaymentTermsCd = undefined;
}
export class CustomerSchedule {
	/**
	 * number of unit
	 */
	frequencyInterval:number = undefined;
	/**
	 * Hours, minutes, days
	 */
	frequencyUnit:string = undefined;
	scheduledTime:Date[] = undefined;
}
export class Instruction {
	instructionTypeCd:string = undefined;
	value:string = undefined;
	role:string = undefined;
	notes:string = undefined;
	schedule:CustomerSchedule[] = undefined;
	suppRef:SupplementalReference[] = undefined;
	documentRequirement:NameValue[] = undefined;
	ruleParameter:ConfigParm[] = undefined;
}
export class InvoicingInstruction {
	/**
	 * This is to distinguish groups of instructions used for generating invoices for a customer. e.g. Prepaid invoices, Past Due invoices etc.
	 */
	invInstrTypeCd:string = undefined;
	/**
	 * Reference by customerAccount ID to the bill to location
	 */
	bil2Account:string = undefined;
	/**
	 * These are the instruction used by the invoicing process to generate invoices. The instructions include the schedule (when to generate invoice e.g. daily for this customer), additional documents that need to be attached, additional data such as Supplimentary references that must be available for the PRO before an invoice can be generated etc.
	 */
	instruction:Instruction[] = undefined;
	/**
	 * The method of transmission of invoices to the customer. Example electronic, EDI, email attachment, pape etc.
	 */
	transmissionMode:string = undefined;
	/**
	 * The format in which the invoice is generated. Example plain text, comma delimited, PDF, html etc. Most commonly used when the transmission mode is electronic. For paper invoices, this can store the name of the template.
	 */
	invoiceFormat:string = undefined;
	/**
	 * This may be Statement or Manifest. If not populated then the invoices are being rendered individually with no summary.
	 */
	consolidatedInvoiceFormat:string = undefined;
	/**
	 * ***What type? For this invoicing mode, use this contact. It will have the name, address or email address or phone. FTP addresses will be in address with an address type of FTP and the ID will be in reference in the AdressType
	 */
	sendTo:Person = undefined;
}
/**
 * Account IDs and address
 */
export class PartyAccountBasic {
	/**
	 * Database key for customer
	 */
	acctInstId:string = undefined;
	/**
	 * Business key for customer
	 */
	acctMadCd:string = undefined;
	/**
	 * Business key for customer
	 */
	acctName:string = undefined;
	/**
	 * Address
	 */
	address:Address = undefined;
}
/**
 * Account IDs and address
 */
export class PartyBasic {
	/**
	 * Database key for customer
	 */
	acctInstId:string = undefined;
	/**
	 * Business key for customer
	 */
	acctMadCd:string = undefined;
	/**
	 * Address
	 */
	address:Address = undefined;
}
/**
 * Account IDs and address
 */
export class PartyContactBasic {
	/**
	 * Database key for customer
	 */
	acctInstId:string = undefined;
	/**
	 * Business key for customer
	 */
	acctMadCd:string = undefined;
	/**
	 * Address
	 */
	address:Address = undefined;
	contactInfo:ContactInfoBasic = undefined;
}
/**
 * This is a person belonging to customer that XPO can contact for various activities. 
 * The contact information is recorded here.
 */
export class ContactPerson {
	/**
	 * System generated internal ID to identify the person.
	 */
	contactPersonId:number = undefined;
	/**
	 * First Name of the Contact person
	 * John
	 */
	firstName:string = undefined;
	/**
	 * Last Name of the Contact person
	 * example
	 * Doe
	 */
	lastName:string = undefined;
	/**
	 * Title held by the person at the customer. 
	 * This is optional
	 * Example:
	 * VICE PRESIDENT MARKETING
	 */
	title:string = undefined;
	/**
	 * Internation calling country code
	 * e.g.
	 * 1 - USA (optional as most of the LTL offices are in US)
	 * 52- Mexico
	 */
	businessPhoneCountryCd:string = undefined;
	/**
	 * Business phone area code. 
	 * example
	 * 503 for Portland
	 */
	businessPhoneAreaCd:string = undefined;
	/**
	 * Business phone number excluding area code.
	 */
	businessPhoneNbr:string = undefined;
	/**
	 * Business Phone Extension
	 */
	businessPhoneExt:string = undefined;
	/**
	 * 
	 */
	altBusinessPhoneCountryCd:string = undefined;
	/**
	 * 
	 */
	altBusinessPhoneAreaCd:string = undefined;
	/**
	 * Alt. Business Phone number
	 * Legacy mapping: C IS_CNTCT_MEDIA.MEDIA_CODE_ID =2
	 */
	altBusinessPhoneNbr:string = undefined;
	/**
	 * 
	 */
	altBusinessPhoneExt:string = undefined;
	/**
	 * 
	 */
	cellPhoneCountryCd:string = undefined;
	/**
	 * 
	 */
	cellPhoneAreaCd:string = undefined;
	/**
	 * Cell phone number
	 * Legacy mapping: C IS_CNTCT_MEDIA.MEDIA_CODE_ID =4
	 */
	cellPhoneNbr:string = undefined;
	/**
	 * 
	 */
	cellPhoneExt:string = undefined;
	/**
	 * Email ID to contact the person
	 */
	emailId:string = undefined;
	/**
	 * 
	 */
	faxAreaCd:string = undefined;
	/**
	 * Fax number for the person.
	 */
	faxNbr:string = undefined;
	/**
	 * Address Line1
	 * Examples:
	 * 150 S LOMBARD RD
	 */
	address:string = undefined;
	/**
	 * City, town Name
	 * Example
	 * PORTLAND
	 */
	cityName:string = undefined;
	/**
	 * US and Mexico have states, Canada has provinces. 
	 * The two char abbreviateions are recorded here. 
	 * Currently LTL only records US, CA and MX parties. Hence the State codes are two char.
	 * 
	 * Example
	 * OR - Oregon USA
	 * TM - Tamaulipas Mexico
	 * AB - Alberta
	 */
	stateCd:string = undefined;
	/**
	 * This records ZIP codes also called PIN codes etc.
	 * US and Mexico use 5 digit codes, Canada uses 6 character codes.
	 * Example
	 * T2H2K4 - Canada
	 * 97209 - USA
	 * 55540 - Mexico
	 * 
	 * There could be common zip codes numbers between US and Mexico.
	 */
	zipCd:string = undefined;
	/**
	 * USPS uses additional numbers as extension to the 5 digit zip codes
	 */
	zip4Cd:string = undefined;
	/**
	 * Country Code is 2 character Standard country codes.
	 * US -USA
	 * MX - Mexico
	 * CA - Canada
	 */
	countryCd:string = undefined;
	/**
	 * A same value is assigned to CORRELATION id of various rows from different tables that form a single business transactions. In other words, this groups multipple rows across various tables for a single business transaction..
	 */
	correlationId:string = undefined;
	/**
	 * These comments added by users (generally coding dept) when a row is added, updated. 
	 * Typically it describes the reason for update
	 */
	lastUpdateRemarks:string = undefined;
	/**
	 * 
	 */
	cstRequestId:number = undefined;
	/**
	 * 
	 */
	legacyContactKey:number = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 */
	auditInfo:AuditInfo = undefined;
}
export class Equipment {
	equipmentBase:EquipmentBase = undefined;
	/**
	 * replaced, intact, broken, missing
	 */
	sealInfo:EquipmentSeal[] = undefined;
	/**
	 * The stackability factor indicates how many items of packaging material, such as pallets, can be stacked on top of one another. It is not a control and is therefore for information purposes only.
	 */
	stackabilityFactor:number = undefined;
	/**
	 * The filling level is the percentage to which a handling unit should be filled. It is not a control and is therefore for information purposes only.
	 */
	fillingLevel:number = undefined;
	/**
	 * In this field, you can enter a percentage tolerance limit, up to which the allowed packing weight of a handling unit can be exceeded.
	 */
	excessWeightTolerance:number = undefined;
	/**
	 * In this field, you can enter a percentage tolerance limit, up to which the allowed packing volume of a handling unit can be exceeded.
	 */
	excessVolumeTolerance:number = undefined;
	maxSpeed:Speed = undefined;
	/**
	 * Used for optimization
	 */
	averageSpeed:Speed = undefined;
	/**
	 * Serial or VIN number of the equipment
	 */
	equipmentSerialNbr:string = undefined;
	/**
	 * Transponder Number of the tractor
	 */
	equipmentTransponderNbr:string = undefined;
	/**
	 * The DOT number of the tractor For XPO LTL tractors it is 241829.  For XPO LTL Canada
	 * tractors, that is 838885.
	 */
	equipmentDOTNbr:string = undefined;
}
export class EquipmentBase {
	equipmentInstID:string = undefined;
	equipmentPrefix:string = undefined;
	/**
	 * Sequencing or serial part of an equipment unit's identifying number (pure numeric form for equipment number is preferred)
	 */
	equipmentSuffix:string = undefined;
	/**
	 * Prefix or alphabetic part of an equipment unit's identifying number
	 */
	ownerPfx:string = undefined;
	/**
	 * Name of equipment owner if not ltl.xpo
	 */
	ownerName:string = undefined;
	/**
	 * Company, Subservice, rental company,
	 */
	supplierTypeCd:string = undefined;
	/**
	 * Trip Leased,Seller owned(Returnable),Railroad Leased,Not customer owned or leased,Customer owned or leased
	 */
	leaseTypeCd:string = undefined;
	/**
	 * Container, Forklift, Dolly, Tractor, Skid, TrailerType -
	 */
	equipmentTypeCd:string = undefined;
	/**
	 * LiftGate, PackingMaterials, StackingAppliance
	 */
	equipmentCharacteristics:EquipmentProperty[] = undefined;
	tareWeight:Weight = undefined;
	allowedGrossWeight:Weight = undefined;
	allowedGrossVolume:Volume = undefined;
	dimensions:Dimensions = undefined;
	dateOfManufacture:Date = undefined;
	/**
	 * License or Registration number of the Equipment
	 */
	equipmentLicNbr:string = undefined;
	/**
	 * State or Province Code where the license waas issued.
	 */
	equipmentLicLoc:string = undefined;
	/**
	 * Country where the license was issued
	 */
	equipmentLicCountryCd:string = undefined;
}
/**
 * Identification info for equipment
 */
export class EquipmentInfo {
	equipmentInstID:string = undefined;
	/**
	 * Type of the equipment - Tractor, Trailer, Dolly, Straight Truck
	 */
	equipmentTypeCd:string = undefined;
	/**
	 * Prefix for the quipment number.
	 */
	equipmentPrefix:string = undefined;
	/**
	 * Sequencing or serial part of an equipment unit's identifying number (pure numeric form for equipment number is preferred)
	 */
	equipmentSuffix:string = undefined;
}
export class EquipmentProperty {
	eqpPropTypeCd:string = undefined;
	eqpPropDesc:string = undefined;
}
export class EquipmentSeal {
	/**
	 * for now it will be hardcoded value not a code lookup
	 */
	sealOwner:string = undefined;
	/**
	 * Unique number on seal used to close a shipment
	 */
	sealNbr:string[] = undefined;
	/**
	 * replaced, intact, broken, missing
	 */
	sealStatusCd:string[] = undefined;
}
export class Speed {
	speed:number = undefined;
	speedUOM:string = undefined;
}
/**
 * Captures transaction events. API writes one record per transaction.  Added to EVT seed model  06/26/2020 
 */
export class TransactionEvent {
	/**
	 * PK. Unique ID of the transaction. For Oracle, use the transaction id available in the context. Populated by trigger.
	 */
	transactionId:string = undefined;
	/**
	 * Identifies the type of transaction. Could be method name
	 */
	transactionType:string = undefined;
	/**
	 * Same value for CORRELATION id is assigned to various rows from different tables that form a single business transactions. 
	 * In other words, this groups multiple rows across various tables for a single business transaction.
	 */
	correlationId:string = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * Application key value like 
	 * 
	 * trip_inst_id, route_inst_id, shp_inst_id etc.
	 */
	businessKey1:string = undefined;
	/**
	 * Application key value like 
	 * 
	 * trip_inst_id, route_inst_id, shp_inst_id etc.
	 */
	businessKey2:string = undefined;
	/**
	 * Application key value like 
	 * 
	 * trip_inst_id, route_inst_id, shp_inst_id etc.
	 */
	businessKey3:string = undefined;
	/**
	 * Application key value like 
	 * 
	 * trip_inst_id, route_inst_id, shp_inst_id etc.
	 */
	businessKey4:string = undefined;
	/**
	 * Application key value like 
	 * 
	 * trip_inst_id, route_inst_id, shp_inst_id etc.
	 */
	businessKey5:string = undefined;
	/**
	 * Number of seconds to hold event before publishing.  Added 09/21/2020  LISG-7498
	 */
	eventReleaseDelaySeconds:number = undefined;
	/**
	 * 
	 */
	transactionDetails:TransactionEventDetail[] = undefined;
	/**
	 * 
	 */
	transactionBusinessEvents:TransactionEventBusiness[] = undefined;
}
/**
 * 
 */
export class TransactionEventBusiness {
	/**
	 * Unique identifier for the record.  This will be populate by a trigger before insert to get the ID from _EVT_TRANSACTION_BUS_SEQ
	 */
	instId:number = undefined;
	/**
	 * The transaction id from the session.  This will be populate by a trigger before insert.
	 */
	transactionId:string = undefined;
	/**
	 * Short description of the business transaction type.
	 */
	transactionType:string = undefined;
	/**
	 * Code identifying the type of business event.
	 */
	typeCd:string = undefined;
	/**
	 * Code identifying the type of business event.
	 */
	subTypeCd:string = undefined;
	/**
	 * Same value for CORRELATION id is assigned to various rows from different tables that form a single business transactions. In other words, this groups multiple rows across various tables for a single business transaction.
	 */
	correlationId:string = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * Stores the snapshot of all the tables that were updated as part of the transaction. The snapshot is a json representation of all columns that were changed. The snapshot will be created by insert/update/delete triggers on the source table.   Added to EVT seed model  06/26/2020
 */
export class TransactionEventDetail {
	/**
	 * PK. Unique id for the snapshot record. Generated by Sequence.
	 */
	detailId:number = undefined;
	/**
	 * Unique ID of the transaction. For Oracle, use the transaction id available in the context. Populated by trigger.
	 */
	transactionId:string = undefined;
	/**
	 * Name of the table for which the snapshot is stored
	 */
	tableName:string = undefined;
	/**
	 * Json string containing the before and after values for all the columns that were changed by the transaction.
	 */
	snapshot:object = undefined;
	/**
	 * Application key value like 
	 * 
	 * trip_inst_id, route_inst_id, shp_inst_id etc.
	 */
	businessKey1:string = undefined;
	/**
	 * Application key value like 
	 * 
	 * trip_inst_id, route_inst_id, shp_inst_id etc.
	 */
	businessKey2:string = undefined;
	/**
	 * Application key value like 
	 * 
	 * trip_inst_id, route_inst_id, shp_inst_id etc.
	 */
	businessKey3:string = undefined;
	/**
	 * Application key value like 
	 * 
	 * trip_inst_id, route_inst_id, shp_inst_id etc.
	 */
	businessKey4:string = undefined;
	/**
	 * Application key value like 
	 * 
	 * trip_inst_id, route_inst_id, shp_inst_id etc.
	 */
	businessKey5:string = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * Identifies the type of transaction. Could be method name
	 */
	transactionType:TransactionEventDetailTypeCd = undefined;
}
export class FaultResponse {
	/**
	 * Internal error code
	 */
	errorCode:string = undefined;
	/**
	 * The message describing the fault
	 */
	message:string = undefined;
	/**
	 * A JSON message describing the fault
	 */
	jsonMessage:object = undefined;
	/**
	 * Program trace information to help troubleshoot this fault E.g. The Java Stack trace from the exception.
	 */
	trace:string = undefined;
	/**
	 * The ID of the application using this API
	 */
	srcApplicationId:string = undefined;
	/**
	 * Contains the date and time of when this transaction occurred.
	 */
	transactionTimestamp:Date = undefined;
	/**
	 * A unique ID for this transaction.
	 */
	correlationId:string = undefined;
	/**
	 * Any additional information that will help in troubleshooting this fault. E.g. include any context information such as requesting application, user details, any identifying details from the API request such as customer number, PRO number etc.
	 */
	moreInfo:MoreInfo[] = undefined;
	/**
	 * Fault Response from the root cause.
	 */
	rootCause:FaultResponse = undefined;
}
export class JobStatus {
	code:number = undefined;
	message:string = undefined;
	details:string[] = undefined;
}
export class JobRetryConfig {
	retryCount:number = undefined;
	maxRetryDuration:number = undefined;
	maxBackoffDuration:number = undefined;
	maxDoublings:number = undefined;
}
export class Attribute {
	name:string = undefined;
	value:string = undefined;
}
export class PubsubTarget {
	topicName:number = undefined;
	data:string = undefined;
	attributes:Attribute[] = undefined;
}
export class AppEngineRouting {
	service:string = undefined;
	version:string = undefined;
	instance:string = undefined;
	host:string = undefined;
}
export class AppEngineHttpTarget {
	httpMethod:HttpMethod = undefined;
	appEngineRouting:AppEngineRouting = undefined;
	maxBackoffDuration:number = undefined;
	relativeUri:string = undefined;
	headers:Attribute[] = undefined;
	body:string = undefined;
}
export class HttpTarget {
	uri:string = undefined;
	httpMethod:HttpMethod = undefined;
	headers:Attribute[] = undefined;
	body:string = undefined;
}
export class Job {
	name:string = undefined;
	description:string = undefined;
	schedule:string = undefined;
	timeZone:string = undefined;
	userUpdateTime:Date = undefined;
	state:JobStateTypeCd = undefined;
	status:JobStatus = undefined;
	scheduleTime:Date = undefined;
	lastAttemptTime:Date = undefined;
	retryConfig:JobRetryConfig = undefined;
	attemptDeadline:number = undefined;
	target:object = undefined;
}
/**
 * 
 */
export class Locations {
	locationId:number = undefined;
	name:string = undefined;
	typeCd:GeoLocationTypeCd = undefined;
	sic:string = undefined;
	geoAreaId:number = undefined;
	shape:GeoCoordinates = undefined;
	geoCodingPrecision:string = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class LocationAddress {
	/**
	 * 
	 */
	refSicCd:string = undefined;
	/**
	 * 
	 */
	addressId:number = undefined;
	/**
	 * 
	 */
	locationAddressEffDate:Date = undefined;
	/**
	 * 
	 */
	altAddressType:string = undefined;
	/**
	 * 
	 */
	addr1:string = undefined;
	/**
	 * 
	 */
	addr2:string = undefined;
	/**
	 * 
	 */
	addr3:string = undefined;
	/**
	 * 
	 */
	boxNbr:string = undefined;
	/**
	 * 
	 */
	cityName:string = undefined;
	/**
	 * 
	 */
	countryCd:string = undefined;
	/**
	 * 
	 */
	countryName:string = undefined;
	/**
	 * 
	 */
	countrySubdivisionCd:string = undefined;
	/**
	 * 
	 */
	countrySubdivisionName:string = undefined;
	/**
	 * 
	 */
	companyName:string = undefined;
	/**
	 * 
	 */
	deliveryInstructionDescription:string = undefined;
	/**
	 * 
	 */
	expiryDate:Date = undefined;
	/**
	 * 
	 */
	nm1Name:string = undefined;
	/**
	 * 
	 */
	nm2Name:string = undefined;
	/**
	 * 
	 */
	prmyAddressFlag:number = undefined;
	/**
	 * 
	 */
	postalCd:string = undefined;
	/**
	 * 
	 */
	postalExtCd:string = undefined;
	/**
	 * 
	 */
	expiryReasonCd:string = undefined;
	/**
	 * 
	 */
	pyrlCntr:string = undefined;
	/**
	 * 
	 */
	pyrlCorpCd:string = undefined;
	/**
	 * 
	 */
	pyrlEnvs:string = undefined;
	/**
	 * 
	 */
	geoCodedFlag:number = undefined;
	/**
	 * 
	 */
	postalCertifiedFlag:number = undefined;
	/**
	 * 
	 */
	detailCapxtimestamp:Date = undefined;
	/**
	 * 
	 */
	replicationLastUpdateDateTime:Date = undefined;
	/**
	 * 
	 */
	dmlDateTime:Date = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * Added for compatibility
 */
export class KafkaMessageHeader {
	/**
	 * Name of the target elastic search index
	 */
	index:string = undefined;
	/**
	 * This is the envrionment within that division  e.g. CTSI, CTS2, CTSS, PROD
	 */
	env:string = undefined;
	/**
	 * Id of the document
	 */
	id:string = undefined;
	/**
	 * Type of document
	 */
	docType:string = undefined;
	/**
	 * Correlation id of the transaction
	 */
	correlationId:string = undefined;
	/**
	 * Id of the application generating this message
	 */
	srcApplicationId:string = undefined;
}
/**
 * Envelope for messages bound for a Elastic Search
 */
export class SearchRecordMessageHeader {
	/**
	 * Name of the target elastic search index
	 */
	index:string = undefined;
	/**
	 * This is the environment within that division  e.g. CTSI, CTS2, CTSS, PROD
	 */
	env:string = undefined;
	/**
	 * Id of the document
	 */
	id:string = undefined;
	/**
	 * Type of document
	 */
	docType:string = undefined;
	/**
	 * Correlation id of the transaction
	 */
	correlationId:string = undefined;
	/**
	 * Id of the application generating this message
	 */
	srcApplicationId:string = undefined;
	/**
	 * Type of document
	 */
	action:ActionCd = undefined;
}
/**
 * This is the root element for the LTL Canonical Msg
 */
export class LTLMessageHdr {
	hdr:object = undefined;
	body:Body[] = undefined;
}
export class Body {
	payload:PayloadType[] = undefined;
}
export class PayloadType {
}
/**
 * TransactionContext format. transaction type, action
 */
export class TranCntxt {
	/**
	 * Transaction event
	 */
	tranType:string = undefined;
	/**
	 * The action to be taken on this message --- is a business body of work.
	 */
	action:string = undefined;
	correlationId:number = undefined;
	contextId:object = undefined;
}
export class LTLEvent {
	/**
	 * The type of event.
	 */
	eventType:string = undefined;
	/**
	 * Contains a list of additional event types that are included in this event.
	 */
	auxEventTypes:string[] = undefined;
	/**
	 * The event occurrence timestamp.
	 */
	eventTmst:Date = undefined;
	/**
	 * The name of component from which this event originated.
	 */
	srcComponent:string = undefined;
	/**
	 * The correlation ID of the originating transaction
	 */
	correlationId:string = undefined;
	/**
	 * Application key value like pro, shipid, pickupdate etc.
	 */
	businessKey1:string = undefined;
	/**
	 * Application key value like pro, shipid, pickupdate etc.
	 */
	businessKey2:string = undefined;
	/**
	 * Application key value like pro, shipid, pickupdate etc.
	 */
	businessKey3:string = undefined;
	/**
	 * Application key value like pro, shipid, pickupdate etc.
	 */
	businessKey4:string = undefined;
	/**
	 * Application key value like pro, shipid, pickupdate etc.
	 */
	businessKey5:string = undefined;
	/**
	 * A unique id for the event. If this is chunked, the eventId will help in combining chunks of the same message
	 */
	eventId:string = undefined;
	/**
	 * If there are a large number of payloads, the events can be chunked and published as smaller payloads. Set chunkInd to true if this is a chunk of a larger event message
	 */
	chunkedInd:boolean = undefined;
	/**
	 * If this is a chunked event message, specifies the sequence number of this instance
	 */
	chunkSeqNbr:number = undefined;
	/**
	 * If this is a chunked event message, specifies the total number of chunks
	 */
	totalChunks:number = undefined;
	/**
	 * The event data.
	 */
	eventData:PayloadType[] = undefined;
}
export class CBOHeaderType {
	/**
	 * The name of the message.
	 */
	cboName:string = undefined;
	/**
	 * The version of the message.
	 */
	cboVersion:number = undefined;
	/**
	 * The correlation ID maps back the original ID. This may be the JMS Message ID, ADB Sequence number, etc.
	 */
	correlationID:string = undefined;
	/**
	 * The original subject name on which this message was published.
	 */
	originSubjectName:string = undefined;
	/**
	 * The common name of the origin publisher. Examples are DB2ADB, RPT1ADB, CAXFileAdapter. In subsequent publishes of this type this should not be changed.
	 */
	originPublisher:string = undefined;
	/**
	 * The timestamp from the origin message.
	 */
	originTimestamp:string = undefined;
	/**
	 * The name of the application that published this message.
	 */
	currentPublisher:string = undefined;
	/**
	 * The date and time the message was sent.
	 */
	currentTimestamp:Date = undefined;
	/**
	 * The priority of the message.
	 */
	priority:string = undefined;
	/**
	 * The type of the message.
	 */
	type:string = undefined;
	/**
	 * This indicated whether the message has been republished.
	 */
	redelivered:boolean = undefined;
	/**
	 * The version of the message.
	 */
	version:number = undefined;
}
export class TrackingInfoType {
	/**
	 * The identifier of the message.
	 */
	id:string = undefined;
	/**
	 * The application information of the requestor.
	 */
	appInfo:AppInfoType = undefined;
}
export class AppInfoType {
	/**
	 * The type of application information.
	 */
	info:InfoType[] = undefined;
}
export class InfoType {
	/**
	 * The item that holds the information about the application.
	 */
	item:string = undefined;
}
export class CBOType {
}
/**
 * The schedule details which are published as an event.
 */
export class LinehaulSchedule {
	/**
	 * The unique identifier assigned to the schedule.
	 */
	lnhScheduleId:number = undefined;
	/**
	 * The list of employee identifiers for the drivers.
	 */
	dsrEmployeeIds:string[] = undefined;
	/**
	 * The service center identification code for the location where the schedule originated.
	 */
	originSicCd:string = undefined;
	/**
	 * The cut date and time as derived from the calculation after the model is released.
	 */
	originCutDateTime:Date = undefined;
	/**
	 * The equipment identifier, which includes the prefix and suffix, for the tractor.
	 */
	tractorEquipmentId:string = undefined;
	/**
	 * The list of equipment identifiers, which include the prefix and suffix, for the trailers.
	 */
	trailerEquipmentIds:string[] = undefined;
	/**
	 * The code associated with the linehaul schedule event status.
	 */
	statusCd:string = undefined;
	/**
	 * The estimated date and time when the schedule will start at the origin SIC.
	 */
	estimatedDepartDateTime:Date = undefined;
	/**
	 * The actual date and time when the schedule was dispatched from the origin SIC.
	 */
	actualDepartDateTime:Date = undefined;
	/**
	 * The legs of this schedule.
	 */
	linehaulScheduleLegs:LinehaulScheduleLeg[] = undefined;
	/**
	 * The date for which the Linehaul schedule is active.
	 */
	scheduleDate:string = undefined;
	/**
	 * The shift code at the origin SIC where the schedule was planned.
	 */
	shiftCd:string = undefined;
}
/**
 * The details for a leg of a linehaul schedule.
 */
export class LinehaulScheduleLeg {
	/**
	 * The actual date and time when the schedule was dispatched from the SIC.
	 */
	actualDepartDateTime:Date = undefined;
	/**
	 * The estimated date and time when the schedule leg will start at the SIC.
	 */
	estimatedDepartDateTime:Date = undefined;
	/**
	 * The actual arrival date and time for this schedule leg.
	 */
	actualArrivalDateTime:Date = undefined;
	/**
	 * The estimated arrival date and time for this schedule leg.
	 */
	estimatedArrivalDateTime:Date = undefined;
	/**
	 * The service center identification code for this leg.
	 */
	sicCd:string = undefined;
	/**
	 * The type of leg. E.g. VIA, FINAL-DESTINATION, etc.
	 */
	type:string = undefined;
}
/**
 * Details about a stop window including its type, start/end dates and times, and the list of associated notes.
 * The beginning local date of the service window.
 * The ending local date of the service window.
 */
export class StopWindow {
	/**
	 * Indicates if the window is profile based or for a specific date/date range.
	 */
	stopWindowType:StopWindowCd = undefined;
	beginDate:string = undefined;
	/**
	 * The beginning local time of the service window.
	 */
	beginTime:string = undefined;
	endDate:string = undefined;
	/**
	 * The ending local time of the service window.
	 */
	endTime:string = undefined;
	/**
	 * The list of notes associated with the stop window.
	 */
	notes:StopWindowNote[] = undefined;
}
/**
 * The note contents and type associated with a stop window.
 */
export class StopWindowNote {
	/**
	 * The type of stop window note.
	 */
	type:StopWindowNoteTypeCd = undefined;
	/**
	 * The content of the appointment note.
	 */
	note:string = undefined;
}
/**
 * 
 */
export class CustomerOperationsNote {
	/**
	 * Implied FK to CIS_INTRFC_ACCT
	 */
	customerAcctId:number = undefined;
	/**
	 * Identifies type of note this is.
	 * 
	 * VALID VALUES
	 * PICKUP_INSTRUCTIONS
	 * DLVRY_INSTRUCTIONS
	 * OPERATIONS_NOTE
	 */
	noteTypeCd:CustOperationNoteTypeCd = undefined;
	/**
	 * Part of unique identifier.
	 */
	sequenceNbr:number = undefined;
	/**
	 * Text of note
	 */
	note:string = undefined;
	/**
	 * Same value for CORRELATION id is assigned to various rows from different tables that form a single business transactions. 
	 * In other words, this groups multiple rows across various tables for a single business transaction.
	 */
	correlationId:string = undefined;
	/**
	 * Same value for event id is assigned to various rows from different tables that form a single database transactions. 
	 * Multiple database transactions could make up one business transaction, which is indicated by correlation_id
	 * In other words, this groups multiple rows across various tables for a single database transaction.
	 * Typically this is used to publish a message to a queue for consumption or a workflow.
	 */
	scoEventId:number = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class NotificationLog {
	/**
	 * 
	 */
	ntfictnInstId:number = undefined;
	/**
	 * 
	 */
	logSeqNbr:number = undefined;
	/**
	 * 
	 */
	eventCd:string = undefined;
	/**
	 * 
	 */
	callerId:string = undefined;
	/**
	 * 
	 */
	occurredTmst:Date = undefined;
	/**
	 * 
	 */
	updateById:string = undefined;
	/**
	 * 
	 */
	dtlCapxtimestamp:Date = undefined;
	/**
	 * 
	 */
	replLstUpdtTmst:Date = undefined;
	/**
	 * 
	 */
	dmlTmst:Date = undefined;
	/**
	 * Same value for CORRELATION 
	 * id is assigned to various rows from different tables that form a single business
	 * transactions. In other words, this groups multiple rows across various tables f
	 * or a single business transaction.
	 */
	correlationId:string = undefined;
	/**
	 * Same value for event id is as
	 * signed to various rows from different tables that form a single database transac
	 * tions. 
	 * Multiple database transactions could make up one business transaction, which is
	 * indicated by correlation_id
	 * In other words, this groups multiple rows across various tables for a single da
	 * tabase transaction.
	 * Typically this is used to publish a message to a queue for consumption or a wor
	 * kflow.
	 */
	scoEventId:number = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * Details which are used to confirm the cancellation of a pickup request.
 */
export class CancelledPickupRequest {
	/**
	 * The unique identifier of the pickup request.
	 */
	pkrPickupRequestId:number = undefined;
	/**
	 * The business key which is used to uniquely identify a pickup request.
	 */
	callNbr:string = undefined;
	/**
	 * The business key (format: ddnnn) which is used to uniquely identify a pickup request from the SCO application.
	 */
	callNbrLegacy:string = undefined;
	/**
	 * The identification code for the service center that is responsible for processing the pickup request.
	 */
	pickupTerminalSicCd:string = undefined;
	/**
	 * The status of the pickup request after cancellation.
	 */
	statusCd:PickupStatusCd = undefined;
	/**
	 * The exception message that may occur when cancelling the pickup request.
	 */
	exceptionMessage:DataValidationError = undefined;
	/**
	 * The pickup request shipment ID and status code for each pickup request shipment after cancellation.
	 */
	cancelledPickupRequestShipments:CancelledPickupRequestShipment[] = undefined;
}
/**
 * Details which are used to confirm the cancellation of a pickup request shipment.
 */
export class CancelledPickupRequestShipment {
	/**
	 * The unique identifier of the pickup request shipment.
	 */
	pkrShipmentId:number = undefined;
	/**
	 * The status of the pickup request shipment after cancellation.
	 */
	statusCd:string = undefined;
}
/**
 * The complete pickup request.
 */
export class CompletePickupRequest {
	/**
	 * The pickup request that will be created.
	 */
	pkrPickupRequest:PkrPickupRequest = undefined;
	/**
	 * List of shipments that are part of the pickup request that will be created.
	 */
	pickupRequestShipments:PickupRequestShipment[] = undefined;
}
/**
 * 
 */
export class PickupRequest {
	/**
	 * 
	 */
	pickupRequestInstId:number = undefined;
	/**
	 * 
	 */
	sourceCd:PickupSourceCd = undefined;
	/**
	 * 
	 */
	statusCd:PickupStatusCd = undefined;
	/**
	 * 
	 */
	pickupDate:string = undefined;
	/**
	 * 
	 */
	readyTime:string = undefined;
	/**
	 * 
	 */
	closeTime:string = undefined;
	/**
	 * 
	 */
	ttlPalletsCount:number = undefined;
	/**
	 * 
	 */
	ttlLoosePiecesCount:number = undefined;
	/**
	 * 
	 */
	ttlWeightLbs:number = undefined;
	/**
	 * 
	 */
	pickupTerminalSicCd:string = undefined;
	/**
	 * 
	 */
	cadCallNbr:number = undefined;
	/**
	 * 
	 */
	shipperName1:string = undefined;
	/**
	 * 
	 */
	shipperName2:string = undefined;
	/**
	 * 
	 */
	shipperAddress:string = undefined;
	/**
	 * 
	 */
	shipperCity:string = undefined;
	/**
	 * 
	 */
	shipperState:string = undefined;
	/**
	 * 
	 */
	shipperCountryCd:ShipperCountryCd = undefined;
	/**
	 * 
	 */
	shipperZip6:string = undefined;
	/**
	 * 
	 */
	shipperZip4RestUs:string = undefined;
	/**
	 * 
	 */
	rqstrName:string = undefined;
	/**
	 * 
	 */
	rqstrCompanyName:string = undefined;
	/**
	 * 
	 */
	rqstrPhoneCountryCdNbr:string = undefined;
	/**
	 * 
	 */
	rqstrPhoneAreaCdNbr:string = undefined;
	/**
	 * 
	 */
	rqstrPhoneNbr:string = undefined;
	/**
	 * 
	 */
	rqstrPhoneExtensionNbr:string = undefined;
	/**
	 * 
	 */
	rqstrEMailId:string = undefined;
	/**
	 * 
	 */
	customerProfitabilityInstId:number = undefined;
	/**
	 * 
	 */
	contactName:string = undefined;
	/**
	 * 
	 */
	contactCompanyName:string = undefined;
	/**
	 * 
	 */
	contactPhoneCountryCdNbr:string = undefined;
	/**
	 * 
	 */
	contactPhoneAreaCdNbr:string = undefined;
	/**
	 * 
	 */
	contactPhoneNbr:string = undefined;
	/**
	 * 
	 */
	contactPhoneExtensionNbr:string = undefined;
	/**
	 * 
	 */
	createdTimestamp:Date = undefined;
	/**
	 * 
	 */
	createdUserId:string = undefined;
	/**
	 * 
	 */
	remarks:string = undefined;
	/**
	 * 
	 */
	shipperPhoneCountryCd:ShipperPhoneCountryCd = undefined;
	/**
	 * 
	 */
	shipperPhoneExtension:string = undefined;
	/**
	 * 
	 */
	shipperPhoneAreaCd:string = undefined;
	/**
	 * 
	 */
	shipperPhone:string = undefined;
	/**
	 * 
	 */
	equipmentNbr:string = undefined;
	/**
	 * 
	 */
	strandInd:boolean = undefined;
	/**
	 * 
	 */
	callDate:string = undefined;
	/**
	 * 
	 */
	rqstrRoleCd:string = undefined;
	/**
	 * 
	 */
	callSequenceNbr:number = undefined;
	/**
	 * 
	 */
	cashDueInd:boolean = undefined;
	/**
	 * 
	 */
	specialEquipmentCd:SpecialEquipmentCd = undefined;
	/**
	 * 
	 */
	earlyCutoffInd:EarlyCutoffCd = undefined;
	/**
	 * 
	 */
	shipperAddr2:string = undefined;
	/**
	 * 
	 */
	shipperCreditedStatusCd:ShipperCreditStatusCd = undefined;
	/**
	 * 
	 */
	insidePickupInd:boolean = undefined;
	/**
	 * 
	 */
	pickupTypeCd:PickupTypeCd = undefined;
	/**
	 * 
	 */
	volumeInd:boolean = undefined;
	/**
	 * 
	 */
	areaInstId:number = undefined;
	/**
	 * 
	 */
	dsrNote:string = undefined;
	/**
	 * 
	 */
	rschCnclReasonCd:RschCnclReasonCd = undefined;
	/**
	 * 
	 */
	rschCnclReason:string = undefined;
	/**
	 * 
	 */
	originTerminalSicCd:string = undefined;
	/**
	 * 
	 */
	archiveInd:boolean = undefined;
	/**
	 * 
	 */
	shipperSicCd:string = undefined;
	/**
	 * 
	 */
	csrOmsInd:boolean = undefined;
	/**
	 * 
	 */
	omsEmployeeUserid:string = undefined;
	/**
	 * 
	 */
	strandReasonCd:StrandReasonCd = undefined;
	/**
	 * 
	 */
	arrivalShiftDate:string = undefined;
	/**
	 * 
	 */
	arrivalShiftCd:string = undefined;
	/**
	 * 
	 */
	loadId:string = undefined;
	/**
	 * 
	 */
	rschCnclContactName:string = undefined;
	/**
	 * 
	 */
	rschCnclContactPhoneNbr:string = undefined;
	/**
	 * 
	 */
	lastUsedSequenceNbr:number = undefined;
	/**
	 * 
	 */
	possibleDuplicateOrderInd:boolean = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * The business identifier of a pickup activity.
	 */
	callNbr:string = undefined;
	/**
	 * Each service center is divided into multiple geographic areas for delivery. This identifies the delivery area of destination service center in which this shipment's consignee is located.
	 */
	areaName:string = undefined;
}
/**
 * Contains details about the shipments included in the pickup request.
 */
export class PickupRequestShipment {
	/**
	 * The shipment that is part of the pickup request.
	 */
	shipment:PkrShipment = undefined;
	/**
	 * The list of hazardous materials for a shipment that is part of the pickup request.
	 */
	shipmentHazMats:PkrShipmentHazMat[] = undefined;
	/**
	 * The list of dimensions for a shipment that is part of the pickup request.
	 */
	shipmentDimensions:PkrShipmentDimension[] = undefined;
	/**
	 * The list of handling units for a shipment that is part of the pickup request.
	 */
	shipmentHandlingUnits:PkrShipmentHandlingUnit[] = undefined;
	/**
	 * The unique identifier of the pickup request.
	 */
	pkrPickupRequestId:number = undefined;
	/**
	 * The unique index to identify a particular shipment in a list.
	 */
	shipmentIndex:number = undefined;
	/**
	 * The business key which is used to uniquely identify a pickup request.  The format is NN-NNNN.
	 */
	handheldCallNbr:string = undefined;
	/**
	 * Indicates whether this is initiated by a handheld device.
	 */
	invokeFromHandheldInd:boolean = undefined;
}
/**
 * Details which are used to confirm the status update of one or more pickup requests and shipments.
 */
export class PickupRequestStatus {
	/**
	 * The pickup request ID, call number, status code, and error message for each pickup request after the status is updated to cancelled.
	 */
	cancelledPickupRequests:CancelledPickupRequest[] = undefined;
	/**
	 * The pickup request ID, call number, and error message for each pickup request after the status is updated to rescheduled.
	 */
	rescheduledPickupRequests:RescheduledPickupRequest[] = undefined;
}
/**
 * The customer might request multiple shipments to be picked up from a shipper, typically going to different destinations. This entity records various shipments to be picked up as part of a given pickup request.
 */
export class PickupShipment {
	/**
	 * The system generated ID which uniquely identifies a record.
	 */
	pickupShipmentId:number = undefined;
	/**
	 * The zip code of the consignee.
	 */
	destinationZip6:string = undefined;
	/**
	 * The code associated with the service center that is responsible for the delivery of the shipment.
	 */
	destinationTerminalSicCd:string = undefined;
	/**
	 * The status of the shipment.
	 */
	statusCd:PickupShipmentStatusCd = undefined;
	/**
	 * The business identifier for the shipment.
	 */
	proNbr:string = undefined;
	/**
	 * When a shipment has been confirmed as picked up, a shipment record (SHM_SHIPMENT) is created and this attribute is set with the identifier of that shipment record.
	 */
	shipmentInstId:number = undefined;
	/**
	 * The internal identifier of the eBol (BOL_DOC).
	 */
	bolInstId:number = undefined;
	/**
	 * The number of pallets in the shipment.
	 */
	palletsCount:number = undefined;
	/**
	 * The total number of loose pieces from all commodities in the shipment.
	 */
	loosePiecesCount:number = undefined;
	/**
	 * The total weight of the shipment in pounds.
	 */
	weightLbs:number = undefined;
	/**
	 * The amount of trailer area that the shipment will occupy.
	 */
	cubeNbr:number = undefined;
	/**
	 * The method used to calculate the volume of the freight (TOT_VOL_CFT).
	 */
	cubicFeetProfileMethodCd:CubicFeetProfileMthdCd = undefined;
	/**
	 * If a default or destination shipper profile was used to calculate the volume of the freight, then this field describes the type of shipper profile used.
	 */
	cubicFeetProfileTypeCd:CubicFeetProfileTypeCd = undefined;
	/**
	 * The cubic feet of the shipment.
	 */
	totalVolumeCubicFeet:number = undefined;
	/**
	 * Indicates whether the shipment is under the Guaranteed Service Program.
	 */
	guaranteedInd:boolean = undefined;
	/**
	 * Indicates whether a hazardous material commodity is being carried on a shipment.
	 */
	hazmatInd:boolean = undefined;
	/**
	 * Indicates whether a shipment requires special handling to prevent freezing during harsh weather.
	 */
	freezableInd:boolean = undefined;
	/**
	 * Indicates whether foodstuffs, feed or other edible material intended for consumption by humans or animals is included in this shipment.
	 */
	foodInd:boolean = undefined;
	/**
	 * Indicates whether the shipment has been placed in the nose of a trailer and will not need to be removed from the trailer for outbound.
	 */
	headloadInd:boolean = undefined;
	/**
	 * Indicates whether the consignee requests that the shipment be delivered on a Weekend (Saturday) or a Holiday.
	 */
	holidayDeliveryInd:boolean = undefined;
	/**
	 * Indicates whether a shipment contains a bulk liquid. If volume is known, then this applies to shipments >119 gallons.
	 */
	bulkLiquidInd:boolean = undefined;
	/**
	 * Indicates whether the cube entered for the pickup line might be invalid.
	 */
	suspectCubeInd:boolean = undefined;
	/**
	 * Indicates whether the pickup line item needs to be reviewed for accuracy.
	 */
	reviewRequiredInd:boolean = undefined;
	/**
	 * The code associated with the specific type of service provided for a shipment.
	 */
	serviceTypeCd:ServiceTypeCd = undefined;
	/**
	 * Designates something about the origin/destination SIC that will require getting this shipment back to the service center earlier than the standard cut-off time for outbound freight.
	 */
	earlyCutoffCd:EarlyCutoffCd = undefined;
	/**
	 * The number of forklift moves (motor moves) that are required to move the shipment.
	 */
	motorMovesNbr:number = undefined;
	/**
	 * The sender-id of the EDI customer.
	 */
	ediSenderId:string = undefined;
	/**
	 * The shipment identifier of the EDI Pkup Req supplied by the EDI customer.
	 */
	ediSenderShipmentId:string = undefined;
	/**
	 * The user ID of the DSR who explicitly opted out of taking measurements at pickup time.
	 */
	optOutByEmployeeId:string = undefined;
	/**
	 * The freeform text which contains any additional information relevant to a shipment on a pickup request.
	 */
	shipmentRemark:string = undefined;
	/**
	 * The same value is assigned to the correlation ID of various rows from different tables that form a single business transaction.
	 */
	correlationId:string = undefined;
	/**
	 * The same value for event ID is assigned to various rows from different tables that form a single database transaction.
	 */
	pickupEventId:number = undefined;
	/**
	 * Indicates the source or origination of the shipment that is part of a pickup request.
	 */
	overrideSourceCd:string = undefined;
	/**
	 * Indicates whether the PRO is exempted to be tracked via piece level.
	 */
	handlingUnitExemptionInd:boolean = undefined;
	/**
	 * An explanation of why a PRO is exempted from piece level tracking.
	 */
	handlingUnitExemptionReason:string = undefined;
	/**
	 * Indicates whether an appointment should be created for delivering the shipment.
	 */
	appointmentRequiredInd:boolean = undefined;
	/**
	 * Indicates whether a destination SIC override has already occurred, in which case the update of the line item should NOT change the destination SIC.
	 */
	destinationSicOverrideInd:boolean = undefined;
	/**
	 * Indicates the source or origination of the pickup request.
	 */
	sourceCd:PickupShipmentSourceCd = undefined;
	/**
	 * The consignee address line 1 for the corresponding consignee zip.
	 */
	consigneeAddress1:string = undefined;
	/**
	 * The consignee address line 2 for the corresponding consignee zip.
	 */
	consigneeAddress2:string = undefined;
	/**
	 * 
	 */
	consigneeAreaInstId:number = undefined;
	/**
	 * The internal customer number that belongs to Consignee from CIS.
	 */
	consigneeCisCustomerNbr:number = undefined;
	/**
	 * The country code of the consignee.
	 */
	consigneeCountryCd:string = undefined;
	/**
	 * The consignee credit status code.
	 */
	consigneeCreditStatusCd:string = undefined;
	/**
	 * The city of the consignee.
	 */
	consigneeCity:string = undefined;
	/**
	 * The consignee name 1.
	 */
	consigneeName1:string = undefined;
	/**
	 * The consignee name 2.
	 */
	consigneeName2:string = undefined;
	/**
	 * The area code for the phone number of the consignee.
	 */
	consigneePhoneAreaCd:string = undefined;
	/**
	 * The country code for the phone number of the consignee.
	 */
	consigneePhoneCountryCd:string = undefined;
	/**
	 * The phone extension of the consignee for a pickup request.
	 */
	consigneePhoneExtension:string = undefined;
	/**
	 * The phone number of the consignee for a pickup request.
	 */
	consigneePhoneNbr:string = undefined;
	/**
	 * A unique, randomly-generated number that identifies a registered customer in the XPO Customer Profile Component.
	 */
	consigneeProfitabilityInstId:number = undefined;
	/**
	 * The service center identification code where the consignee is located.
	 */
	consigneeSicCd:string = undefined;
	/**
	 * The state for the delivery location.
	 */
	consigneeStateCd:string = undefined;
	/**
	 * The last four characters of the zip code for a US address for the delivery location.
	 */
	consigneeZip4RestUs:string = undefined;
	/**
	 * The zip code for the delivery location.
	 */
	consigneeZip6:string = undefined;
	/**
	 * This is the length of the whole or part of the shipment. The number of pieces attribute indicates how many pieces of the shipment to which the dimensions apply.
	 */
	lengthNbr:number = undefined;
	/**
	 * This is the width of the whole or part of the shipment. The number of pieces attribute indicates how many pieces of the shipment to which the dimensions apply.
	 */
	widthNbr:number = undefined;
	/**
	 * This is the height of the whole or part of the shipment. The number of pieces attribute indicates how many pieces of the shipment to which the dimensions apply.
	 */
	heightNbr:number = undefined;
	/**
	 * The unit of measure used for the shipment dimensions.
	 */
	unitOfMeasure:string = undefined;
	/**
	 * Indicates whether it is an excessive length shipment.
	 */
	excessiveLengthInd:boolean = undefined;
	/**
	 * Indicates whether the shipment is under the Rapid Remote Service Program.
	 */
	rrsInd:boolean = undefined;
	/**
	 * Indicates whether this shipment is time date critical.
	 */
	tdcInd:boolean = undefined;
	/**
	 * Indicates whether there should be notification prior to delivery.
	 */
	notifyPriorToDeliveryInd:boolean = undefined;
	/**
	 * Indicates whether delivery should be made after business hours.
	 */
	deliveryAfterBusinessHrsInd:boolean = undefined;
	/**
	 * Indicates whether the delivery should be sorted.
	 */
	deliverySortInd:boolean = undefined;
	/**
	 * 
	 */
	deliveryInboundFreightInd:boolean = undefined;
	/**
	 * Indicates whether the driver needs to collect money at delivery.
	 */
	deliveryCollectInd:boolean = undefined;
	/**
	 * The pickup request line level sequence Number from PUR_DELIVERY_LOC in SCO.
	 */
	pickupRequestSequenceNbrLegacy:number = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction.
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * This shipment line is merged with another shipment line as identified by this attribute.
	 */
	parentMergeToPickupShipmentId:number = undefined;
	/**
	 * Manually added. This is the old delivery location seqNbr that is required to support old processes using the PKR DTOs.
	 */
	sequenceNbr:number = undefined;
}
/**
 * 
 */
export class PickupRequestor {
	/**
	 * 
	 */
	rqstrInstId:number = undefined;
	/**
	 * 
	 */
	pickupRequestInstId:number = undefined;
	/**
	 * 
	 */
	statusCd:string = undefined;
	/**
	 * 
	 */
	archiveInd:boolean = undefined;
	/**
	 * 
	 */
	readyTime:string = undefined;
	/**
	 * 
	 */
	closeTime:string = undefined;
	/**
	 * 
	 */
	name:string = undefined;
	/**
	 * 
	 */
	companyName:string = undefined;
	/**
	 * 
	 */
	phoneCountryCdNbr:string = undefined;
	/**
	 * 
	 */
	phoneAreaCdNbr:string = undefined;
	/**
	 * 
	 */
	phoneNbr:string = undefined;
	/**
	 * 
	 */
	phoneExtensionNbr:string = undefined;
	/**
	 * 
	 */
	eMailId:string = undefined;
	/**
	 * 
	 */
	roleCd:string = undefined;
	/**
	 * 
	 */
	customerProfitabilityInstId:number = undefined;
	/**
	 * 
	 */
	contactName:string = undefined;
	/**
	 * 
	 */
	contactCompanyName:string = undefined;
	/**
	 * 
	 */
	contactPhoneCountryCdNbr:string = undefined;
	/**
	 * 
	 */
	contactPhoneAreaCdNbr:string = undefined;
	/**
	 * 
	 */
	contactPhoneNbr:string = undefined;
	/**
	 * 
	 */
	contactPhoneExtensionNbr:string = undefined;
	/**
	 * 
	 */
	createdUserId:string = undefined;
	/**
	 * 
	 */
	updateById:string = undefined;
	/**
	 * 
	 */
	ediSenderId:string = undefined;
	/**
	 * 
	 */
	pickupDate:string = undefined;
	/**
	 * 
	 */
	shipperName1:string = undefined;
	/**
	 * 
	 */
	shipperName2:string = undefined;
	/**
	 * 
	 */
	shipperAddress:string = undefined;
	/**
	 * 
	 */
	shipperCity:string = undefined;
	/**
	 * 
	 */
	shipperStateCd:string = undefined;
	/**
	 * 
	 */
	shipperCountryCd:string = undefined;
	/**
	 * 
	 */
	shipperZip6:string = undefined;
	/**
	 * 
	 */
	shipperZip4RestUs:string = undefined;
	/**
	 * 
	 */
	shipperPhoneCountryCd:string = undefined;
	/**
	 * 
	 */
	shipperPhoneAreaCd:string = undefined;
	/**
	 * 
	 */
	shipperPhone:string = undefined;
	/**
	 * 
	 */
	shipperPhoneExtension:string = undefined;
	/**
	 * 
	 */
	equipmentNbr:string = undefined;
	/**
	 * 
	 */
	specialEquipmentCd:string = undefined;
	/**
	 * 
	 */
	insidePickupInd:boolean = undefined;
	/**
	 * 
	 */
	remarks:string = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 */
	auditInfo:AuditInfo = undefined;
}
export class PickupLineItemSearchFilter {
	q:string = undefined;
	hostOriginTerminalSicCd:XrtAttributeFilter = undefined;
	header_pickupTerminalSicCd:XrtAttributeFilter = undefined;
	header_pickupDate:XrtAttributeFilter = undefined;
	header_pickupTypeCd:XrtAttributeFilter = undefined;
	header_readyTime:XrtAttributeFilter = undefined;
	header_closeTime:XrtAttributeFilter = undefined;
	header_cadCallNbr:XrtAttributeFilter = undefined;
	header_callNbr:XrtAttributeFilter = undefined;
	guaranteedInd:XrtAttributeFilter = undefined;
	hazardousMtInd:XrtAttributeFilter = undefined;
	freezableInd:XrtAttributeFilter = undefined;
	proNbr:XrtAttributeFilter = undefined;
	shipper_geoCoordinatesGeo:XrtAttributeFilter = undefined;
	header_statusCd:XrtAttributeFilter = undefined;
	suppRef_suppRefTypeCd:XrtAttributeFilter = undefined;
	suppRef_refValue:XrtAttributeFilter = undefined;
	header_shipperName1:XrtAttributeFilter = undefined;
	header_shipperAddress:XrtAttributeFilter = undefined;
	header_shipperCity:XrtAttributeFilter = undefined;
	header_shipperState:XrtAttributeFilter = undefined;
	header_shipperZip6:XrtAttributeFilter = undefined;
	header_shipperCountryCd:XrtAttributeFilter = undefined;
	header_shipperZip4RestUs:XrtAttributeFilter = undefined;
	header_shipperPhoneCountryCd:XrtAttributeFilter = undefined;
	header_shipperPhoneAreaCd:XrtAttributeFilter = undefined;
	header_shipperPhone:XrtAttributeFilter = undefined;
	header_shipperPhoneExtension:XrtAttributeFilter = undefined;
	header_specialEquipmentCd:XrtAttributeFilter = undefined;
	header_sourceCd:XrtAttributeFilter = undefined;
	specialServiceSummary_specialService:XrtAttributeFilter = undefined;
	header_callDate:XrtAttributeFilter = undefined;
	header_originTerminalSicCd:XrtAttributeFilter = undefined;
	header_pickupRequestInstId:XrtAttributeFilter = undefined;
	destinationTerminalSicCd:XrtAttributeFilter = undefined;
	header_rqstrRoleCd:XrtAttributeFilter = undefined;
	header_rqstrCompanyName:XrtAttributeFilter = undefined;
	header_rqstrName:XrtAttributeFilter = undefined;
	header_rqstrPhoneCountryCdNbr:XrtAttributeFilter = undefined;
	header_rqstrPhoneAreaCdNbr:XrtAttributeFilter = undefined;
	header_rqstrPhoneNbr:XrtAttributeFilter = undefined;
	header_rqstrPhoneExtensionNbr:XrtAttributeFilter = undefined;
	header_rqstrEMailId:XrtAttributeFilter = undefined;
	shipper_acctMadCd:XrtAttributeFilter = undefined;
	pickupRequestInstId:XrtAttributeFilter = undefined;
	pickupRequestSequenceNbr:XrtAttributeFilter = undefined;
	pickupCreateDateTime:XrtAttributeFilter = undefined;
	rescheduledCount:XrtAttributeFilter = undefined;
	zip6:XrtAttributeFilter = undefined;
	ediSenderId:XrtAttributeFilter = undefined;
	statusCd:XrtAttributeFilter = undefined;
	dispatchGroupId:XrtAttributeFilter = undefined;
	tripInstId:XrtAttributeFilter = undefined;
	carrierId:XrtAttributeFilter = undefined;
	tenderId:XrtAttributeFilter = undefined;
	tenderDetailSequenceNbr:XrtAttributeFilter = undefined;
	tenderDetailStatusCd:XrtAttributeFilter = undefined;
	tenderDetailStatusMessage:XrtAttributeFilter = undefined;
	appointmentRequiredInd:XrtAttributeFilter = undefined;
	/**
	 * The unique, randomly-generated number that identifies a registered customer.  'Profitability' should have been generated as 'Profile', but the wrong abbreviation was used for the associated column name.
	 */
	customerProfitabilityInstId:XrtAttributeFilter = undefined;
	public toJSON = function(this: PickupLineItemSearchFilter) {
		return {
			"q" : this.q,
			"hostOriginTerminalSicCd" : this.hostOriginTerminalSicCd,
			"header.pickupTerminalSicCd" : this.header_pickupTerminalSicCd,
			"header.pickupDate" : this.header_pickupDate,
			"header.pickupTypeCd" : this.header_pickupTypeCd,
			"header.readyTime" : this.header_readyTime,
			"header.closeTime" : this.header_closeTime,
			"header.cadCallNbr" : this.header_cadCallNbr,
			"header.callNbr" : this.header_callNbr,
			"guaranteedInd" : this.guaranteedInd,
			"hazardousMtInd" : this.hazardousMtInd,
			"freezableInd" : this.freezableInd,
			"proNbr" : this.proNbr,
			"shipper.geoCoordinatesGeo" : this.shipper_geoCoordinatesGeo,
			"header.statusCd" : this.header_statusCd,
			"suppRef.suppRefTypeCd" : this.suppRef_suppRefTypeCd,
			"suppRef.refValue" : this.suppRef_refValue,
			"header.shipperName1" : this.header_shipperName1,
			"header.shipperAddress" : this.header_shipperAddress,
			"header.shipperCity" : this.header_shipperCity,
			"header.shipperState" : this.header_shipperState,
			"header.shipperZip6" : this.header_shipperZip6,
			"header.shipperCountryCd" : this.header_shipperCountryCd,
			"header.shipperZip4RestUs" : this.header_shipperZip4RestUs,
			"header.shipperPhoneCountryCd" : this.header_shipperPhoneCountryCd,
			"header.shipperPhoneAreaCd" : this.header_shipperPhoneAreaCd,
			"header.shipperPhone" : this.header_shipperPhone,
			"header.shipperPhoneExtension" : this.header_shipperPhoneExtension,
			"header.specialEquipmentCd" : this.header_specialEquipmentCd,
			"header.sourceCd" : this.header_sourceCd,
			"specialServiceSummary.specialService" : this.specialServiceSummary_specialService,
			"header.callDate" : this.header_callDate,
			"header.originTerminalSicCd" : this.header_originTerminalSicCd,
			"header.pickupRequestInstId" : this.header_pickupRequestInstId,
			"destinationTerminalSicCd" : this.destinationTerminalSicCd,
			"header.rqstrRoleCd" : this.header_rqstrRoleCd,
			"header.rqstrCompanyName" : this.header_rqstrCompanyName,
			"header.rqstrName" : this.header_rqstrName,
			"header.rqstrPhoneCountryCdNbr" : this.header_rqstrPhoneCountryCdNbr,
			"header.rqstrPhoneAreaCdNbr" : this.header_rqstrPhoneAreaCdNbr,
			"header.rqstrPhoneNbr" : this.header_rqstrPhoneNbr,
			"header.rqstrPhoneExtensionNbr" : this.header_rqstrPhoneExtensionNbr,
			"header.rqstrEMailId" : this.header_rqstrEMailId,
			"shipper.acctMadCd" : this.shipper_acctMadCd,
			"pickupRequestInstId" : this.pickupRequestInstId,
			"pickupRequestSequenceNbr" : this.pickupRequestSequenceNbr,
			"pickupCreateDateTime" : this.pickupCreateDateTime,
			"rescheduledCount" : this.rescheduledCount,
			"zip6" : this.zip6,
			"ediSenderId" : this.ediSenderId,
			"statusCd" : this.statusCd,
			"dispatchGroupId" : this.dispatchGroupId,
			"tripInstId" : this.tripInstId,
			"carrierId" : this.carrierId,
			"tenderId" : this.tenderId,
			"tenderDetailSequenceNbr" : this.tenderDetailSequenceNbr,
			"tenderDetailStatusCd" : this.tenderDetailStatusCd,
			"tenderDetailStatusMessage" : this.tenderDetailStatusMessage,
			"appointmentRequiredInd" : this.appointmentRequiredInd,
			"customerProfitabilityInstId" : this.customerProfitabilityInstId		}
	}
}
/**
 * Elastic Search document for Pickup line item
 */
export class PickupLineItemSearchRecord {
	pickupRequestInstId:number = undefined;
	hostOriginTerminalSicCd:string = undefined;
	zip6:string = undefined;
	pickupRequestSequenceNbr:number = undefined;
	palletsCount:number = undefined;
	loosePiecesCount:number = undefined;
	weightLbs:number = undefined;
	guaranteedInd:boolean = undefined;
	hazardousMtInd:boolean = undefined;
	freezableInd:boolean = undefined;
	statusCd:PickupStatusCd = undefined;
	destinationTerminalSicCd:string = undefined;
	createdUserId:string = undefined;
	remarks:string = undefined;
	updateById:string = undefined;
	serviceTypeCd:string = undefined;
	holDeliveryInd:boolean = undefined;
	proNbr:string = undefined;
	headloadInd:boolean = undefined;
	cubeNbr:number = undefined;
	earlyCutoffCd:string = undefined;
	motorMovesNbr:number = undefined;
	archiveInd:boolean = undefined;
	shipmentInstanceId:number = undefined;
	omsCd:string = undefined;
	omsEmployeeUserId:string = undefined;
	omsAuthUserId:string = undefined;
	foodInd:boolean = undefined;
	bolInstId:number = undefined;
	ediSenderId:string = undefined;
	ediSenderShipmentId:string = undefined;
	optOutBy:string = undefined;
	totalVolumeCubicFeet:number = undefined;
	bulkLiquidInd:boolean = undefined;
	suspectCubeInd:boolean = undefined;
	reviewReqdInd:boolean = undefined;
	moveShiftDate:string = undefined;
	moveShiftCd:string = undefined;
	expectedDeliveryDate:string = undefined;
	cubicFeetProfileMthdCd:string = undefined;
	cubicFeetProfileTypeCd:string = undefined;
	sourceCd:PickupSourceCd = undefined;
	originalPickupRequestInstId:number = undefined;
	originalPickupRequestSequenceNbr:number = undefined;
	lstUpdtTmst:Date = undefined;
	eventTmst:Date = undefined;
	eventActionCd:ActionCd = undefined;
	pickupTypeCd:PickupTypeCd = undefined;
	shipper:AccountDetail = undefined;
	requestor:PickupRequestor = undefined;
	header:PickupRequest = undefined;
	specialServiceSummary:ShipmentSpecialServiceSummary[] = undefined;
	suppRef:SupplementalReference[] = undefined;
	preassignedDriver:string = undefined;
	remarksAvailableInd:boolean = undefined;
	operationalRemarks:string = undefined;
	pickupInstructions:string = undefined;
	locationType:string = undefined;
	minimumAllowableTrailerAge:number = undefined;
	pickupDwellTime:number = undefined;
	equipmentDwellTime:number = undefined;
	lineItemCount:number = undefined;
	srnCount:number = undefined;
	headerDerivedStatusCd:string = undefined;
	pickupCreateDateTime:string = undefined;
	rescheduledCount:number = undefined;
	specialEquipmentCd:SpecialEquipmentCd = undefined;
	eventTmstMicro:number = undefined;
	destinationZipCount:number = undefined;
	ediSenderIdCount:number = undefined;
	destinationSicCount:number = undefined;
	dispatchGroupId:number = undefined;
	tripInstId:number = undefined;
	carrierId:number = undefined;
	tenderId:number = undefined;
	tenderDetailSequenceNbr:number = undefined;
	tenderDetailStatusCd:CarrierTenderStatusCd = undefined;
	tenderDetailStatusMessage:string = undefined;
	appointmentRequiredInd:boolean = undefined;
	/**
	 * The unique, randomly-generated number that identifies a registered customer.  'Profitability' should have been generated as 'Profile', but the wrong abbreviation was used for the associated column name.
	 */
	customerProfitabilityInstId:number = undefined;
}
/**
 * Details which are used to confirm the rescheduling of a pickup request.
 */
export class RescheduledPickupRequest {
	/**
	 * The unique identifier of the pickup request.
	 */
	pkrPickupRequestId:number = undefined;
	/**
	 * The business key which is used to uniquely identify a pickup request.
	 */
	callNbr:string = undefined;
	/**
	 * The business key (format: ddnnn) which is used to uniquely identify a pickup request from the SCO application.
	 */
	callNbrLegacy:string = undefined;
	/**
	 * The identification code for the service center that is responsible for processing the pickup request.
	 */
	pickupTerminalSicCd:string = undefined;
	/**
	 * The exception message that may occur when rescheduling the pickup request.
	 */
	exceptionMessage:DataValidationError = undefined;
}
/**
 * This records the pickup request information as received.
 * The pickup request can be received from 3 different channels/sources:
 * 1. Electronic BOL entered by customer on XPO.com
 * 2. Customer calls CSR
 * 3. EDI
 * 
 * It is possible that the same request to pickup could be received via multiple channels. System can automatically merge duplicates or identify potential duplicates for CSR to review.
 * Upon review, CSR could merge a pickup request with another existing pickup request.
 */
export class PkrPickupRequest {
	/**
	 * System Generated ID to uniquely identify a record.
	 */
	pkrPickupRequestId:number = undefined;
	/**
	 * Expanded Name : Source Code                           
	 * 																																																						
	 * Desc : Indicates the source or origination of the Pickup Request.
	 * 																																																						
	 * Valid Values:                                         
	 * 1 - Pickup Request web page for customers 
	 * 2   - set 204 or set 211
	 * 3  - Mixed (changed from FTP 11/13/17)
	 * 4  - Auto-genned (SMART4 batch) 
	 * 5 - Internal (SMART 4) 
	 * 6 - Operational (added 11/13/17)
	 */
	sourceCd:PickupSourceCd = undefined;
	/**
	 * Business key to uniquely identify a pickup request.
	 * Format: SIC-yyyymmdd-nnn  where SIC is the SIC code that received this request, yyyymmdd is the date when the pickup request was received (Date portion of CRTE_TMST). nnn is running seq for SIC/yyyymmdd
	 */
	callNbr:string = undefined;
	/**
	 * Expanded Name:  Call Sequence Number
	 * 
	 * Desc:  A number sequentially generated by the system starting from 1 for a given SIC plus date when pickup req was received (Date portion of CRTE_TMST)
	 * E.G. UPO for requests created on 2/27/2019 will have sequence from 1 through 55
	 */
	callSequenceNbr:number = undefined;
	/**
	 * Expanded Name : Status Code                   
	 * 																																																			
	 * Desc : Current status of the Pickup Request.  
	 * 
	 * Valid Values: 
	 * 1 - Entered 
	 * 2 - Scheduled (for CAD dispatch only, not used by SMART4)
	 * 3 - Cancelled
	 * 4 - Pre-assigned/Routed [SMART 4]
	 * 5 - Dispatched (SMART 4)
	 * 6 - Freight is ON BOARD (pickup completed) [SMART 4]
	 */
	statusCd:PickupStatusCd = undefined;
	/**
	 * Desc: Possible Duplicate with this pickup request. E.g. this is for the same shipper and same pickup date. Presence of this prompts the CSR to review and merge if this pickup req is indeed duplicate of pickup req identified by POSSIBLE_DUP_PKUP_REQ_ID
	 */
	possibleDuplicatePickupRequestId:number = undefined;
	/**
	 * Expanded Name : Pickup Date                       
	 * 																																																		
	 * Desc : Date that the freight is to be picked up at a  Pickup Location.
	 */
	pickupDate:string = undefined;
	/**
	 * Expanded Name : Total Pallets count             
	 * 																																															
	 * Desc : Total Pallets from all Shipments on the
	 * Pickup Request.
	 */
	totalPalletsCount:number = undefined;
	/**
	 * Expanded Name : Total Loose Pieces count             
	 * 																																															
	 * Desc : Total loose pieces from all Shipments on the
	 * Pickup Request.
	 */
	totalLoosePiecesCount:number = undefined;
	/**
	 * Expanded Name : Total weight (in pounds)             
	 * 																																																					
	 * Desc : Total weight of all the freight being moved as
	 * part of a Pickup Request.
	 */
	totalWeightLbs:number = undefined;
	/**
	 * Expanded Name : Pickup terminal SIC         
	 * 																																														
	 * Desc : SIC where the Pickup Request is sent.                             
	 * This is the SIC that is responsible for processing the pickup request.  If we were dealing with a zone situation, this would be the zone office. 
	 * 
	 * Example:  The shipper address is in Tillamook, Oregon (UTY), the western zone office is UZO.  When this pickup request is created, the responsible SIC is UZO.
	 */
	pickupTerminalSicCd:string = undefined;
	/**
	 * Expanded Name: Shipper SIC Code
	 * 
	 * Desc: This is the SIC code for  the service center that encompasses the shipper location, based on shippers address. This service cneter may or may not always provide the pick up and delivery service to this customer location.
	 * 																																																																															
	 * Example:  The shipper address is in Tillamook, Oregon (UTY) and the western zone office is UZO.  When this pickup request is created, the customer SIC is UTY.  If a different SIC performs the actual pickup,for example,  Bend, Oregon (UBD), the Origin Terminal SIC is changed to UBD but the Customer SIC remains as UTY.
	 */
	shipperSicCd:string = undefined;
	/**
	 * Expanded Name : Origin terminal SIC         
	 * 																																														
	 * Desc : This is typically the SIC where the Pickup Request orginated based on shippers address.
	 * 																													
	 * This is the SIC that will perform the actual pickup.  If we were dealing with a zone situation, this would be the zone SIC. 
	 * 
	 * Example:  The shipper address is in Tillamook, Oregon (UTY) and the western zone office is UZO.  When this pickup request is created, the origin SIC is UTY.
	 */
	originTerminalSicCd:string = undefined;
	/**
	 * Expanded Name : Shipper CIS Customer Number                  
	 * 																																																					
	 * Desc : LEGACY CUSTOMER NUMBER -Identifier of the appropriate customer in CIS,
	 * determined by the matching sub-routine (97 percent shippers are coded in customer system)
	 */
	shipperCisCustomerNbr:number = undefined;
	/**
	 * Expanded Name : Shipper Name Line 1                 
	 * 																																																					
	 * Desc : Name Line 1 of the Company that is the Pickup Location.                            
	 * 																																																					
	 * Valid Values :
	 */
	shipperName1:string = undefined;
	/**
	 * Expanded Name : Shipper Name Line 2                 
	 * 																																																					
	 * Desc : Name Line 2 of the Company that is the Pickup Location.                            
	 * 																																																					
	 * Valid Values :
	 */
	shipperName2:string = undefined;
	/**
	 * Address Line 1 of the shipper
	 */
	shipperAddress1:string = undefined;
	/**
	 * Address Line 2 of the shipper
	 */
	shipperAddress2:string = undefined;
	/**
	 * Expanded Name : Shipper City             
	 * 																																																					
	 * Desc : City where the Pickup Location is located.                            
	 * 																																																					
	 * Valid Values :
	 */
	shipperCity:string = undefined;
	/**
	 * Expanded Name : Shipper State          
	 * 																																																					
	 * Desc : State where the Pickup Location is located.                            
	 * 																																																					
	 * Valid Values :
	 */
	shipperStateCd:string = undefined;
	/**
	 * Expanded Name : Shipper Zip (code) first 5 (characters) (6
	 * characters for Canadian zips)                     
	 * 																																																					
	 * Desc : Zip Code where the Pickup Location is located.
	 * First 5 characters of the zip code for a US    
	 * address or first 6 characters of the Canadian zip code
	 * of the address where the customer is located.         
	 * 
	 * Valid Values :
	 */
	shipperZip6:string = undefined;
	/**
	 * Expanded Name : Shipper Zip (Code) last 4 (characters)   
	 * 																																																	
	 * Desc : Last 4 characters of the zip code for a US
	 * address for the Pickup Location.
	 */
	shipperZip4RestUs:string = undefined;
	/**
	 * Expanded Name : Shipper Country             
	 * 																																																					
	 * Desc : Country where the Pickup Location is located.                            
	 * 																																																					
	 * Valid Values :
	 */
	shipperCountryCd:CountryCd = undefined;
	/**
	 * Expanded Name: Phone Country Code
	 * 
	 * Desc: Country code for the phone number of the Requestor for a Pickup Request.
	 */
	shipperPhoneCountryCd:string = undefined;
	/**
	 * Expanded Name:  Phone Extension Number
	 * 
	 * Desc: Phone Extension of the Shipper for a Pickup Request.
	 */
	shipperPhoneExtension:string = undefined;
	/**
	 * Expanded Name:  Phone Area Code
	 * 
	 * Desc:  Area code for the phone number of the Shipper for a Pickup Request
	 */
	shipperPhoneAreaCd:string = undefined;
	/**
	 * Expanded Name: Phone Number
	 * 
	 * Desc:  Phone number of the Shipper for a Pickup Request.
	 */
	shipperPhoneNbr:string = undefined;
	/**
	 * Expanded Name:  Shipper Credit Status Code
	 * 
	 * Desc: Credit status code of the shipper
	 * Values:
	 * CASH
	 * BANKRUPT 
	 * CREDIT
	 * NO_CREDIT 
	 * VOLUNTARY
	 */
	shipperCreditedStatusCd:ShipperCreditStatusCd = undefined;
	/**
	 * Expanded Name : Customer Profile Instance Id   
	 * 																																															
	 * Desc : A unique, randomly-generated number that
	 * identifies a registered customer in the Con-Way
	 * Customer Profile Component.
	 */
	shipperProfitabilityInstId:number = undefined;
	/**
	 * Unique Instance ID assigned to area by the GEO Area component on the middle tier
	 */
	shipperAreaInstId:number = undefined;
	/**
	 * Expanded Name : Requestor Name                    
	 * 																																																					
	 * Desc : Name of the Requestor.                            
	 * 																																																					
	 * Valid Values :
	 */
	requesterName:string = undefined;
	/**
	 * Expanded Name : Requestor Company Name.                  
	 * 																																																					
	 * Desc : Company Name of the Requestor.                            
	 * 																																																					
	 * Valid Values :
	 */
	requesterCompanyName:string = undefined;
	/**
	 * Expanded Name : Requestor Phone Country Code   
	 * 																																															
	 * Desc : Country code for the phone number of the
	 * Requestor for a Pickup Request.
	 */
	requesterPhoneCountryCd:string = undefined;
	/**
	 * Expanded Name : Requestor Phone Area Code             
	 * 																																																						
	 * Desc : Area code for the phone number of the Requestor
	 * for a Pickup Request.
	 */
	requesterPhoneAreaCd:string = undefined;
	/**
	 * Expanded Name : Requestor Phone Number            
	 * 																																																			
	 * Desc : Phone number of the Requestor for a Pickup Request.  In many cases, this may turn out to be the 
	 * same as the shipper.
	 */
	requesterPhoneNbr:string = undefined;
	/**
	 * Expanded Name : Requestor Phone Extension Number     
	 * 																																																					
	 * Desc : Phone Extension of the Requestor for a Pickup Request .  This may often turn out to be the shipper in
	 * many cases.
	 */
	requesterPhoneExtension:string = undefined;
	/**
	 * Expanded Name : Requestor E-mail Id                  
	 * 																																																						
	 * Desc : E-mail address of the requestor, used to return
	 * any confirmation e-mail when the pickup request has   
	 * been accepted.
	 */
	requesterEmail:string = undefined;
	/**
	 * Expanded Name : Requestor Role Code                  
	 * 																																																					
	 * Desc : The role of the entity making the request for the pickup.                            
	 * 																																																					
	 * Valid Values : 
	 * SHIPPER
	 * CONSIGNEE
	 * THIRD_PARTY
	 */
	requesterRoleCd:PickupRequesterRoleCd = undefined;
	/**
	 * Expanded Name : Contact Name                    
	 * 																																																					
	 * Desc : Name of the Contact.                            
	 * 																																																					
	 * Valid Values :
	 */
	contactName:string = undefined;
	/**
	 * Expanded Name : Contact Company Name.                  
	 * 																																																					
	 * Desc : Company Name of the Contact.                            
	 * 																																																					
	 * Valid Values :
	 */
	contactCompanyName:string = undefined;
	/**
	 * Expanded Name : Contact Phone Country Code   
	 * 																																															
	 * Desc : Country code for the phone number of the
	 * Contact for a Pickup Request.
	 */
	contactPhoneCountryCd:string = undefined;
	/**
	 * Expanded Name : Contact Phone Area Code             
	 * 																																																						
	 * Desc : Area code for the phone number of the Contact
	 * for a Pickup Request.
	 */
	contactPhoneAreaCd:string = undefined;
	/**
	 * Expanded Name : Contact Phone Number            
	 * 																																																			
	 * Desc : Phone number of the Contact for a Pickup Request.  In many cases, this may turn out to be the same as the shipper.
	 */
	contactPhoneNbr:string = undefined;
	/**
	 * Expanded Name : Contact Phone Extension Number     
	 * 																																																					
	 * Desc : Phone Extension of the Contact for a Pickup Request   This may often turn out to be the shipper in
	 * many cases.
	 */
	contactPhoneExtension:string = undefined;
	/**
	 * Indicates the type of pickup.
	 * 
	 * Values:
	 * NORMAL 
	 * SPOT_EMPTY
	 * HOOK_LOADED
	 * HOOK_EMPTY
	 */
	pickupTypeCd:PickupTypeCd = undefined;
	/**
	 * Desc: ID of the Equipment related to this Pickup.  Examples: Trailer that is expected to be hooked;  Customer-managed Truckload ID.
	 */
	equipmentNbr:string = undefined;
	/**
	 * Expanded Name:  Special Equipment Requirement Code
	 * 
	 * Desc:  Indicates whether any special equipment is required to make the pickup.
	 * 
	 * Valid valies:
	 * L - Liftgate
	 * F - Forklift 
	 * P - Pallet Jack
	 */
	specialEquipmentCd:SpecialEquipmentCd = undefined;
	/**
	 * The purpose of the strand indicator is to signal that the freight contained on this pickup request is going to be handled differently by O/B Planning.  Typically, freight that is stranded does not move out the evening that it is picked up.
	 * 
	 * Is set to Y if freight on the pickup request needs to be stranded. Otherwise is set to N
	 */
	strandInd:boolean = undefined;
	/**
	 * The purpose of the Strand Reason Code is to specify the reason that the freight contained on this pickup request is going to be handled differently by O/B Planning.  Freight that is stranded does not move out the evening that it is picked up.
	 * 
	 * Valid Values:
	 * space - Not Stranded
	 * L - Late Tender (customer caused)
	 * C - Con-way (Con-way caused)
	 */
	strandReasonCd:StrandReasonCd = undefined;
	/**
	 * Expanded Name:  Cash Due Indicator
	 * 
	 * Desc:  Indicates whether cash needs to be collected by the driver at the time of pickup.
	 * 
	 * Values : 
	 * 	Y - Cash is Due  
	 * 	N - No
	 */
	cashDueInd:boolean = undefined;
	/**
	 * Expanded Name:  Early Cut-off Time Indicator
	 * 
	 * Desc:  Used in conjunction with EARLY_CUTOFF_CD in PKR_DELIVERY_LOCATION.  Used to indicate that the pickup request has one or more shipments on it that will require getting back to the service center earlier than the standard cut-off time for outbound freight.
	 * 
	 * Values:
	 * Y - Has an early cut-off shipment
	 * N -- No
	 */
	earlyCutoffInd:boolean = undefined;
	/**
	 * Extended Name:  Inside Pickup Indicator
	 * 
	 * Desc:  Indicates whether this will be an inside pickup.
	 * Values:  
	 * 	N - No
	 * 	Y - Is an inside delivery
	 */
	insidePickupInd:boolean = undefined;
	/**
	 * Expanded Name: Volume Indicator
	 * 
	 * Desc:  Indicates a pickup request that consists of weight greater than 5000 lbs
	 * Values:
	 * 	N - Not a volume pickup
	 * 	Y - Is a volume pickup
	 */
	volumeInd:boolean = undefined;
	/**
	 * Description:  Indicates the reason why a pickup request was rescheduled.   Note this is longer than normal length 20.
	 * 
	 * Valid values are in table PKR_REASON_CODE
	 */
	rescheduleReasonCd:string = undefined;
	/**
	 * Desc: Reschedule  Contact Name 
	 * Contact name of the person authorizing or requesting the  reschedule of the Shipment. To help Track EDI/EBOL cancellation.
	 * 
	 * 07/17/2018 Cathy Doucet Added as per Asha Kava for EBOL Accountability R2
	 */
	rescheduleContactName:string = undefined;
	/**
	 * Desc: Reschedule Contact Phone Number
	 * Full Contact Phone Number (incudes Area code) of the person authorizing or requesting the reschedule of the Shipment.  To help Track EDI/EBOL cancellation.
	 * 
	 * 
	 * 07/17/2018 Cathy Doucet Added as per Asha Kava for EBOL Accountability R2
	 */
	rescheduleContactPhoneNbr:string = undefined;
	/**
	 * Desc: For trailers containing Deconsolidation shipments, the expected arrival shift date
	 */
	arrivalShiftDate:string = undefined;
	/**
	 * Desc: For trailers containing Deconsolidation shipments, the expected arrival shift.
	 * 
	 * Non Null Values:
	 * I - Inbound
	 * D - Day FAC
	 * O - Outbound
	 * N - Night FAC
	 */
	arrivalShiftCd:ShiftCd = undefined;
	/**
	 * Desc: For trailers containing Deconsolidation shipments, a unique Identifier of the Trailer Load, example: Seal Number.
	 */
	loadId:string = undefined;
	/**
	 * 
	 */
	pickupPriorityNbr:string = undefined;
	/**
	 * Cancel Reason Codes.  Note this is longer than normal length 20.
	 * 
	 * Valid values are in table PKR_REASON_CODE
	 */
	cancelReasonCd:string = undefined;
	/**
	 * The date in which Pickup request is cancelled
	 */
	cancelDate:string = undefined;
	/**
	 * Pickup Request Cancel Description.  Used to record detailed free format  information about pickup request cancellations.
	 */
	cancelRemark:string = undefined;
	/**
	 * 
	 */
	callCustomerBeforePickupInd:boolean = undefined;
	/**
	 * 
	 */
	carrierName:string = undefined;
	/**
	 * Special instructions for the DSR for handling of the Pickup request.  11/01/2021 Increased length from 120.  LISG-15458
	 */
	dsrNote:string = undefined;
	/**
	 * Expanded Name : Remarks   
	 * 																																																	
	 * Desc : Freeform textual comments used to relate information pertinent to the Pickup Request or Pickup Location.  07/28/2021  LISG-14052 Increased length from 1000
	 */
	pickupRemark:string = undefined;
	/**
	 * A same value is assigned to CORRELATION id of various rows from different tables that form a single business transactions. In other words, this groups multipple rows across various tables for a single business transaction..
	 */
	correlationId:string = undefined;
	/**
	 * Same value for event id is as
	 * signed to various rows from different tables that form a single database transac
	 * tion. 
	 * Multiple database transactions could make up one business transaction, which is
	 * 	indicated by correlation_id.  
	 * In other words, this groups multiple rows across various tables for a single da
	 * tabase transaction.
	 * Typically this is used to publish a message to a queue for consumption or a wor
	 * kflow.
	 */
	pkrEventId:number = undefined;
	/**
	 * Expanded Name : Ready Time                    
	 * 																																																		
	 * Desc : Earliest available time that freight can be
	 * picked up from a Pickup Location.                        
	 * Recorded in the following format:  HH24:MI  
	 * EXAMPLE: 16:30 for 4:30pm
	 * 
	 * Note:  This time is stored as LOCAL time (not Pacific time).
	 */
	readyTime:string = undefined;
	/**
	 * Expanded Name : Close Time                       
	 * 																																																		
	 * Desc : Latest available time that freight can be
	 * picked up from a Pickup Location.                        
	 * 
	 * Note:  This time is stored as LOCAL time (not Pacific time).
	 * 
	 * Recorded in the following format:  HH24:MI  
	 * EXAMPLE: 16:30 for 4:30pm
	 */
	closeTime:string = undefined;
	/**
	 * When a Pickup Request is re-scheduled, capture detailed re schedue notes
	 */
	rescheduleReasonRemark:string = undefined;
	/**
	 * When a Pickup Request is Re-Scheduled, capture the Email address of the contact person who has requedted a re-schedule either from a shipper -or- consignee -o- 3PL.  07/28/2021  Increased length from 100  LISG-14052
	 */
	rescheduleContactEmail:string = undefined;
	/**
	 * Desc: Cancel Contact Phone Number
	 * 
	 * Full Contact Phone Number (incudes Area code) of the person authorizing or requesting the cancel of the Shipment.  To help Track EDI/EBOL cancellation.
	 */
	cancelContactPhoneNbr:string = undefined;
	/**
	 * When a Pickup request is cancelled, capture email addres of the contact person requesting cancellation either from shipper -or- consignee -or- 3PL
	 */
	cancelContactEmail:string = undefined;
	/**
	 * Capture Appoint Required Indicator at the time of Pickup.  For the time being this is captured in Pickup Request and will be revisited during Appointment Management development work holistically.
	 * 'Y' - Appointment Required
	 * 'N' - Appointment not Required
	 */
	appointmentRequiredInd:boolean = undefined;
	/**
	 * Appointment Time Window   Start Date and Time.
	 * 
	 * 1) In the application capture the date and time separately and concatenate to store here.
	 * 2) If only Date portion is captured then Appointment window is a all day window.
	 * 3) If the time portion is available then this is the start time and cannot be > APPT_TO_DATE
	 * 
	 * 09/10/2021  LISG-14952 Changed suffix from _DT to _DTTM to allow change to mthods in the transformers in Java
	 */
	appointmentConfirmFromDateTime:Date = undefined;
	/**
	 * Appointment Time Window   End Date and Time.
	 * 
	 * 1) In the application capture the date and time separately and concatenate to store here.
	 * 2) If only Date portion is captured then Appointment window is a all day window.
	 * 3) If the time portion is available then this is the End time and cannot be < APPT_FROM_DATE
	 * 
	 * 09/10/2021  LISG-14952 Changed suffix from _DT to _DTTM to allow change to mthods in the transformers in Java
	 */
	appointmentConfirmToDateTime:Date = undefined;
	/**
	 * 
	 */
	areaInstId:number = undefined;
	/**
	 * When a Pickup Request is Re-scheduled, this indicator is updated to 'Y'. Values Permited 'Y' or 'N'
	 */
	rescheduleInd:boolean = undefined;
	/**
	 * Dock drop means customer will drop their shipments at our dock
	 */
	dockDropInd:boolean = undefined;
	/**
	 * Increased length from 10 to 11.  11/11/2021  LISG-15549
	 */
	trailerNbr1:string = undefined;
	/**
	 * Increased length from 10 to 11.  11/11/2021  LISG-15549
	 */
	trailerNbr2:string = undefined;
	/**
	 * Increased length from 10 to 11.  11/11/2021  LISG-15549
	 */
	trailerNbr3:string = undefined;
	/**
	 * TRailer Count
	 */
	trailerCount:string = undefined;
	/**
	 * Identify possibole duploicate shipments from the same Pickup Request that has the following similar criteria
	 * 1)   Shipments created under the same Pickup Request with the same  consignee zip code and weight with a threshold of plus or minus 10 percent.
	 */
	possibleDuplicateShipmentInd:boolean = undefined;
	/**
	 * Indicates if the customer location is residential, commercial, construction ..
	 * 
	 * Valid Values:
	 * BusinessCommercialBUSINESS/COMMERCIAL
	 * DistributionCenterDISTRIBUTION CENTER
	 * GroceryWareHouseGROCERY WAREHOUSE
	 * ShoppingMallSHOPPING MALL/STRIP MALL
	 * UnversityCollegeUNIVERSITY/COLLEGE
	 * ConstructionSiteCONSTRUCTION/UTILITY SITES: CONSTRUCTION SITE
	 * ConstructionUtilitySiteCONSTRUCTION/UTILITY SITES: UTILITY SITE
	 * GovtMineGOVERNMENT SITES: MINE
	 * GovtGasOilFieldGOVERNMENT SITES: NATURAL GAS OR OIL FIELD
	 * GovtPostOfficeGOVERNMENT SITES: POST OFFICE
	 * GovtMilitaryGOVERNMENT SITES: MILITARY
	 * GovtPrisonCorrectionGOVERNMENT SITES: PRISON/CORRECTION FACILITY
	 * GovtAirportGOVERNMENT SITES: AIRPORT
	 * GovtOtherGOVERNMENT SITES: OTHER GOVERNMENT SITES
	 * PierContainerFreightStationPIERS, WHARFS, CFS: CONTAINER FREIGHT STATION
	 * PierWharfPIERS, WHARFS, CFS: PIER OR WHARF
	 * ResidentialHomeRESIDENTIAL: RESIDENCE
	 * ResidentialApartmentRESIDENTIAL: APARTMENT
	 * ResidentialPlaceofWorshipRESIDENTIAL: PLACE OF WORSHIP
	 * ResidentialSchoolRESIDENTIAL: SCHOOL (K-12)
	 * ResidentialCampRESIDENTIAL: CAMP
	 * ResidentialFarmRESIDENTIAL: FARM
	 * ResidentialPublicStorageRESIDENTIAL: PUBLIC STORAGE FACILITY
	 * ResidentialBusinessRESIDENTIAL: BUSINESS OPERATING WITHIN A PRIVATE RESIDENCE
	 * ExhibitionConvCtrEXHIBITION SITES: EXHIBITION SITE OR CONVENTION CENTER
	 */
	locationTypeCd:string = undefined;
	/**
	 * Pickup Request cancelled after Driver has arrived at the Customer/Shipper Location.
	 * Values Allowed (Y/N)
	 * Default Value 'N'
	 */
	cancelAfterDsrArrivalInd:boolean = undefined;
	/**
	 * Pickup Request re-scheduled after Driver has arrived at the Customer/Shipper Location.
	 * Values Allowed (Y/N)
	 * Default Value 'N'
	 */
	rescheduleAfterDsrArrivalInd:boolean = undefined;
	/**
	 * Indicates if the DSR has to notify the pickup location contact on arrival to the shipper location.  Added 07/28/2021  LISG-14046
	 */
	notifyShipperOnArrivalInd:boolean = undefined;
	/**
	 * Indicates if the pickup request appointment is set.  Added 07/28/2021  LISG-14046
	 */
	appointmentConfirmInd:boolean = undefined;
	/**
	 * Email of the Pickup Location contact.  Added 07/28/2021  LISG-14046
	 */
	contactEmail:string = undefined;
	/**
	 * Business key to uniquely identify a pickup request from SCO Application. Format: SIC-DD-nnn where 
	 * 1)  SIC is the SIC code that received the request 
	 * 2) DD is the two digit date section of the date
	 * 3)  nnn is the running sequence number
	 * 
	 * This column is added in order to preserve the SCO Call Number which will be used in the application until SCO is retired.
	 * Once SCO is retired, application will switch to the new CALL_NBR_TX
	 */
	callNbrLegacy:string = undefined;
	/**
	 * Identifies if the pickup location is in XPO Customer database, or is a Future (potential) Customer.  Added 08/10/2021  LISG-14344
	 */
	shipperCustomerTypeCd:ShipperCustomerTypeCd = undefined;
	/**
	 * When a Pickup Request is Re-Scheduled, capture the Company name.  Added 08/12/2021  LISG-14408
	 */
	rescheduleContactCompanyName:string = undefined;
	/**
	 * Contact Phone Number extension of the person authorizing or requesting the reschedule of the Shipment.  Added 08/12/2021  LISG-14408
	 */
	rescheduleContactPhoneExt:string = undefined;
	/**
	 * When a Pickup request is cancelled, capture name of the contact person requesting cancellation either from shipper -or- consignee -or- 3PL.  Added 08/12/2021  LISG-14408
	 */
	cancelContactName:string = undefined;
	/**
	 * Contact Phone Number extension of the person authorizing or requesting the cancelling of the Shipment.  Added 08/12/2021  LISG-14408
	 */
	cancelContactPhoneExt:string = undefined;
	/**
	 * When a Pickup Request is Cancelled, capture the Company name.  Added 08/12/2021  LISG-14408
	 */
	cancelContactCompanyName:string = undefined;
	/**
	 * Identifies the Last generated Sequence Number for the PUR Delivery Location Item for the Pickup Request. Added 10/18/2021  LISG-15338
	 */
	lastUsedSequenceNbr:number = undefined;
	/**
	 * Original Source Code of Pickup Request.   Added 02/25/2022  LISG-16213 
	 * 
	 * Values (need to switch to longer ENUMS when appropriate):
	 * 1 - WEB
	 * 2 - EDI
	 * 3 - DAILY
	 * 5 - INTERNAL
	 */
	originalSourceCd:OriginalSourceCd = undefined;
	/**
	 * Identifier of the Pickup contact.  Added 03/14/2022 LISG-16358
	 */
	contactKeyNbr:number = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * when a pickup request is merged with another, this records the PKR_PICKUP_REQUEST_ID of the request with which this pickup request is merged. The status of this pickup request is set to MERGED_TO, which also means this pickup request is no more to be used (logically deleted)
	 */
	parentMergeToPkrPickupRequestId:number = undefined;
	/**
	 * This allows further sub categorization of the codes specifically for rescheduling purposes.
	 */
	rescheduleReasonSubCategory:string = undefined;
	/**
	 * The long description for the code which is specific to rescheduling.
	 */
	rescheduleReasonDescription:string = undefined;
	/**
	 * This allows further sub categorization of the codes specifically for cancellation purposes.
	 */
	cancelReasonSubCategory:string = undefined;
	/**
	 * The long description for the code which is specific to cancellation.
	 */
	cancelReasonDescription:string = undefined;
}
/**
 * Customer might request multiple shipments to be picked up from a shipper, typically going to different destinations. This entity records various shipments to be picked up as part of a given pickup request.
 * 
 * e.g. A pickup request could be have 3 shipments to be picked up for the following destinations: Dallas, LA, San Jose.  
 * Note, CSR/DSR might determine that one of the shipments is not picked up today but instead as part of pickup scheduled for another date. In this case, that line might be moved from one pickup request to another. 
 * 
 * 
 */
export class PkrShipment {
	/**
	 * System Generated ID to uniquely identify a record.
	 * This entity has its own surrogate key and does not use relationship to the PKR_PICKUP_REQUEST as part of the identifier because, 
	 * CSR might move a line from one pickup  equest to another.
	 * 
	 * If this shipment line is moved to a different pickup request, the original pickup req under which this shipment was originally requested can be obtained using the link to the PKR_RCVD_PKUP_REQ_LINE, which is the copy of Shipment line when this shipment line was first created.
	 */
	pkrShipmentId:number = undefined;
	/**
	 * Consignee Zip code.
	 */
	destinationZip6:string = undefined;
	/**
	 * Expanded Name : Destination terminal SIC         
	 * 																																														
	 * Desc : SIC responsible for the delivery of the Shipment.
	 */
	destinationTerminalSicCd:string = undefined;
	/**
	 * NEW - when the line is created
	 * 	
	 * MERGED - When duplicate pickup requests are entered (e.g. one by CSR and another from EDI), they can be merged into a single pickup request (e.g. same shipper, same date before 5pm). Example: P1 is merged into P2.  P1 has shipment S1, P2 has shipment S2. During the merge, it is determined that S1 and S2 are the same (based on consigee, weight etc). In this case S1 will be set with status MERGED and at the same time MERGE_TO_PKR_PKUP_RQST_SHP_ID is set to S2 indicating that this line (S1) is logically deleted and merged into S2.
	 * 	
	 * DELETED - CSR/DSR might delete one shipment line when they determine that the shipper will not ship this shipment, but is shipping other shipments for this Pickup request
	 */
	statusCd:string = undefined;
	/**
	 * Expanded Name:  PRO Number
	 * 
	 * Desc: The PRO number of the shipment.  This is typically entered at the time the pickup has been completed (freight on board).
	 */
	proNbr:string = undefined;
	/**
	 * When a shipment has been confirmed as picked up, a shipment record (SHM_SHIPMENT) is created and this attribute is set with the identifier of that shipment record.
	 */
	shipmentInstanceId:number = undefined;
	/**
	 * Desc: Internal identifier of the eBol (BOL_DOC).
	 * When a BOL is created either by a customer or by EDI, a pickup request with shipment lines is created. This attribute links the shipment line with the BOL Identifier that created this shipment line for the pickup request.
	 */
	bolInstanceId:number = undefined;
	/**
	 * Expanded Name : pallet count             
	 * 																																															
	 * Desc : Number of pallets in the shipment.
	 */
	palletsCount:number = undefined;
	/**
	 * Expanded Name : Total pieces count             
	 * 																																															
	 * Desc : Total Loose pieces from all commodities in the
	 * shipment.
	 */
	loosePiecesCount:number = undefined;
	/**
	 * Expanded Name: Total weight (in pounds)       
	 * 																																														
	 * Desc : Total weight of the Shipment in pounds.
	 */
	weightLbs:number = undefined;
	/**
	 * Expanded Name:  Cube
	 * 
	 * Desc: Specifies the amount of trailer area that the shipment will occupy.  A cube of 1.000 indicates that the entire trailer will be filled by the shipment.  A cube of 0.500 indicates that half of the trailer will be filled by the shipment.
	 */
	cubeNbr:number = undefined;
	/**
	 * Desc: The method used to calculate the volume of the freight (TOT_VOL_CFT).                                 
	 * 																																																							
	 * VALUES                                                 
	 * ACTL - Actual dimensions captured by the driver        
	 * (length x width x height)                              
	 * DEST - Shipper profile for the shipment.s destination  
	 * ZIP (average shipment volume when the shipper ships to 
	 * that ZIP)                                              
	 * DFLT - Default shipper profile (average shipment       
	 * volume for shipper regardless of ZIP)                  
	 * SIC - Service center profile (average shipment volume  
	 * across all shipments at that SIC)                      
	 * SYS - System profile (average shipment volume across   
	 * all shipments in LTL)                                  
	 * 																																																							
	 * 08/31/2016 Cathy Doucet Added as per Rajesh for P2Ds part in WNI changes.
	 */
	cubicFeetProfileMethodCd:string = undefined;
	/**
	 * Desc: If a default or destination shipper profile was used to calculate the volume of the freight, this field describes the type of shipper profile used.     
	 * 																																																						
	 * Allowed values:                                       
	 * FTPRNT - Footprint piece profile                      
	 * VOLSHP - Volume-shipment                              
	 * VOLPCE - Volume-piece                                 
	 * DNSITY - Density shipment                             
	 * HEIGHT - Height piece                                 
	 * 																																																						
	 * 08/31/2016 Cathy Doucet Added as per Rajesh for P2Ds part in WNI changes.
	 */
	cubicFeetProfileTypeCd:string = undefined;
	/**
	 * Total Volume in Cubic Feet:
	 * 
	 * Cubic feet of the Shipment.  This may be either the sum of the measured dimensions, or the estimate calculated from the shipment characteristics using the customer profile of location default density.
	 */
	totalVolumeCubicFeet:number = undefined;
	/**
	 * Expanded Name : Guaranteed Indicator                 
	 * 																																																					
	 * Desc : Indicates if the shipment is under Guaranteed Service Program.                          
	 * 																																																					
	 * Values : Y/N
	 */
	guaranteedInd:boolean = undefined;
	/**
	 * Expanded Name : Hazardous material indicator
	 * 
	 * Desc : Indicates whether a hazardous material
	 * commodity is being carried on a Shipment.                  
	 * 																																													
	 * Valid Values :                               
	 * Y - Has hazardous materials                  
	 * N - No hazardous materials
	 */
	hazardousMaterialInd:boolean = undefined;
	/**
	 * Expanded Name : Freezable Indicator               
	 * 																																																		
	 * Desc : Indicates whether shipment requires special
	 * handling to prevent freezing during harsh weather.
	 * 																																																		
	 * Valid Values : Y/N                                
	 * 																																																		
	 * Y - Can be damaged by freezing
	 */
	freezableInd:boolean = undefined;
	/**
	 * Expanded Name: Food indicator
	 * 
	 * Desc: Indicates whether foodstuffs, feed or other edible material intended for consumption by humans or animals is included in this shipment.
	 * 
	 * Valid Values:
	 * Y - Has food, feed or other edible material included in shipment
	 * N - No food included in shipment
	 */
	foodInd:boolean = undefined;
	/**
	 * Expanded Name : Head Load Indicator                 
	 * 																																																			
	 * Desc :  Indicates that the shipment has been placed in the nose of a trailer and will not need to be removed from the trailer for outbound.  Typically, the dispatcher or driver would update an existing pickup request with this information at the time the freight is on board.  
	 * 
	 * Valid Values: Y/N  ( Y - Is a head load )
	 */
	headloadInd:boolean = undefined;
	/**
	 * Is set to 'Y' if Consignee requests that the shipment be delivered on a Weekend (Saturday) or a Holiday. Otherwise, is set to 'N'
	 */
	holidayDeliveryInd:boolean = undefined;
	/**
	 * Expanded Name : Bulk Liquid indicator
	 * 
	 * Desc : Indicates whether shipment contains a bulk liquid. If volume is known, this applies to shipments >119 gallons.         
	 * 																																													
	 * Valid Values :                               
	 * Y - Has Bulk Liquid                 
	 * N - No Bulk Liquid    
	 * 
	 * Added 8/5/14 by Paul Bramhall for Tanker Endorsement Project.
	 * -------------------------------------------------------------
	 */
	bulkLiquidInd:boolean = undefined;
	/**
	 * Desc: Indicates if the cube entered for the pickup line might be invalid.
	 */
	suspectCubeInd:boolean = undefined;
	/**
	 * Desc: Indicates if the pickup line item needs to be reviewed for accuracy.
	 */
	reviewRequiredInd:boolean = undefined;
	/**
	 * Expanded name:  Service type code.                    
	 * 																																																						
	 * Description:  Defines the specific type of service    
	 * provided for a shipment.                              
	 * 																																																						
	 * Values:  
	 * NORMAL 
	 * RRS - Rapid Remote Service
	 * G12 - Guaranteed by Noon (G12)
	 */
	serviceTypeCd:string = undefined;
	/**
	 * Expanded Name:  Early Cut-off Time Code
	 * 
	 * Desc:  Used to designate something about the origin/destination SIC that will require getting this shipment back to the service center earlier than the standard cut-off time for outbound freight.
	 * 
	 * Values : 
	 * NORMAL - no early cut-off time
	 * ESL         - (Extended Service Lane)
	 */
	earlyCutoffCd:string = undefined;
	/**
	 * Expanded Name:  Motor Moves
	 * 
	 * Desc: Specifies the number of forklift moves (motor moves) that are required to move the shipment.  Typically, one pallet or barrel equals one motor move.
	 */
	motorMovesNbr:number = undefined;
	/**
	 * Desc: Sender-id of the EDI Customer.
	 */
	ediSenderId:string = undefined;
	/**
	 * Desc: Shipment identifier of the EDI Pkup Req supplied by the EDI Customer.
	 */
	ediSenderShipmentId:string = undefined;
	/**
	 * Opt out by:
	 * The userid of the DSR who explicitly opted out of taking measurements at pickup time.
	 */
	optOutBy:string = undefined;
	/**
	 * Expanded Name: Remarks      
	 * 																																														
	 * Desc : Freeform textual field to hold any additional information relevant to a Shipment on a Pickup Request.
	 */
	remarks:string = undefined;
	/**
	 * A same value is assigned to CORRELATION id of various rows from different tables that form a single business transactions. In other words, this groups multipple rows across various tables for a single business transaction..
	 */
	correlationId:string = undefined;
	/**
	 * Same value for event id is as
	 * signed to various rows from different tables that form a single database transac
	 * tion. 
	 * Multiple database transactions could make up one business transaction, which is
	 * 	indicated by correlation_id.  
	 * In other words, this groups multiple rows across various tables for a single da
	 * tabase transaction.
	 * Typically this is used to publish a message to a queue for consumption or a wor
	 * kflow.
	 */
	eventId:number = undefined;
	/**
	 * Expanded Name : Override Source Code                          
	 * 
	 * Desc : Indicates the source or origination of the shipment that is part of a pickup request. After pickup request is created, shipments can be added later on, including during confirming on-board the pickup.
	 * 
	 * Valid Values:                                        
	 * WEB - Pickup Request web page for customers
	 * EDI   - set 204 or set 211
	 * EDGE - Application used by employees, such as CSR
	 * MIXED - More than one source at the PKR Line level.  (i.e.  'EDI' , 'WEB)
	 * DAILY - Batch Generated Pickup Request based on customer profile preferences (example. Pickup on Wed at 12noon)
	 * OPER  Shipments added to the pickup request during its operational course
	 */
	overrideSourceCd:string = undefined;
	/**
	 * Indicates that the PRO is exempted to be tracked via piece level. SLC (Shipper Loaded Count) shipments are generally exempted. Pickups with 15 or more pieces are also allowed to be exempted.    Added 12/10/2020  LISG-9067  Renamed 01/07/2021
	 */
	handlingUnitExemptionInd:boolean = undefined;
	/**
	 * An explanation of why a pro is exempted from piece level tracking.  Added 12/10/2020   LISG-9067 Renamed 01/07/2021
	 */
	handlingUnitExemptionReason:string = undefined;
	/**
	 * An appointment should be created for delivering the shipment.  Added 03/11/2021 Cathy Doucet LISG-10771
	 */
	appointmentRequiredInd:boolean = undefined;
	/**
	 * Identifies if a destination SIC override has already occurred - in which case update of line item should NOT change dest SIC.  Added for Kroger 10/16/2020
	 */
	destinationSicOverrideInd:boolean = undefined;
	/**
	 * Expanded Name : Source Code                           
	 * 																																																						
	 * Desc : Indicates the source or origination of the Pickup Request.
	 * 																																																						
	 * Valid Values:                                         
	 * WEB - Pickup Request web page for customers 
	 * EDI   - set 204 or set 211
	 * MANUAL- Application used by employees, such as CSR
	 * MIXED - More than one source at the PKR Line level.  (i.e.  'EDI' , 'WEB)
	 * DAILY - Batch Generated Pickup Request based on customer profile preferences (example. Pickup on Wed at 12noon)
	 */
	sourceCd:PickupShipmentSourceCd = undefined;
	/**
	 * Pickup Request Line Level sequence Number from PUR_DELIVERY_LOC in SCO
	 */
	pickupRequestSequenceNbr:number = undefined;
	/**
	 * Indicates whether Child Pro Entry was opted out when confirming on board a shipment.  Added 04/17/2023  LISG-53654
	 */
	childProOptOutInd:boolean = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * This shipment line is merged with another shipment line as identified by this attribute.  Also see STATUS_CD=MERGED
	 */
	parentMergeToPkrShipmentId:number = undefined;
	/**
	 * Manually added. This is the old delivery location seqNbr that is required to support old processes using the PKR DTOs.
	 */
	sequenceNbr:number = undefined;
	/**
	 * When a shipment is moved from one pickup request to another capture the pickup request identifier from which a shipment is moved.
	 */
	parentOriginalPickupRequestId:number = undefined;
	/**
	 * This shipment line currently belongs to the Pickup Request identified by this attribute. Note CSR could move a shipment line to a different pickup request. e.g. if one of the many shipments was not ready today and needs to be rescheduled under a different pickup tomorrow.
	 */
	parentPkrPickupRequestId:number = undefined;
}
/**
 * Optionally stores the dimension (length, width, height) for a shipment line of the pick up request.
 * 
 * 	
 */
export class PkrShipmentDimension {
	/**
	 * The ID of the pickup request shipment line for which dimensions are captured
	 */
	pkrShipmentId:number = undefined;
	/**
	 * Allows multiple dimensions to be recorded for the same shipment
	 */
	sequenceNbr:number = undefined;
	/**
	 * Expanded Name : Pieces count                   
	 * 																																															
	 * Desc : Number of pieces of the shipment this dimensions apply to.
	 */
	piecesCount:number = undefined;
	/**
	 * Expanded Name: Length
	 * 
	 * Description: This is the length of the whole or part of the shipment. The number of pieces attribute indicates how many pieces of the shipment the dimensions apply to.
	 */
	lengthNbr:number = undefined;
	/**
	 * Expanded Name: Width
	 * 
	 * Description: This is the width of the whole or part of the shipment. The number of pieces attribute indicates how many pieces of the shipment the dimensions apply to.
	 */
	widthNbr:number = undefined;
	/**
	 * Expanded Name: Height
	 * 
	 * Description: This is the height of the whole or part of the shipment. The number of pieces attribute indicates how many pieces of the shipment the dimensions apply to.
	 */
	heightNbr:number = undefined;
	/**
	 * Expanded Name: Stackable indicator
	 * 
	 * Desc: Indicator that specifies if the box/pallet can be stackable
	 * 
	 * Values:Y = Stackable 
	 * N = Not stackable
	 */
	stackableInd:boolean = undefined;
	/**
	 * A same value is assigned to CORRELATION id of various rows from different tables that form a single business transactions. In other words, this groups multipple rows across various tables for a single business transaction..
	 */
	correlationId:string = undefined;
	/**
	 * Same value for event id is as
	 * signed to various rows from different tables that form a single database transac
	 * tion. 
	 * Multiple database transactions could make up one business transaction, which is
	 * 	indicated by correlation_id.  
	 * In other words, this groups multiple rows across various tables for a single da
	 * tabase transaction.
	 * Typically this is used to publish a message to a queue for consumption or a wor
	 * kflow.
	 */
	eventId:number = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * During Pickup completion from HH, they want to capture the dimension capture code.  Added 05/03/2022 LISG-16757
	 * 
	 * Valid Values:
	 * P=PICKUP
	 * U=PICKUP_DIMENSIONER
	 */
	dimensionTypeCd:string = undefined;
}
/**
 *  When a shipment is confirmed on board, a unique tracking# label is stuck to indiividual piece. This records the information about individual piece of a given shipment
 */
export class PkrShipmentHandlingUnit {
	/**
	 * The ID of the parent pickup request shipment line for which the handling unit is recorded
	 */
	pkrShipmentId:number = undefined;
	/**
	 * A system assigned number used as part of how to uniquely identify each handling unit of a shipment.
	 */
	sequenceNbr:number = undefined;
	/**
	 * Child PRO number identifies a handling unit of a shipment. This is an alternate key of the table.The numbers are printed on labels and are assigned to service centers. Labels are placed on 2 sides of each pieces (usually by pallet) of the shipment pic
	 */
	childProNbr:string = undefined;
	/**
	 * Weight of this handling unit.
	 */
	weightLbs:number = undefined;
	/**
	 * Volume in cubic feet of this handling unit.
	 */
	volumeCubicFeet:number = undefined;
	/**
	 * Length of this handling unit.
	 */
	lengthNbr:number = undefined;
	/**
	 * Width of this handling unit.
	 */
	widthNbr:number = undefined;
	/**
	 * Height of this handling unit.
	 */
	heightNbr:number = undefined;
	/**
	 * Indicates whether the handling unit can be stacked or not.
	 */
	stackableInd:boolean = undefined;
	/**
	 * Indicates whether or not the handling unit is a hazardous material.
	 */
	hazmatInd:boolean = undefined;
	/**
	 * Indicates whether or not the handling unit is a freezable materia
	 */
	freezableInd:boolean = undefined;
	/**
	 * A same value is assigned to CORRELATION id of various rows from different tables that form a single business transactions. In other words, this groups multipple rows across various tables for a single business transaction..
	 */
	correlationId:string = undefined;
	/**
	 * Same value for event id is as
	 * signed to various rows from different tables that form a single database transac
	 * tion. 
	 * Multiple database transactions could make up one business transaction, which is
	 * 	indicated by correlation_id.  
	 * In other words, this groups multiple rows across various tables for a single da
	 * tabase transaction.
	 * Typically this is used to publish a message to a queue for consumption or a wor
	 * kflow.
	 */
	eventId:number = undefined;
	/**
	 * The type of handling unit.  Added 10/29/2020
	 * 
	 * Valid Values:
	 * MOTOR
	 * LOOSE
	 */
	typeCd:HandlingUnitTypeCd = undefined;
	/**
	 * 
	 */
	statusCd:PickupStatusCd = undefined;
	/**
	 * This is the volume of the handling unit expressed as a percentage of a full pup (28 foot long trailer).Added 10/25/2021  LISG-15410
	 */
	pupVolumePercentage:number = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * The business identifier for the parent shipment.
	 */
	parentProNbr:string = undefined;
	/**
	 * Used to be able to identify based on the PUR_DELIVERY_LOC key.
	 */
	parentSequenceNbr:number = undefined;
	/**
	 * During Pickup completion from HH, they want to capture the dimension capture code.  Added 05/03/2022 LISG-16757
	 * 
	 * Valid Values:
	 * PICKUP
	 * PICKUP_DIMENSIONER
	 */
	dimensionTypeCd:string = undefined;
	/**
	 * Indicates whether the shipment is poorly packaged.
	 */
	poorlyPackagedInd:boolean = undefined;
}
/**
 * Describes hazardous material details for one pickup request line item.
 * 	
 */
export class PkrShipmentHazMat {
	/**
	 * The ID of the pickup request shipment line for which dimensions are captured
	 */
	pkrShipmentId:number = undefined;
	/**
	 * Expanded Name: Sequence number                      
	 * 				
	 * Desc: Used to uniquely identify a hazmat row of the shipment.
	 */
	sequenceNbr:number = undefined;
	/**
	 * Expanded Name: UN/NA Number                          
	 * 		
	 * Desc: The UN or NA identification number for a hazardous material.  Format UN0009 or NA0009 (2 letters followed by 4 numbers).
	 */
	hazmatUnna:string = undefined;
	/**
	 * Expanded Name: Hazard Class Code                      
	 * 		
	 * Desc: The Primary Hazard Class Code of this hazardous material.
	 */
	hazmatClassCd:string = undefined;
	/**
	 * Expanded Name: Class Qualifier                  
	 * 		
	 * Desc: Describes the Primary Hazard Class of this hazardous material.
	 */
	hazmatClassQualifier:string = undefined;
	/**
	 * Expanded Name: Subsidiary Hazard Class 1        
	 * 			
	 * Desc:  The first Subsidiary Hazard Class of this hazardous material (if any).
	 */
	hazmatSubsidiaryClass1Cd:string = undefined;
	/**
	 * Expanded Name: Subsidiary Qualifier 1                
	 * 			
	 * Desc: Describes the first Subsidiary Hazard Class (if any).
	 */
	hazmatSubsidiaryQualifier1:string = undefined;
	/**
	 * Expanded Name: Subsidiary Hazard Class 2        
	 * 						
	 * Desc: The second Subsidiary Hazard Class of this hazardous material (if any).
	 */
	hazmatSubsidiaryClass2Cd:string = undefined;
	/**
	 * Expanded Name: Subsidiary Qualifier 2 
	 * 			
	 * Desc: Describes the second Subsidiary Hazard Class (if any).
	 */
	hazmatSubsidiaryQualifier2:string = undefined;
	/**
	 * Expanded Name: Weight in Pounds  
	 * 			
	 * Desc: The weight, in pounds, of this hazardous material.
	 */
	hazmatWeightLbs:number = undefined;
	/**
	 * Expanded Name: Bulk quantity code                    
	 * 																																																					
	 * Desc: Indicates the set of federal regulations to impose based on the volume of this hazardous material.  
	 * NON_BULK Non-bulk 
	 * BULK = Bulk 
	 * LIMITED_QTY = Limited Quantity
	 */
	hazmatBulkQuantityCd:string = undefined;
	/**
	 * Expanded Name: Residue indicator              
	 * 																																														
	 * Desc: Y = Only the residue of the previous contents remains in the hazmat container.
	 */
	hazmatResidueInd:boolean = undefined;
	/**
	 * A same value is assigned to CORRELATION id of various rows from different tables that form a single business transactions. In other words, this groups multipple rows across various tables for a single business transaction..
	 */
	correlationId:string = undefined;
	/**
	 * Same value for event id is as
	 * signed to various rows from different tables that form a single database transac
	 * tion. 
	 * Multiple database transactions could make up one business transaction, which is
	 * 	indicated by correlation_id.  
	 * In other words, this groups multiple rows across various tables for a single da
	 * tabase transaction.
	 * Typically this is used to publish a message to a queue for consumption or a wor
	 * kflow.
	 */
	eventId:number = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 */
	auditInfo:AuditInfo = undefined;
}
export class Accessorial {
	accessorialCd:string = undefined;
	quantity:number = undefined;
	quantityUom:string = undefined;
	/**
	 * Description of the Accessorial
	 */
	accessorialDesc:string = undefined;
	/**
	 * Accessorial Amount
	 */
	chargeAmt:MonetaryAmount = undefined;
}
export class Appointment {
	apptTypeCd:string = undefined;
	/**
	 * A system generated sequence number to uniquely identify a nootification for a shipment
	 */
	seq:number = undefined;
	startDateTime:Date = undefined;
	endDateTime:Date = undefined;
	callDateTime:Date = undefined;
	/**
	 * *** Needs Review
	 */
	apptContact:ContactInfo = undefined;
	/**
	 * This might be any special instructions for accessing customer dock
	 */
	apptNote:Comment = undefined;
	/**
	 * customer provided reference number for the appointment
	 */
	apptConfirmation:string = undefined;
	/**
	 * Reason for rescheduling the appt.
	 */
	rescheduleReasonCd:string = undefined;
	apptStatus:Status = undefined;
	/**
	 * SIC that will delivery freight according to this appt.
	 */
	dlvrySIC:string = undefined;
	references:ReferenceNumber[] = undefined;
}
/**
 * 
 */
export class Bond {
	/**
	 * The IT, TE or WDTE number (7,8 or a 9 digit number) on the in-bond document. It is used to identify the Shipment while it is in the customs warehouse.
	 */
	bondNbr:string = undefined;
	/**
	 * The name of the city where the customs inspection is to be made.
	 */
	city:string = undefined;
	/**
	 * The state of the city where the customs inspection is to be made.
	 */
	state:string = undefined;
}
/**
 * Details for cash on delivery payments.
 */
export class CashOnDelivery {
	/**
	 * The description of the payment method.
	 */
	description1:string = undefined;
	/**
	 * The description of the COD amount.
	 */
	description2:string = undefined;
	/**
	 * The COD amount.
	 */
	amount:number = undefined;
}
export class ChemicalCharacteristics {
	chemProperShippingName:string = undefined;
	chemTechnicalName1:string = undefined;
	chemTechnicalName2:string = undefined;
	chemTechnicalName3:string = undefined;
	classPrimary:string = undefined;
	classSecondary:string = undefined;
	classTertiary:string = undefined;
	contentWeight:number = undefined;
	contentWeightUOM:string = undefined;
	reportableQuantityInd:boolean = undefined;
	reportQuantityWeight:number = undefined;
	reportQuantityWeightUOM:string = undefined;
	packagingGroupCd:string = undefined;
	specialProvision:string = undefined;
	emergencyGuideBookName:string = undefined;
	hotInd:boolean = undefined;
	residueInd:boolean = undefined;
	poisonousInd:boolean = undefined;
	notOtherwiseSpecifiedInd:boolean = undefined;
	limitedQuantityInd:boolean = undefined;
	toxicInhalationInd:boolean = undefined;
	passengerAircraftForbidInd:boolean = undefined;
	commercialAircraftForbidInd:boolean = undefined;
	massivePollutantInd:boolean = undefined;
	oilContainedInd:boolean = undefined;
	temperatureUOM:string = undefined;
	flashPointTemperature:number = undefined;
	emergencyTemperature:number = undefined;
	controlTemperature:string = undefined;
}
/**
 * ***Needs Review
 */
export class CommodityLine {
	/**
	 * Might be versions of a commodity line - original, corrected, as-rated
	 */
	cmdyLineTypeCd:string = undefined;
	/**
	 * sequence on shipment
	 */
	seq:number = undefined;
	/**
	 * ***Needs Review - will there be dimensions on commodities?
	 */
	dimensions:Dimensions = undefined;
	/**
	 * commodity and packing info including hazmat
	 */
	commodityInfo:CommodityInfo = undefined;
	commoditySubLine:CommodityInfo[] = undefined;
	/**
	 * this will have the rate and charge information. if weight was changed based on rated as, then the new weight will be in quantity. The class will be reflected in asRatedNmfcClass
	 */
	charge:Charge = undefined;
	/**
	 * this is an FAK that has overridden the as entered class when rated
	 */
	asRatedNmfcClass:string = undefined;
	/**
	 * This is value declared by the shipper. This might have implications for the claims.
	 */
	declaredValue:MonetaryAmount = undefined;
	/**
	 * These are refenence numbers provided by the party on the bill (shipper) specific to this commodity line.
	 */
	suppRef:SupplementalReference[] = undefined;
	reference:ReferenceNumber[] = undefined;
}
export class CommodityInfo {
	/**
	 * number of commodity items
	 */
	pieceCnt:number = undefined;
	/**
	 * packaging information
	 */
	packaging:Packaging = undefined;
	/**
	 * total weight for package and item together
	 */
	grossWeight:Weight = undefined;
	/**
	 * weight of commodity without packaging
	 */
	tareWeight:Weight = undefined;
	volume:Volume = undefined;
	/**
	 * Commodity description
	 */
	desc:string = undefined;
	nmfcClass:string = undefined;
	nmfcItemCd:string = undefined;
	/**
	 * Indicates whether shipment requires special handling to prevent freezing during harsh weather. 
	 * Y - Can be damaged by freezing
	 */
	freezableInd:boolean = undefined;
	/**
	 * Indicates whether the commodity contains hazardous materials
	 */
	hazmatInd:boolean = undefined;
	hazmatInfo:HazardousCharacteristics = undefined;
	chemicalCharacteristics:ChemicalCharacteristics = undefined;
	emergencyContactName:string = undefined;
	emergencyContactPhone:Phone = undefined;
	/**
	 * Indicates whether the piece, skid, or pallet can be stacked due to its shape or packaging.
	 */
	stackableInd:boolean = undefined;
}
/**
 * Reveals the criteria for the application of deficit rating
 */
export class DeficitRating {
	/**
	 * The freight class for this calculation. This will be eitther the actual class or if the customer has an FAK, it will be the FAK
	 */
	nmfcClass:string = undefined;
	/**
	 * The difference between the actual weight and the nearest weight break.
	 */
	deficitWght:Weight = undefined;
	/**
	 * The weight used to rate the shipment.
	 */
	asRatedWeight:Weight = undefined;
	/**
	 * The rate used to calculate the freight charges.
	 */
	deficitRate:MonetaryAmount = undefined;
	/**
	 * The amount of the deficit.
	 */
	deficitAmt:MonetaryAmount = undefined;
}
/**
 * Shipment delivery instructions.
 */
export class DoNotHoldDeliveryLine {
	/**
	 * The first line showing the description.
	 */
	description1:string = undefined;
	/**
	 * The second line showing the description.
	 */
	description2:string = undefined;
}
/**
 * Document details for FBDS/Copy-Bill for a shipment
 */
export class FBDSDocument {
	/**
	 * The unique identifier of a shipment.
	 */
	shipmentInstId:number = undefined;
	/**
	 * The business identifier for the shipment.
	 */
	proNbr:string = undefined;
	/**
	 * The business identifier for the shipment that is related to the parent PRO.
	 */
	childProNbr:string = undefined;
	/**
	 * The business identifier which is formatted for display (pppX-SSSSSc).
	 */
	formattedProNbr:string = undefined;
	/**
	 * Identifies the type of shipment.
	 */
	billClassCd:BillClassCd = undefined;
	/**
	 * The formatted serial number for the shipment.
	 */
	shipperNbr:string = undefined;
	/**
	 * The equipment number associated with the shipment.
	 */
	equipmentNbr:string = undefined;
	/**
	 * The date on which the shipment is picked up.
	 */
	pickupDate:string = undefined;
	/**
	 * The origin location for the shipment.
	 */
	originSic:string = undefined;
	/**
	 * The destination location for the shipment.
	 */
	destinationSic:string = undefined;
	/**
	 * The number of times the FBDS document was printed for the shipment.
	 */
	fbdsPrintCount:number = undefined;
	/**
	 * The shipment's shipper information.
	 */
	shipper:AsMatchedParty = undefined;
	/**
	 * The shipment's consignee message.
	 */
	consigneeMessage:string = undefined;
	/**
	 * The shipment's consignee information.
	 */
	consignee:AsMatchedParty = undefined;
	/**
	 * The customer to whom the shipment is being billed.
	 */
	billTo:AsMatchedParty = undefined;
	/**
	 * The list of commodities for the shipment.
	 */
	commodities:Commodity[] = undefined;
	/**
	 * The list of accessorials for the shipment.
	 */
	accessorials:AccessorialService[] = undefined;
	/**
	 * The type of payment.
	 */
	paymentType:string = undefined;
	/**
	 * A summary containing descriptions of all items in the current shipment.
	 */
	finalItemsDescription:string = undefined;
	/**
	 * The In Bond description.
	 */
	inBondDescription:string = undefined;
	/**
	 * The Freezable text.
	 */
	freezableText:string = undefined;
	/**
	 * The Warranty text.
	 */
	warrantyText:string = undefined;
	/**
	 * The list of Customer Reference numbers.
	 */
	customerReferenceNbrs:string[] = undefined;
	/**
	 * The list of PO numbers.
	 */
	poNbrs:string[] = undefined;
	/**
	 * The list of other reference numbers.
	 */
	otherReferenceNbrs:string[] = undefined;
	/**
	 * The CTS revenue amount.
	 */
	ctsRevenueAmount:number = undefined;
	/**
	 * The list of PRO numbers of the child shipments of the current shipment.
	 */
	childProNbrs:string[] = undefined;
	/**
	 * The advanced carrier revenue amount.
	 */
	advancedRevenueAmount:number = undefined;
	/**
	 * The beyond carrier revenue amount.
	 */
	beyondRevenueAmount:number = undefined;
	/**
	 * The collector of custom message.
	 */
	collectorOfCustomMessage:string = undefined;
	/**
	 * The code associatd with the delivery route.
	 */
	deliveryRoute:string = undefined;
	/**
	 * The clearance bill text for the MOVR/astray freight segment shipment (BillClassCd = 'I').
	 */
	movrClearanceBillText:string = undefined;
	/**
	 * Clearance bill text of the remaining pieces for the MOVR/astray freight segment shipment (BillClassCd = 'I').
	 */
	movrClearancePiecesOutstandingText:string = undefined;
	/**
	 * Verification of Hazardous Material commodities for the MOVR/astray freight segment shipment (BillClassCd = 'I').
	 */
	movrClearanceBillHazMatText:string = undefined;
	/**
	 * The COD payment method for COD type miscellaneous line items.
	 */
	codPaymentMethod:string = undefined;
	/**
	 * The COD calculated amount for COD type miscellaneous line items.
	 */
	codAmountText:number = undefined;
	/**
	 * The total number of pieces for the shipment.
	 */
	totalPieces:number = undefined;
	/**
	 * The total charge amount on line one.
	 */
	totalChargeAmountTextLine1:string = undefined;
	/**
	 * The total charge amount on line two.
	 */
	totalChargeAmountTextLine2:string = undefined;
	/**
	 * The total weight of the shipment.
	 */
	totalWeight:number = undefined;
	/**
	 * The total charge amount for the shipment.
	 */
	totalAmount:string = undefined;
	/**
	 * Advance carrier (type code 1).
	 */
	advanceCarrier:AdvanceBeyondCarrier = undefined;
	/**
	 * The notification information for the current shipment.
	 */
	notification:ShipmentNotification = undefined;
	/**
	 * Indicates if the shipment is under the Con-Way Guaranteed Service Program.
	 */
	guaranteedInd:boolean = undefined;
	/**
	 * Estimated delivery date of the shipment.
	 */
	estimatedDeliveryDate:string = undefined;
	/**
	 * Indicates credit status, if the driver needs to collect frieght amount.
	 */
	driverCollectDescription:string = undefined;
	/**
	 * Description of the discount provided.
	 */
	discountMsgDescription:string = undefined;
	/**
	 * FBDS Rates and Charges info.
	 */
	ratesAndCharges:RateAndCharge = undefined;
	/**
	 * Remarks for Shipment authority line text.
	 */
	authorityLineRemark:string = undefined;
	/**
	 * Remarks for Shipment delivery attachment.
	 */
	deliveryAttachmentRemark:string = undefined;
	/**
	 * Shipment biller initials.
	 */
	billerInitials:string = undefined;
	/**
	 * Remarks for Shipment by the Shipper.
	 */
	shipmentRemark:string = undefined;
	/**
	 * Remarks for HazMat items in the Shipment.
	 */
	hazmatRemark:string = undefined;
	/**
	 * Remarks for Shipment associated Canadian freight.
	 */
	canadianGoodsServicesRemark:string = undefined;
	/**
	 * Remarks for Shipment GST associated freight transported from/to Quebec.
	 */
	quebecGstRemark:string = undefined;
	/**
	 * Count of copies of FBDS document printed.
	 */
	nbrOfCopiesText:string = undefined;
	/**
	 * The list of handling units.
	 */
	handlingUnits:HandlingUnit[] = undefined;
	/**
	 * Indicates whether the shipment is tracked using handling units.
	 */
	trackWithHandlingUnitsInd:boolean = undefined;
	/**
	 * The code which represents results for a specific delivery activity.
	 */
	deliveryQualifierCd:DeliveryQualifierCd = undefined;
	/**
	 * The billing status of the shipment.
	 */
	billStatusCd:BillStatusCd = undefined;
	/**
	 * Indentifies the party responsible for paying the shipment.
	 */
	chargeToCd:ChargeToCd = undefined;
	/**
	 * Additional notes for the shipment.
	 */
	additionalNotes:string = undefined;
}
export class FreightExceptions {
	/**
	 * sequence number of the movement for which this exception applies
	 */
	movementSeq:number = undefined;
	exception:StatusException = undefined;
	/**
	 * Over Short and Damage
	 */
	osdPieces:number = undefined;
}
export class HazardousCharacteristics {
	countryCd:string = undefined;
	/**
	 * UN/NA Hazardous codes
	 */
	hazardousCd:string = undefined;
	desc:string = undefined;
}
export class Packaging {
	packageCd:string = undefined;
	packageWeight:Weight = undefined;
	packageDimensions:Dimensions = undefined;
	packageVolume:Volume = undefined;
}
/**
 * Information about FBDS rates and charges.
 */
export class RateAndCharge {
	/**
	 * Rates and charges information based on shipment weight.
	 */
	ratedAsWeight:RatedAsWeight = undefined;
	/**
	 * Reduced charge or discount details.
	 */
	reducedCharge:ReducedCharge = undefined;
	/**
	 * Details for Part collect, Part prepaid, Cash prepaid, and Freight charges.
	 */
	shipmentCharges:ShipmentCharge[] = undefined;
	/**
	 * Shipment delivery instructions.
	 */
	doNotHoldDeliveryLine:DoNotHoldDeliveryLine = undefined;
	/**
	 * Details of cash on delivery payments.
	 */
	cashOnDelivery:CashOnDelivery = undefined;
}
/**
 * Rates and charges information based on shipment weight.
 */
export class RatedAsWeight {
	/**
	 * The code that is associated with the type of the line item.
	 * 						Valid Values :
	 * 						1 - As weight
	 * 						2 - Deficit weight
	 * 						3 - COD Amount
	 * 						4 - Discount line (Reduced charge)
	 * 						5 - Part PPD Line
	 * 						6 - Part Collect Line
	 * 						7 - Cash Prepaid Line
	 * 						8 - Cash Collected line
	 */
	lineTypeCd:MiscLineItemCd = undefined;
	/**
	 * The description for rates and charges based on shipment weight.
	 */
	ratedAsWeightDescription:string = undefined;
	/**
	 * The rated as weight quantity.
	 */
	quantity:number = undefined;
	/**
	 * The text for the tariff rate.
	 */
	tariffRateTxt:string = undefined;
	/**
	 * The rated as weight amount.
	 */
	amount:number = undefined;
}
/**
 * The reduced charge or discount information.
 */
export class ReducedCharge {
	/**
	 * The description of the reduced charge.
	 */
	reducedChargeText:string = undefined;
	/**
	 * The reduced charge amount.
	 */
	amount:number = undefined;
	/**
	 * The reduction percentage.
	 */
	percentage:number = undefined;
}
/**
 * The selected detail for a shipment that has either a pre-delivery notification or an appointment.
 */
export class ShipmentAppointment {
	/**
	 * The unique identifier of the shipment.
	 */
	shipmentInstId:number = undefined;
	/**
	 * The business identifier of the shipment.
	 */
	proNbr:string = undefined;
	/**
	 * The number of pieces of the shipment.
	 */
	piecesCount:number = undefined;
	/**
	 * The total weight of the shipment in pounds.
	 */
	totalWeightLbs:number = undefined;
	/**
	 * The estimated delivery date of the shipment.
	 */
	estimatedDeliveryDate:string = undefined;
	/**
	 * The freight charges amount that needs to be collected at the time of delivery.  When the charge type on the shipment is both, this amount is the portion of the freight charges that are applicable to the consignee.
	 */
	freightChargesAmount:number = undefined;
	/**
	 * The COD amount of the shipment that must be collected at time of delivery.
	 */
	codAmount:number = undefined;
}
/**
 * Details for Part collect, Part prepaid, Cash prepaid, and Freight charges.
 */
export class ShipmentCharge {
	/**
	 * The type of charge - Part collect, Part prepaid, Cash prepaid, or Freight charge.
	 */
	chargeType:string = undefined;
	/**
	 * The type of payment.
	 */
	paymentType:string = undefined;
	/**
	 * The description of the charge amount.
	 */
	description:string = undefined;
	/**
	 * The Charge amount received.
	 */
	amount:number = undefined;
}
/**
 * Information about measurements for the pieces on a shipment.
 */
export class ShipmentDimension {
	/**
	 * The length measurement for the piece(s)
	 */
	length:number = undefined;
	/**
	 * The width measurement for the piece(s)
	 */
	width:number = undefined;
	/**
	 * The height measurement for the piece(s)
	 */
	height:number = undefined;
	/**
	 * The weight of the piece(s)
	 */
	weight:number = undefined;
	/**
	 * The number of pieces for which the measurement and weight values apply.
	 */
	piecesCnt:number = undefined;
	/**
	 * Indicates whether the piece(s) can be stacked.
	 */
	stackableInd:boolean = undefined;
}
/**
 * Notification information for a shipment.
 */
export class ShipmentNotification {
	/**
	 * The nofiication message for a shipment.
	 */
	message:string = undefined;
	/**
	 * The observation for the notification message.
	 */
	messageObservation:string = undefined;
	/**
	 * The custom FBDS document notification message.
	 */
	fbdsMessage:string = undefined;
	/**
	 * The date of the notification.
	 */
	notificationDate:string = undefined;
	/**
	 * The time of the notification.
	 */
	notificationTime:string = undefined;
	/**
	 * The contact name associated with the notification.
	 */
	contactName:string = undefined;
}
export class ShipmentParty {
	/**
	 * A sequential number used to identify a specifc shipment party in a database.
	 */
	shipmentPartySeq:number = undefined;
	/**
	 * An internal identifier to the customer that this shipment party references.
	 */
	custInstID:string = undefined;
	/**
	 * A business key that identifies the customer that this shipment party references.
	 */
	custMadCd:string = undefined;
	/**
	 * Identifies the type of shipment party that this is: (e.g. Shipper; Consignee; etc.  This will only be used in otherShipmentParties element
	 */
	shipmentPartyTypeCd:string = undefined;
	/**
	 * This is the name and address information that was provided by the customer directly.  This could be keyed from a paper BOL or provided via EDI or some other electronic means.  This is sometimes referred to as the 'as entered' address.
	 */
	shipmentPartyBOLAddress:Address = undefined;
	/**
	 * This is the shipment party location information that comes from the customer that the shipment party was matched to.
	 */
	shipmentPartyLocation:Location = undefined;
	/**
	 * The time stamp of the last time that this shipment party was matched to a customer.
	 */
	lastMatchedDateTime:Date = undefined;
	/**
	 * Indicates whether this shipment party is a debtor for this shipment.  Note that a 'bill-to' associated with a shipper/consignee is not a debtor.  They are actiing on behalf of the debtor.
	 */
	debtorInd:boolean = undefined;
	/**
	 * Indicates whether all shipments for this shipment party are to be billed a prepaid.
	 */
	allPrepaidInd:boolean = undefined;
	/**
	 * Indicates whether the address for this shipment party, to be used on an invoice, should be the one provided by the customer instead of the one on the customer that was matched.
	 */
	useAsEnteredInd:boolean = undefined;
	/**
	 * Indicates whether this shipment party requires a 'Proof of Delivery' document to be captured and provided with an invoice.
	 */
	proofOfDlvryRequiredInd:boolean = undefined;
	/**
	 * Indicates whether a 'Proof of Delivery' image has been recorded for this shipment party for this shipment.
	 */
	proofOfDlvryImagedInd:boolean = undefined;
	/**
	 * This iindicates that type of credit that this shipment party has.  Values: Credit; Non-credit; Bad Debt; A/R Control; Voluntary; Cash Only
	 */
	creditStatusCd:string = undefined;
	/**
	 * Defines the currency that this shipment party prefers using.
	 */
	preferredCurrency:string = undefined;
	/**
	 * This is an internal identifier to the customs broker that this shipment party uses for a shipment that will cross a border.
	 */
	brokerInstID:string = undefined;
	/**
	 * This is a business key that identifies the customs broker that this shipment party uses for a shipment that will cross a border.
	 */
	brokerBusinessKey:string = undefined;
	/**
	 * This is a code that defines the relationship that a bill-to shipment party has to the shipper and the consignee.  Values are: None; Shipper; Consignee; Both
	 */
	bil2RelationshipCd:string = undefined;
	suppRef:SupplementalReference[] = undefined;
	/**
	 * Match status from CIS matching. Valid Values :
	 * The following values ARE ACTIVE for Forward Sync:
	 * Spaces - Initial value, matching activity has not yet occurred. 
	 * '70' - Full Match 
	 * '60' - Un-matched (valid name / address but does not exist in CIS)
	 * The following values ARE NOT ACTIVE for Forward Sync:
	 * '15' - Possible matches found
	 * '30' - Invalid address (Bad US postal address as per Code-1)
	 * '50' - Blank bill2 - no bill-to was entered
	 */
	mchStatCd:string = undefined;
	/**
	 * Contains the initials of the Review Clerk who performed matching on a pro, or forced the MAD code on a pro to a different MAD using a Pro Override.
	 */
	mchInitTxt:string = undefined;
	/**
	 * Used to group customer oriented service reports and manifests for direct customer access via the online system.
	 */
	directCd:string = undefined;
	/**
	 * Flag to indicate whether the Bill-To is related to the Shipper.
	 */
	bil2RelToShipperInd:boolean = undefined;
	/**
	 * Flag to indicate whether the Bill-To is related to the Consignee.
	 */
	bil2RelToConsigneeInd:boolean = undefined;
	/**
	 * Contains the customer key to an uncoded customer in the customer component. These are pickup and delivery locations that have been a part of at least one shipment in the Con-way system. (Note that they are not currently stored in the P and D table in the customer component.) They do not have an assigned MAD code (uncoded) and are therefore identified and matched separately so that we know when we service the same physical location.
	 */
	alternateCustNbr:string = undefined;
	/**
	 * Phone information for the party on the shipment.
	 */
	phone:Phone = undefined;
}
export class ShipmentPartyBasic {
	/**
	 * An internal identifier to the customer that this shipment party references.
	 */
	custInstID:string = undefined;
	/**
	 * A business key that identifies the customer that this shipment party references.
	 */
	custMadCd:string = undefined;
	/**
	 * Identifies the type of shipment party that this is: (e.g. Shipper; Consignee; etc.  This will only be used in otherShipmentParties element
	 */
	shipmentPartyTypeCd:string = undefined;
	/**
	 * This is the name and address information that was provided by the customer directly.  This could be keyed from a paper BOL or provided via EDI or some other electronic means.  This is sometimes referred to as the 'as entered' address.
	 */
	shipmentPartyBOLAddress:Address = undefined;
	suppRef:SupplementalReference[] = undefined;
}
/**
 * A generic representation of an indicator for special treatment of a delivery/pickup.
 */
export class ShipmentSpecialServiceSummary {
	/**
	 * The types of special services.
	 */
	specialService:ShipmentSpecialServiceCd = undefined;
	/**
	 * A boolean value indicating if the indicator type is applicable.
	 */
	applicableInd:boolean = undefined;
}
/**
 * 
 */
export class AccessorialService {
	/**
	 * 
	 */
	shipmentInstId:number = undefined;
	/**
	 * Expanded Name:  Sequence Number
	 * 
	 * Desc : Sequential number assigned to uniquely identify  each extra service for the shipment.
	 * 
	 * Source : Generated
	 * 
	 * Design Considerations :
	 */
	sequenceNbr:string = undefined;
	/**
	 * Expanded Name : Accessorial  code
	 * 
	 * Desc : Indicates the type of accessorial service requested or performed
	 */
	accessorialCd:string = undefined;
	/**
	 * Expanded Name : Tariff rate
	 * 
	 * Desc : This is the rate used to calculate the accessorial charges for an accessorial service . The rate is multiplied by the unit of measure to calculate the total charges. 
	 * 
	 * Source : MBF Variable Segment -  MBCRATE
	 */
	tariffsRate:number = undefined;
	/**
	 * Expanded Name :  Charge Amount
	 * 
	 * Desc : The dollar amount of the charge for the accessorial service.
	 */
	amount:number = undefined;
	/**
	 * Expanded Name: Percent Prepaid
	 * 
	 * Desc: Percenatge of the charge amount for the accessorial is to be paid by the shipper. The rest of it is paid by the consignee.
	 */
	prepaidPercentage:number = undefined;
	/**
	 * Expanded Name : Minimum charge indicator
	 * 
	 * Desc : Indicates if the charges associated with the accessorial are the minimum charges for the extra service. 
	 * 
	 * Source : MBF: root: MBMINCHG (x 10  of MBFLAG7)
	 * 
	 * Valid Values :  Y/N
	 */
	minimumChargeInd:boolean = undefined;
	/**
	 * Expanded Name : Description
	 * 
	 * Desc : A textual description of the service.
	 * 
	 * Source : MBF  Variable Segment :
	 */
	description:string = undefined;
	/**
	 * 
	 */
	archiveControlCd:string = undefined;
	/**
	 * Indication whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Specifies the units for the quantity for which the accessorial fee is charged.
	 * 
	 * Valid Values
	 * 1- Labour Hour
	 * 2- 1/4 hour
	 * 3- 1/2 hour
	 * 4- CWT
	 * 5- CWT/Day
	 * 6- Check
	 * 7- Correction
	 * 8- Day
	 * 9- Document
	 * A- Package
	 * B- Shipment
	 * C- Vehicle
	 * D- Mile
	 * E- Pound
	 * ' ' - Not applicable
	 */
	accessorialUnitOfMeasure:AccessorialUnitOfMeasureCd = undefined;
	/**
	 * Specifies the quantity of units (in AC_UOM) for which the accessorial fee is charged.
	 */
	accessorialQuantity:number = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * 
	 */
	chrgToCd:LineItemChargeToCd = undefined;
}
/**
 * 
 */
export class AdvanceBeyondCarrier {
	/**
	 * 
	 */
	shipmentInstId:number = undefined;
	/**
	 * Expanded Name : Sequence number
	 * 
	 * Desc : A system assigned number to uniquely identify each carrier for a shipment. There can be at most two other carriers for a shipment one advance and one beyond.
	 */
	sequenceNbr:string = undefined;
	/**
	 * Expanded Name : Type code 
	 * 
	 * Desc : Identifies the type movement carried out by the carrier.
	 * 
	 * 
	 * Valid Values:
	 * 1 = Advance carrier
	 * 2 = Beyond carrier
	 */
	typeCd:AdvanceBeyondTypeCd = undefined;
	/**
	 * Expanded Name: Standard Carrier Abbreviation Code
	 * 
	 * Desc : Other Carrier s identification code for Advance and Beyond movements for the shipment.
	 * 
	 * This is the identifying code for the carrier from whom CTS picked up (or transfers to) the freight. If the code is unknown, NONE is entered by the bill entry personnel. 
	 * 
	 * Source : MBF root  -  MBADSCAC
	 */
	carrierScacCd:string = undefined;
	/**
	 * Expanded Name : PRO number (in text format) 
	 * 
	 * Desc : This is  the other carrier s PRO number that identifies the shipment for the carrier in their systems.
	 */
	carrierProNbr:string = undefined;
	/**
	 * Expanded Name: Carrier pick up date
	 * 
	 * Desc: This is the date the carrier picked up the shipment. 
	 * 
	 * In case of an advance carrier this is the date the carrier picked it up from the customer.
	 * 
	 * 	In case of beyond carriers, it is the date the carrier picked up the shipment from CTS.
	 */
	carrierPickupDate:string = undefined;
	/**
	 * Expanded Name : From terminal SIC
	 * 
	 * Desc : SIC where the shipment originates for the carrrier.  
	 * 
	 * For advance carriers it is the sic that services the shipper and for beyond carrier this is CTS service center that delivers teh PRO to the beyond carrier. 
	 * 
	 * This is mostly stored for Beyond carriers.
	 * 
	 * Source :
	 */
	fromTerminalSicCd:string = undefined;
	/**
	 * Expanded Name : To terminal SIC
	 * 
	 * Desc : This is CTS SIC where an advance carrier dropped the shipment for further movement. For beyond carrier this is the SIC code that services the consignee. 
	 * 
	 * Source :
	 */
	toTerminalSicCd:string = undefined;
	/**
	 * Expanded Name :  Charge Amount
	 * 
	 * Desc : The dollar amount of the advance or beyond charge.
	 */
	chargeAmount:number = undefined;
	/**
	 * 
	 */
	archiveControlCd:string = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class AsMatchedParty {
	/**
	 * 
	 */
	shipmentInstId:number = undefined;
	/**
	 * Expanded Name : Sequence Number
	 * 
	 * Desc : A system assigned sequential number to uniquely identify each As Entered Customer for a Shipment.
	 * 
	 * There are always at least two customers for a shipment and atmost 6 customers for a shipment.
	 */
	sequenceNbr:string = undefined;
	/**
	 * Expanded Name :  Type code
	 * 
	 * Desc : Specifies the nature of relationship of the customer with respect to the Service Item in question. For Shipments, a Customer can be the shipper, consignee, bill-to, requestor or contact. 
	 * 
	 * Permitted Values:
	 * 										1=Shipper address
	 * 										2=Consignee address 
	 * 										3=Inbound bill-to address 
	 * 										4=Outbound bill-to address
	 * 										5=Requestor address
	 * 										6=Contact address 
	 * 
	 * Source : Internal
	 */
	typeCd:MatchedPartyTypeCd = undefined;
	/**
	 * Expanded Name : Debtor indicator
	 * 
	 * Desc : Specifies whether the customer is also responsible for paying some or all of the charges for a Service Item. Invoices, allocations (if any) are created only for debtors. 
	 * 
	 * Valid Values : Y/N
	 */
	debtorInd:boolean = undefined;
	/**
	 * Expanded Name : Name Line 1 (text format)
	 * 
	 * Desc : Line 1 of the customer name
	 * 
	 * Source : MBF variable segments
	 * 													AA  Shipper segment
	 * 													AB  Consignee segment
	 * 													AC  Bill to segment
	 */
	name1:string = undefined;
	/**
	 * Expanded Name : Name Line 2 (text format)
	 * 
	 * Desc : Second line (if any) of customer name
	 * 
	 * Source: MBF variable segments
	 * 	AA  - Shipper segment
	 * 	AB  - Consignee segment
	 * 	AC  - Bill to segment
	 */
	name2:string = undefined;
	/**
	 * Expanded Line : Address 
	 * 
	 * Desc : Street address of the customer location.
	 * 
	 * Source: MBF variable segments
	 * 	AA  Shipper segment
	 * 	AB  Consignee segment
	 * 	AC  Bill to segment
	 */
	address:string = undefined;
	/**
	 * Expanded Name : City
	 * 
	 * Desc : City where the customer is located
	 * 
	 * Source: MBF variable segments
	 * 	AA  - Shipper segment
	 * 	AB  - Consignee segment
	 * 	AC  - Bill to segment
	 */
	city:string = undefined;
	/**
	 * Expanded Name : State (code)
	 * 
	 * Desc : State where the customer is located
	 * 
	 * Source: MBF variable segments
	 * 	AA  - Shipper segment
	 * 	AB  - Consignee segment
	 * 	AC  - Bill to segment
	 */
	stateCd:string = undefined;
	/**
	 * Expanded Name : Country Code
	 * 
	 * Desc : Specifies the code of the country where the customer is located
	 * 
	 * Source: MBF variable segments
	 * 	AA  - Shipper segment
	 * 	AB  - Consignee segment
	 * 	AC  - Bill to segment
	 */
	countryCd:string = undefined;
	/**
	 * Expanded Name : Zip (code) first 5 (characters) (6 characters for Canadian zips)
	 * 
	 * Desc : First 5 characters of the zip code for a US address or first 6 characters of the Canadian zip code of the address where the customer is located. 
	 * 
	 * Source: MBF variable segments
	 * 	AA  - Shipper segment
	 * 	AB  - Consignee segment
	 * 	AC  - Bill to segment
	 */
	zip6:string = undefined;
	/**
	 * Expanded Name : Zip (Code) last 4 (characters)
	 * 
	 * Desc : Last 4 characters of the zip code for a US address
	 * 
	 * Source: MBF variable segments
	 * 	AA  - Shipper segment
	 * 	AB  - Consignee segment
	 * 	AC  - Bill to segment
	 */
	zip4RestUs:string = undefined;
	/**
	 * Expanded Name : Matching Status
	 * 
	 * Desc : Match status from CIS matching.
	 * 
	 * Valid Values :
	 * 
	 * The following values ARE ACTIVE for Forward Sync:
	 * 
	 * Spaces - Initial value, matching activity has not yet occurred.  
	 * 	70  - Full Match 
	 * 	60  - Un-matched (valid name / address but does not exist in CIS)
	 * 
	 * The following values ARE NOT ACTIVE for Forward Sync:
	 * 
	 * 	15  - Possible matches found
	 * 	30  - Invalid address (Bad US postal address as per Code-1)
	 * 	50  - Blank bill2 - no bill-to was entered
	 * 
	 * The following was added by C.Doucet 10/12/2001:
	 * 
	 * 	CM  - CIS Customer Merge correction changed the CIS cust nbr value
	 */
	matchedStatusCd:MatchedPartyStatusCd = undefined;
	/**
	 * Expanded Name : Matcher s Initials 
	 * 
	 * Desc : Contains the initials of the Review Clerk who performed matching on a pro, or forced the MAD code on a pro to a different MAD using a Pro Override.  
	 * 
	 * Source : Extended Master Bill File. These initials are the same as those stored on the EMB (JABCOB).
	 */
	matchedInitials:string = undefined;
	/**
	 * Expanded Name : CIS Customer Number
	 * 
	 * Desc : Identifier of the appropriate customer in CIS, determined by the matching sub-routine
	 */
	cisCustNbr:number = undefined;
	/**
	 * Expanded Name : As-Matched MAD code
	 * 
	 * Desc : Customer Account code.  This is the MAD code that came from CIS at the time of Customer Matching.
	 * 
	 * Source : Customer Information System
	 */
	asMatchedMadCd:string = undefined;
	/**
	 * Expanded Name : Direct code
	 * 
	 * Desc : Used to group customer oriented service reports and manifests for direct customer access via the online system.
	 * 
	 * This is a de-normalisation as direct code is available only for CIS customers and it should be picked up from appropriate CIS table. 
	 * 
	 * Source : MBF: root: MBSCUST
	 */
	dirCd:string = undefined;
	/**
	 * Expanded Name : Credit Status Code
	 * 
	 * Desc : Specifies the credit rating / status for a Customer, if not set up in CIS. If the customer exists in CIS, it is copied here for performance reasons.
	 * 
	 * Source : MBF: root: MBONOST (Out-bound)
	 * 														MBF: root: MBINOST (In-bound)
	 * 
	 * 
	 * Valid Values : 
	 * 
	 * B=Bad debt/MBOBAD
	 * C=Credit/MBOCRED
	 * N=Non-credit/MBONOCRD
	 * P=PCO Control/MBOPCO
	 * V=Voluntary/MBOVOLON
	 * $=Cash only/MBOCASH
	 */
	creditStatusCd:ShipmentCreditStatusCd = undefined;
	/**
	 * Expanded Name:  Bill to Relationship Code
	 * 
	 * Desc: Indicates who the Bill to customer is related to (Shipper or Consignee) in the CIS system. This field applies only to BILL TO type of customers. 
	 * 
	 * Valid Values:
	 * 				- Not Applicable
	 * S - Bill To is related to Shipper
	 * C - Bill To is related to Consignee
	 * B - Bill To is related to Both
	 * N - Bill To is related to None
	 */
	billToRelationshipCd:BillToRelationCd = undefined;
	/**
	 * Expanded Name : Use as entered indicator
	 * 
	 * Desc : 
	 * 
	 * Source : E-commerce
	 * 
	 * Valid Values : Y/N
	 */
	useAsEntrdInd:boolean = undefined;
	/**
	 * Expanded Name : All shipments (must be) prepaid indicator
	 * 
	 * Desc : Specifies whether all shipments from the customer have to be pre-paid
	 * 
	 * Source : MBF: root: MBR4PPDA (x 02  of MBRGFLG4)
	 * 
	 * Valid Values : Y/N
	 */
	allShipmentPrepaidInd:boolean = undefined;
	/**
	 * Expanded Name :   Proof Of Delivery  required indicator
	 * 
	 * Desc : Indicates whether the customer (usually shipper and / or consignee) requires a Proof Of Delivery.
	 * 
	 * Source : MBF: root: MBSPD (x 80  of MBFLAGD)
	 * 
	 * Valid Values : Y/N
	 */
	proofOfDeliveryRequiredInd:boolean = undefined;
	/**
	 * Expanded Name :  Proof Of Delivery  image indicator
	 * 
	 * Desc : Specifies that an image of the delivery receipt will suffice, instead of an original 
	 * 
	 * Source : MBF: root: MBSMG (x 40  of MBFLAGD)
	 * 
	 * Valid values : Y/N
	 */
	proofOfDeliveryImageInd:boolean = undefined;
	/**
	 * Expanded Name : Broker customer key number
	 * 
	 * Desc : For international shipments, identifies the CIS customer number of any broker for the shipper or consignee. 
	 * 
	 * Source : MBF: root: MBBRKKEY
	 */
	brokerCustKeyNbr:number = undefined;
	/**
	 * Expanded Name :  Customer E-mail Id
	 * 
	 * Desc : E-mail address of the Customer on a bill, used as supplemental information in case that customer needs to be contacted via e-mail.
	 */
	eMailId:string = undefined;
	/**
	 * Expanded Name : Phone Country Code 
	 * 
	 * Desc : Country code for the phone number of the customer (if any) for a Shipment. 
	 * 
	 * Source : E-commerce
	 */
	phoneCountryCdNbr:string = undefined;
	/**
	 * Expanded Name :  Phone Area Code 
	 * 
	 * Desc : Area Code for phone number of the customer (if any) for a Shipment. 
	 * 
	 * Source : E-commerce
	 */
	phoneAreaCdNbr:string = undefined;
	/**
	 * Expanded Name : Customer Phone 
	 * 
	 * Desc : Phone number of the customer (if any) for a Shipment. 
	 * 
	 * Source : E-commerce
	 */
	phoneNbr:string = undefined;
	/**
	 * Expanded Name : Customer Phone Extension Number
	 * 
	 * Desc : Phone Extension of the customer (if any) for a Shipment. 
	 * 
	 * Source : E-commerce
	 */
	phoneExtensionNbr:string = undefined;
	/**
	 * Expanded Name : Last Match Timestamp
	 * 
	 * Desc : Identifies the last time Customer Matching was performed against this particular customer for this Service Item.
	 * 
	 * Source : MBF root: MBTMDTE
	 */
	lastMatchedDateTime:Date = undefined;
	/**
	 * 
	 */
	archiveControlCd:string = undefined;
	/**
	 * Expanded Name: Preferred Payment Currency Code
	 * 
	 * Desc: The customer prefers to make payments in this currecny.
	 * 
	 * Valid Values:
	 * 
	 * USD - US Dollars
	 * CDN - Canadian Dollars
	 */
	preferredPaymentCurrencyCd:CurrencyCd = undefined;
	/**
	 * Expanded Name: Alternate Customer Number
	 * 
	 * Desc: Contains the customer key to an uncoded customer in the customer component.  These are pickup and delivery locations that have been a part of at least one shipment in the Con-way system.  (Note that they are not currently stored in the P  table in the customer component.) They do not have an assigned MAD code (uncoded) and are therefore identified and matched separately so that we know when we service the same physical location.
	 * 
	 * OR
	 * Contains the non-debtor P location where there were multiple full matches (89% and higher) at the same perentage.  (Changed meaning of attribute 3/1/2010)
	 * 
	 * Added 2/2/2010 Cathy Doucet (for Hand Held Scorecard project)
	 */
	alternateCustNbr:number = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Identifies whether the system full matched the party, or if the Biller chose from a list of possibles. 
	 * 
	 * Valid Values 
	 * S=System Matched
	 * U=User Selected
	 * F=User Selected from Search
	 * N=Not matched
	 */
	matchedSourceCd:MatchedPartySourceCd = undefined;
	/**
	 * Identifies if the invoice address should be read from the PICKUP_DELIVERY_LOCATION(self) versus from an associated Bill To.
	 */
	selfInvoiceInd:boolean = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 */
	auditInfo:AuditInfo = undefined;
}
export class Commodity {
	/**
	 * 
	 */
	shipmentInstId:number = undefined;
	/**
	 * Expanded Name : Sequence number
	 * 
	 * Desc : A system assigned number to uniquely identify each commodity for a shipment.
	 */
	sequenceNbr:string = undefined;
	/**
	 * Expanded Name : (Commodity) class
	 * 
	 * Desc : Identifies the type (class) of commodity being shipped.
	 * 
	 * Examples:  Carpet, Matresses, Computer paper
	 * 
	 * Valid Values : 50, 55, 60, 65, 70, 77.5, 85, 92.5, 100, 110, 125, 150, 175, 200, 250, 300, 350, 400, 500  
	 * 
	 * Source: MBF Variable Segment : MBVDESC
	 */
	classType:CommodityClassCd = undefined;
	/**
	 * Expanded Name : NMFC item code
	 * 
	 * Desc : This is the NMFC (National Motor Freight Classification) code for the commodity.
	 * 
	 * Source: MBF Variable Segment - MBNMFC
	 */
	nmfcItemCd:string = undefined;
	/**
	 * Expanded Name : Source Code
	 * 
	 * Desc : Code indicating the source of a particular Commodity Line.  Provides a way to distinguish between a Commodity Line on a bill that originated from the Common Shipper Bill of Lading (CSBOL) profile or a new Commodity Line that is added onto a bill where CSBOL Commodity Lines already exist.
	 * 
	 * Source : FBES E-Commerce
	 * 
	 * Valid Values :
	 * 1 - from CSBOL
	 * 2 - Not from CSBOL
	 */
	sourceCd:string = undefined;
	/**
	 * Expanded Name: Packaging code
	 * 
	 * Desc : Type of packaging the commodity was originally picked up in. 
	 * 
	 * Examples: Box, Skid, Case, Roll etc.
	 * 
	 * Source: MBF Variable Segment - MBVDESC
	 */
	packageCd:CommodityPackageCd = undefined;
	/**
	 * Expanded Name : Pieces count
	 * 
	 * Desc : Number of pieces of the commodity in the shipment.
	 * 
	 * Source : MBF Variable Segment - MBPIECES
	 */
	piecesCount:number = undefined;
	/**
	 * Expanded Name : Volume in Cubic feet
	 * 
	 * Desc : The volume of the freight; the height multiplied by the width multiplied by the depth
	 * 
	 * Source :
	 */
	volumeCubicFeet:number = undefined;
	/**
	 * Expanded Name: Total weight (in pounds)
	 * 
	 * Desc : Total weight of the commdity in pounds. 
	 * 
	 * Source: MBF Root - MBTOTWT
	 */
	weightLbs:number = undefined;
	/**
	 * Expanded Name : Reduced weight
	 * 
	 * Desc : This is the weight deducted from the actual weight of the commodity to get the net weight. This weight is normally the pallet or the container weight. This part of the weight gets a free ride.
	 * 
	 * Source : MBF Variable Segment - MBWT
	 */
	reducedWeight:number = undefined;
	/**
	 * Expanded Name : Rating Unit of Measure
	 * 
	 * Desc : A code to identify the units in terms of which the quantity is measured for calculating charges. For eg. weight (in pounds) is the most common unit of measure for commodities.
	 * 
	 * Valid Values : 
	 * 1 - Weight (in pounds)
	 * 2 - Yardage (for carpets)
	 * 3 - Count
	 */
	ratingUnitOfMeasure:string = undefined;
	/**
	 * Expanded Name : Quantity
	 * 
	 * Desc : Quantity (measured in terms of UOM) to be used when calculating charges for the commodity.
	 */
	ratingQuantity:number = undefined;
	/**
	 * Expanded Name : Freezable Indicator 
	 * 
	 * Desc : Indicates whether shipment requires special handling to prevent freezing during harsh weather. 
	 * 
	 * Source: MBF root - MBFZB (x 08  of MBTRAFIC)
	 * 
	 * Valid Values : Y/N
	 * 
	 * Y - Can be damaged by freezing
	 */
	freezableInd:boolean = undefined;
	/**
	 * Expanded Name : Hazardous material indicator
	 * 
	 * Desc : Indicates whether the commodity is a hazardous material. 
	 * 
	 * Source : MBF Variable Segment : MBNMHMX
	 * 
	 * Valid Values :
	 * Y - Has hazardous materials
	 * N - No hazardous materials
	 */
	hazardousMtInd:boolean = undefined;
	/**
	 * Expanded Name : Tariff rate
	 * 
	 * Desc : This is the rate used to calculate the total freight charges for a commodity. The rate is multiplied by the unit of measure (weight in most cases) to calculate the total charges. 
	 * 
	 * Source : MBF Variable Segment -  MBCRATE, MBRATES
	 */
	tariffsRate:number = undefined;
	/**
	 * Expanded Name :  Charge Amount
	 * 
	 * Desc : The dollar amount of the charge for the commodity.
	 */
	amount:number = undefined;
	/**
	 * Expanded Name: Percent Prepaid
	 * 
	 * Desc: Percenatge of the charge amount for the accessorial is to be paid by the shipper. The rest of it is paid by the consignee.
	 */
	prepaidPercentage:number = undefined;
	/**
	 * Expanded Name : Minimum charge indicator
	 * 
	 * Desc : Indicates if the charges associated with the line are the minimum charges for the commodity. 
	 * 
	 * Source : MBF: root: MBMINCHG (x 10  of MBFLAG7)
	 * 
	 * Valid Values :  Y/N
	 */
	minimumChargeInd:boolean = undefined;
	/**
	 * Expanded Name : Mixed class commodity exists indicator
	 * 
	 * Desc :
	 * 
	 * Source : MBF: root: MBMXCLS (x 01  of MBFLAGA)
	 * 
	 * Valid Values : Y/N
	 */
	mixClassCommodityInd:boolean = undefined;
	/**
	 * Expanded Name : Description
	 * 
	 * Desc : A textual description of the commodity
	 */
	description:string = undefined;
	/**
	 * Expanded Name : Archive Control Code
	 * 
	 * Desc : Identifies a row of this table as archived or retrieved from archive.  The Princeton Archive tool sets the value upon archive or retrieval of data for selected PROs.
	 * 
	 * FOR USE BY PRINCETON ARCHIVE/RETRIEVE PROCESSING ONLY!
	 * 
	 * Valid Values:
	 * A - Row is flagged for Archive processing
	 * R - Row has been retrieved from archive
	 * blank - row is associated with a currently active PRO (default)
	 */
	archiveControlCd:string = undefined;
	/**
	 * 
	 */
	asRatedClassCd:CommodityClassCd = undefined;
	/**
	 * 
	 */
	chrgToCd:LineItemChargeToCd = undefined;
	/**
	 * 
	 */
	originalDescription:string = undefined;
	/**
	 * Indicates that the biller selected to have the commodity class default to 100 during bill entry since the class was not listed on the Bill of Lading.  This is to distinguish between commodity lines that actually have a true commodity class of 100 and commodity lines where the default situation occurs.  Valid Values :  Y/N
	 */
	defaultClassSelectedInd:boolean = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * 
	 */
	hazMat:HazMat = undefined;
	/**
	 * 
	 */
	commodityDimension:CommodityDimension[] = undefined;
	/**
	 * The numeric ranking (class) that is assigned to the commodity which describes its transportation characteristics.
	 */
	nmfcClass:string = undefined;
}
/**
 * 
 */
export class CommodityDimension {
	/**
	 * 
	 */
	shipmentInstId:number = undefined;
	/**
	 * 
	 */
	commoditySequenceNbr:string = undefined;
	/**
	 * Expanded Name: Sequence Number
	 * 
	 * Desc: A system generated sequential number to uniquely identify the recorded dimensions for a shipment.
	 */
	dimSequenceNbr:string = undefined;
	/**
	 * Expanded Name: Pieces Count
	 * 
	 * Desc: This is the number of pieces the recorded dimensions apply to.
	 */
	piecesCount:number = undefined;
	/**
	 * Expanded Name: Length                               
	 * 																																																				
	 * Description: This is the length of the whole or part
	 * of the shipment. The number of pieces attribute     
	 * indicates how many pieces of the shipment the       
	 * dimensions apply to.
	 */
	lengthNbr:number = undefined;
	/**
	 * Expanded Name: Width                                   
	 * 																																																							
	 * Description: This is the width of the whole or part of 
	 * the shipment. The number of pieces attribute indicates 
	 * how many pieces of the shipment the dimensions apply   
	 * to.
	 */
	widthNbr:number = undefined;
	/**
	 * Expanded Name: Height                                
	 * 																																																								
	 * Description: This is the height of the whole or part of  
	 * the shipment. The number of pieces attribute indicates  
	 * how many pieces of the shipment the dimensions apply    
	 * to.
	 */
	heightNbr:number = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * The weight of the segment of the shipment that is being inspected.
	 */
	weightLbs:number = undefined;
}
/**
 * Holds a snapshot of CommodityDimension. Contains the details at the time on an event. It also provides the previous values if an update occured
 * null
 */
export class CommodityDimensionSnapshot {
	/**
	 * The action that created this snapshot. Add, Update or Delete
	 */
	eventActionCd:ActionCd = undefined;
	/**
	 * The previous snapshot, before this record was updated.
	 */
	before:CommodityDimension = undefined;
	/**
	 * The current snapshot.
	 */
	after:CommodityDimension = undefined;
}
/**
 * 
 */
export class CustomsBond {
	/**
	 * 
	 */
	shipmentInstId:number = undefined;
	/**
	 * Expanded Name : Sequence number
	 * 
	 * Desc : System generated sequence number assigned to each instance to uniquely identify each customs bond information for a shipment
	 */
	sequenceNbr:number = undefined;
	/**
	 * Expanded Name : Bond number text
	 * 
	 * Desc : This is the IT, TE or WDTE number (7,8 or a 9 digit number) on the in-bond document. It is used to identify the Shipment while it is in the customs warehouse.
	 * 
	 * Source: MBF  Variable Segment - MBITNUM
	 */
	bondNbr:string = undefined;
	/**
	 * Expanded Name : City
	 * 
	 * Desc : Name of the City where the customs inspection is to be made.
	 * 
	 * Source: MBF Variable Segment - MBINBOND
	 */
	city:string = undefined;
	/**
	 * Expanded Name : State
	 * 
	 * Desc : State of the city where the customs inspection is to be made.
	 * 
	 * Source: MBF Variable Segment - MBINBOND
	 */
	stateCd:string = undefined;
	/**
	 * 
	 */
	archiveControlCd:string = undefined;
	/**
	 * Type of Bond.  Added 08/05/2022  LISG-17338
	 * 
	 * Valid Values:
	 * PLANNED
	 * UNPLANNED
	 */
	bondTypeCd:BondTypeCd = undefined;
	/**
	 * The status of the Bond.  Added 08/05/2022 LISG-17338
	 * 
	 * Valid Values:
	 * CLEARED
	 * UNCLEARED
	 */
	bondStatusCd:BondStatusCd = undefined;
	/**
	 * The direction of the shipment.  Added 09/12/2022 LISG-24116
	 * 
	 * Valid Values:
	 * NORTHBOUND
	 * SOUTHBOUND
	 */
	shipmentDirectionCd:ShipmentDirectionCd = undefined;
	/**
	 * The reason for the Customs Bond.  Added 09/12/2022 LISG-24116
	 * 
	 * Valid Values:
	 * NEEDS_CLIENT_AUTH     - Broker Needs Client Auth
	 * RCVD_PPWK_NOT_SET_UP  - Broker Rcvd PPWK/Did not set up
	 * REJECTED              - Broker Rejected - Not Theirs
	 * MISC                  - Miscellaneous
	 * MISSING_CUSTOMS_PPWK  - Missing Customs PPWK
	 * MOVR_PRO              - MOVE Pro
	 * NO_BROKER_INDICATED   - No Broker Indicated
	 * NO_ORIGIN_COUNTRY     - No Country of Origin
	 * OVERAGE               - Overage
	 * VALUE_NOT_INDICATED   - Value not Indicated
	 * VIASAFE_OPERATOR_ERR  - ViaSafe Operator Error
	 */
	bondReasonCd:BondReasonCd = undefined;
	/**
	 * Date the Bond was created.  Added 09/12/2022  LISG-24116
	 */
	bondCreateDate:string = undefined;
	/**
	 * Date the Bond was cleared.  Added 09/12/2022 LISG-24116
	 */
	bondClearDate:string = undefined;
	/**
	 * The clearance service center to which an in-bond shipment is headed.  Added 09/12/2022  LISG-24116
	 */
	bondedSicCd:string = undefined;
	/**
	 * Port to which a shipment is bonded. Added 09/16/2022  LISG-24116
	 */
	bondedPort:string = undefined;
	/**
	 * The value of the freight we are at risk of being penalized if not handled properly. Added 09/16/2022 LISG-24116
	 */
	bondValueAmount:number = undefined;
	/**
	 * The customs broker.  Added 09/16/2022  LISG-24116
	 */
	broker:string = undefined;
	/**
	 * CRC audit status to make sure the correct paperwork for release is imaged.  Added 09/16/2022
	 * 
	 * Valid Values:
	 * <spaces>
	 * IN_PROGRESS
	 * COMPLETED
	 * FAILED
	 */
	crcAuditCd:CrcAuditCd = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * Holds a snapshot of CustomsBond. Contains the details at the time on an event. It also provides the previous values if an update occured
 * null
 */
export class CustomsBondSnapshot {
	/**
	 * The action that created this snapshot. Add, Update or Delete
	 */
	eventActionCd:ActionCd = undefined;
	/**
	 * 
	 */
	shipmentInstId:number = undefined;
	/**
	 * Expanded Name : Sequence number
	 * 
	 * Desc : System generated sequence number assigned to each instance to uniquely identify each customs bond information for a shipment
	 */
	sequenceNbr:string = undefined;
	/**
	 * The previous snapshot, before this record was updated.
	 */
	before:CustomsBond = undefined;
	/**
	 * The current snapshot.
	 */
	after:CustomsBond = undefined;
}
/**
 * 
 */
export class HandlingUnit {
	/**
	 * Expanded Name : Instance identifierDesc : System generated unique identifier (as per CBD standard).
	 */
	shipmentInstanceId:number = undefined;
	/**
	 * A system assigned number	used as part of how to uniquely identify each handling unit of a	shipment.
	 */
	sequenceNbr:number = undefined;
	/**
	 * Child PRO	number identifies a handling unit of a shipment. This is an alternate	key of the table.The numbers are printed on labels and are assigned to service centers.	Labels are placed on 2 sides of each pieces (usually by pallet) of the	shipment picked up from the customer.Child PRO number format is 0ppppSSSSSc where pppp is the prefix from	1001 to 9999 (excluding numbers where last digit is 0, ex: 2000), SSSSS	is a suffix/serial number, and c is a check-digit of the ppppSSSSS	using modulus 7.
	 */
	childProNbr:string = undefined;
	/**
	 * This is the	parent PRO number of the handling unit. A denormed field from the	parent shipment table.Refer to the main source table for more description.
	 */
	parentProNbr:string = undefined;
	/**
	 * The date of when a	shipment has to be picked up
	 */
	pickupDate:string = undefined;
	/**
	 * Weight of this handling unit.
	 */
	weightLbs:number = undefined;
	/**
	 * Volume in cubic feet of this handling unit.
	 */
	volumeCubicFeet:number = undefined;
	/**
	 * Length of this handling unit.
	 */
	lengthNbr:number = undefined;
	/**
	 * Width of this handling unit.
	 */
	widthNbr:number = undefined;
	/**
	 * Height of this handling unit.
	 */
	heightNbr:number = undefined;
	/**
	 * SIC where the Shipment currently is.
	 */
	currentSicCd:string = undefined;
	/**
	 * Trailer	Load Instance ID. A denormalized system generated unique identifier of a piece of equipment that is currently involved with this	handling unit.
	 */
	currentTrailerInstanceId:number = undefined;
	/**
	 * The current dock location of this handling unit.
	 */
	currentDockLocation:string = undefined;
	/**
	 * The movement status that reflects the latest movement event on a handling unit.Valid	Values : Spaces - Not applicable1 - On dock2 - On trailer3 - Out for delivery4-  Interim Delivery5 - Final Delivered6 - Not Applicable6 - No	movements allowed7 - Cancell
	 */
	movementStatusCd:string = undefined;
	/**
	 * Date and time, when the last movement for this handling unit occurred.
	 */
	lastMovementDateTime:Date = undefined;
	/**
	 * Indicates whether the handling unit can be stacked or not.
	 */
	stackableInd:boolean = undefined;
	/**
	 * This indicator is set to true (Y) if the handling unit is to be archived. When the record is retrieved from the archives, it is reset to false (N).
	 */
	archiveInd:boolean = undefined;
	/**
	 * The type of handling unit.  Added 10/20/2020  LISG-8170
	 * 
	 * Valid Values:
	 * MOTOR
	 * LOOSE
	 */
	typeCd:HandlingUnitTypeCd = undefined;
	/**
	 * Identifies the type of movement of the child PRO.   Added 12/10/2020  LISG-9067
	 * 
	 * Valid Values:
	 * MISSING - The child PRO is marked as short. When found, it can switch to normal or astray.
	 * NORMAL - The child PRO is with the rest of the child PROs of the parent.
	 * ASTRAY - The child PRO that was once missing has been found but the rest of the shipment has moved elsewhere. It will remain astray until it has caught up with the rest of the shipment, or until it is delivered.
	 */
	handlingMovementCd:string = undefined;
	/**
	 * Identifies the source of the dimension recorded.  Added 12/10/2020  LISG-9067
	 * 
	 * Valid Values
	 * ACCURACY
	 * DOCK
	 * PICKUP
	 */
	dimensionTypeCd:string = undefined;
	/**
	 * Indicates if the child PRO has been reweighed or not.  Added 06/28/2021 LISG-13269
	 * 
	 * Valid Values
	 * Y -Child PRO has been reweighed
	 * N - Child PRO has not been reweighed (default)
	 */
	reweighInd:boolean = undefined;
	/**
	 * This is the volume of the handling unit expressed as a percentage of a full pup (28 foot long trailer).Added 10/19/2021  LISG-15366
	 */
	pupVolumePercentage:number = undefined;
	/**
	 * Indicates if the freight has been poorly packaged or not by the Shipper.  Added 05/04/2022  LISG-16763
	 * 
	 * Valid Values:
	 * N - freight is NOT packaged poorly (default)
	 * Y - freight IS packaged poorly
	 */
	poorlyPackagedInd:boolean = undefined;
	/**
	 * Indicates that the handling unit has been split from the parent and has been authorized to move on its own Added 05/16/2022 LISG-16851
	 */
	splitInd:boolean = undefined;
	/**
	 * PLT PROs crossing border that happened to be split requires a MOVR PRO when some of the handling units have crossed border already. This identifies the MOVR PRO associated to the parent PRO which will move this handling unit across border.  Added 06/14/2022 LISG-17035
	 */
	moverSuffix:string = undefined;
	/**
	 * Denormalized field to store MOVR PRO that is associated to the parent PRO which will move this handling unit across the border.  Added 06/16/2022 LISG-17035
	 */
	moverProNbr:string = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * 
	 */
	positionOnTrailerCd:string = undefined;
	/**
	 * The current location of the handling unit.
	 */
	currentLocation:string = undefined;
	/**
	 * The scheduled ETA timestamp (string format) for the handling unit.
	 */
	scheduledEta:string = undefined;
	/**
	 * 
	 */
	handlingUnitMovement:HandlingUnitMovement[] = undefined;
}
/**
 * Holds a snapshot of HandlingUnit. Contains the details at the time on an event. It also provides the previous values if an update occured
 * null
 */
export class HandlingUnitSnapshot {
	/**
	 * The action that created this snapshot. Add, Update or Delete
	 */
	eventActionCd:ActionCd = undefined;
	/**
	 * The previous snapshot, before this record was updated.
	 */
	before:HandlingUnit = undefined;
	/**
	 * The current snapshot.
	 */
	after:HandlingUnit = undefined;
}
/**
 * 
 */
export class HandlingUnitMovement {
	/**
	 * Expanded Name :
	 * 	
	 * Instance identifierDesc : System generated unique identifier (as per 
	 * CBD standard).
	 */
	shipmentInstanceId:number = undefined;
	/**
	 * A system assigned
	 * 	number used as part of how to uniquely identify each handling unit of a
	 * 	shipment.
	 */
	sequenceNbr:number = undefined;
	/**
	 * A system
	 * 	assigned number to uniquely identify each movement leg for a movement
	 * 	event of a handling unit
	 */
	movementSequenceNbr:number = undefined;
	/**
	 * Identifies the type of movement or type of action taken on a handling unit. Updated description 06/17/2022 LISG-17035
	 * 
	 * Valid Movement Values :
	 * CLOSE
	 * DELIVER
	 * LOAD
	 * PICKUP
	 * STAGE
	 * UNLOAD
	 * 
	 * Valid Action Values:
	 * DOP_EXCP
	 * MOVR
	 * SCAN
	 * SPLIT
	 * TRANSFER
	 * UNSPLIT
	 */
	movementTypeCd:HandlingUnitMovementTypeCd = undefined;
	/**
	 * SIC 
	 * where the movement event for the unit happened and reported.
	 */
	movementReportingSicCd:string = undefined;
	/**
	 * Date and 
	 * time, when the movement event occurred.
	 */
	movementDateTime:Date = undefined;
	/**
	 * Denormed
	 * 	trailer instance ID. A system generated unique identifier 
	 * assigned to a piece of equipment (mostly trailers) used to carry on 
	 * movements of handling units. This applies to all type of movement except
	 * 	for 
	 * staged on dock.
	 */
	trailerInstanceId:number = undefined;
	/**
	 * Unique ID of a
	 * 	Dock Location. This is the dock location where a shipment is staged on
	 * 	dock.
	 */
	dockInstanceId:number = undefined;
	/**
	 * Expanded
	 * 	Name : Archive Control CodeDesc : Identifies a row of this 
	 * table as archived or retrieved from archive.  The Princeton Archive 
	 * tool sets the value upon archive or retrieval of data for selected 
	 * PROs.FOR USE BY PRINCETON ARCHIVE/RETRIEVE PROCESSI
	 */
	archiveControlCd:string = undefined;
	/**
	 * Expanded Name :
	 * 	Exception Type Code
	 * Desc : Describes whether the pieces count is for an overage or a
	 * 	shortage or damaged or refused hanlding unit.
	 * Valid Values : 
	 * 1 - Over
	 * 2 - Short
	 * 3 - Damaged
	 * 4 - Refused
	 * 5 - Undeliverable
	 * 6 - Exception Noted
	 * 7 - Daamaged But Accepted
	 * 8 - Over But Accepted
	 */
	exceptionTypeCd:string = undefined;
	/**
	 * Expanded Name :
	 * 	Refused Reason Code
	 * Desc : The reason code  for refused handling unit. Applicable only for
	 * 	refusal count. 
	 * Valid Values :
	 * 1 - Unauthorized return
	 * 2 - Consignee won't accept freight collect
	 * 3 - Arrived too late  
	 * 4 - Order cancelled
	 * 5 - Did not Order
	 * 6 - Wrong Order
	 * 7 - Customer Wanted Earlier
	 * 8 - Insufficient Delivery Time
	 * 9 - Manufacturer's Defect
	 * A - Arrived too early
	 * B - Duplicate Order
	 * C - Sent to wrong location
	 * U - <used by BI team>
	 * Z - Other (Remarks required)
	 */
	refusedReasonCd:string = undefined;
	/**
	 * Expanded Name
	 * 	: Undelivarable Reason Code
	 * Desc : The reason code explaining why the handling unit could not be
	 * 	delivered. Applicable only for undelivered handling unit. 
	 * Valid Values :
	 * 1 - Construction site, not deliverable within 2 day
	 * 2 - No response to arrival notification after 2 working days
	 * 3 - Consignee involved in labor dispute
	 * 4 - Consignee cannot be located at address or phone number shown
	 * 5 - Consignee has moved 
	 * 6 - Credit Hold
	 * 7 - Suspended at Customer Request
	 * 8 - Insufficient Delivery Time
	 * Z - Other (Remarks required)
	 */
	undeliveredReasonCd:string = undefined;
	/**
	 * Expanded Name
	 * 	: Damaged category
	 * Desc : A code further describing the nature of damage.
	 * Source : SMART (Dock Automation)
	 * Valid Values :
	 */
	damagedCategoryCd:string = undefined;
	/**
	 * Expanded Name:
	 * 	Remarks text
	 * Desc : Any remarks noted during the exception or as a response to a
	 * 	recorded exception. Sometimes describes the reason for the exception
	 * 	when it is not one of the standard reason codes.
	 */
	remarks:string = undefined;
	/**
	 * Indicates that scanning of the child PRO was bypassed.   Added 12/10/2020  LISG-9067
	 */
	bypassScanInd:boolean = undefined;
	/**
	 * The reason why scanning was bypassed.  Added 12/10/2020 LISG-9067
	 */
	bypassScanReason:string = undefined;
	/**
	 * Timestamp of when the child PRO was scanned.  Added 1210/2020  LISG-9067
	 */
	scanDateTime:Date = undefined;
	/**
	 * The employee id who authorized the split movement.  Added 12/10/2020  LISG-9067
	 */
	splitAuthorizeBy:string = undefined;
	/**
	 * The timestamp of when the split movement was authorized.  Added 12/10/2020  LISG-9067
	 */
	splitAuthorizeDateTime:Date = undefined;
	/**
	 * Denormed for performance reasons - the door where the trailer is that reported the handling unit movement.  Added 11/17/2022  LISG-37900
	 */
	doorNbr:string = undefined;
	/**
	 * Denormed for performance reasons - the shift at the time the handling unit movement occurred.  Added 11/17/2022  LISG-37900
	 */
	shiftCd:string = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * Holds a snapshot of HandlingUnitMovement. Contains the details at the time on an event. It also provides the previous values if an update occured
 * null
 */
export class HandlingUnitMovementSnapshot {
	/**
	 * The action that created this snapshot. Add, Update or Delete
	 */
	eventActionCd:ActionCd = undefined;
	/**
	 * The previous snapshot, before this record was updated.
	 */
	before:HandlingUnitMovement = undefined;
	/**
	 * The current snapshot.
	 */
	after:HandlingUnitMovement = undefined;
}
export class HazMat {
	/**
	 * 
	 */
	shipmentInstId:number = undefined;
	/**
	 * 
	 */
	hazmatSequenceNbr:number = undefined;
	/**
	 * 
	 */
	sourceCd:HazmatSourceCd = undefined;
	/**
	 * 
	 */
	hazmatUnna:string = undefined;
	/**
	 * 
	 */
	hazmatClassCd:string = undefined;
	/**
	 * 
	 */
	hazmatWeightLbs:number = undefined;
	/**
	 * 
	 */
	hazmatBulkQuantityCd:string = undefined;
	/**
	 * 
	 */
	hazmatResidueInd:boolean = undefined;
	/**
	 * 
	 */
	zoneCd:string = undefined;
	/**
	 * 
	 */
	packingGroupCd:string = undefined;
	/**
	 * 
	 */
	classLabel:string = undefined;
	/**
	 * 
	 */
	overrideMethodNm:string = undefined;
	/**
	 * 
	 */
	dotSpecialPermit:string = undefined;
	/**
	 * 
	 */
	limitedQuantityInd:boolean = undefined;
	/**
	 * 
	 */
	reportedQuantityInd:boolean = undefined;
	/**
	 * 
	 */
	explosiveInd:boolean = undefined;
	/**
	 * 
	 */
	netExplosiveMassNbr:number = undefined;
	/**
	 * 
	 */
	netExplosiveMassUnitOfMeasure:NetExplosiveMassUomCd = undefined;
	/**
	 * 
	 */
	marinePollutantInd:boolean = undefined;
	/**
	 * 
	 */
	marinePollutantChemical:string = undefined;
	/**
	 * 
	 */
	radioactiveInd:boolean = undefined;
	/**
	 * 
	 */
	radioactiveChemicalName:string = undefined;
	/**
	 * 
	 */
	radioactivityNbr:number = undefined;
	/**
	 * 
	 */
	radioactivityUnitOfMeasure:RadioactivityUomCd = undefined;
	/**
	 * 
	 */
	transportIndex:number = undefined;
	/**
	 * 
	 */
	fissileExceptedInd:boolean = undefined;
	/**
	 * 
	 */
	emergencyContactName:string = undefined;
	/**
	 * 
	 */
	emergencyContactPhoneNbr:string = undefined;
	/**
	 * 
	 */
	technicalName:string = undefined;
	/**
	 * 
	 */
	unnaInstId:number = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class LnhDimension {
	/**
	 * 
	 */
	shipmentInstId:number = undefined;
	/**
	 * 
	 */
	dimensionsSequenceNbr:number = undefined;
	/**
	 * 
	 */
	piecesCount:number = undefined;
	/**
	 * 
	 */
	lengthNbr:number = undefined;
	/**
	 * 
	 */
	widthNbr:number = undefined;
	/**
	 * 
	 */
	heightNbr:number = undefined;
	/**
	 * 
	 */
	stackableInd:boolean = undefined;
	/**
	 * 
	 */
	capturedByUserid:string = undefined;
	/**
	 * 
	 */
	capturedByDateTime:Date = undefined;
	/**
	 * 
	 */
	capturedByCd:string = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * Holds a snapshot of LnhDimension. Contains the details at the time on an event. It also provides the previous values if an update occured
 * null
 */
export class LnhDimensionSnapshot {
	/**
	 * The action that created this snapshot. Add, Update or Delete
	 */
	eventActionCd:ActionCd = undefined;
	/**
	 * The previous snapshot, before this record was updated.
	 */
	before:LnhDimension = undefined;
	/**
	 * The current snapshot.
	 */
	after:LnhDimension = undefined;
}
export class MiscLineItem {
	shipmentInstId:number = undefined;
	/**
	 * Expanded Name : Line type code
	 * 
	 * Desc : Indicates the type of  line item. 
	 * 
	 * Source : MBF Variable Segment - MBWEIGHT
	 * 
	 * Valid Values : 
	 * 
	 * 1 - As weight 
	 * 2 - Deficit weight 
	 * 3 - COD Amount
	 * 4 - Discount line (Reduced charge)
	 * 5 - Part PPD Line
	 * 6 - Part Collect Line
	 * 7- Cash Prepaid Line
	 * 8 - Cash Collected line
	 * 
	 * Note: As weight - Often the commodities are rated at a higher weight bracket than their net weight,  to get lower freight charges. In these cases the 'as weight'  is the weight used in calculating the freight charges. Most of the time this is sytem generated.
	 */
	lineTypeCd:MiscLineItemCd = undefined;
	/**
	 * Expanded Name : Tariff rate
	 * 
	 * Desc : This is the rate used to calculate the  charges for the line item. Not all line items have a charge associated with them. The rate when present is multiplied by the unit of measure  to calculate the total charges. 
	 * 
	 * Source : MBF Variable Segment -  MBCRATE, MBRATES
	 */
	tariffsRate:number = undefined;
	/**
	 * Expanded Name : Unit of Measure
	 * 
	 * Desc : A code to identify the units in terms of which the quantity is measured for calculating charges. For eg. weight (in pounds) is the unit of measure for the AS WEIGHT and  DEFICIT WEIGHT line items. Not all line items have an UOM. 
	 * 
	 * Valid Values :
	 * space - Where UOM is n/a to the Charge. 
	 * 1 - Weight (in pounds)
	 * 2 - Yardage (for carpets)
	 * 3 - Count
	 */
	unitOfMeasure:string = undefined;
	/**
	 * Expanded Name : Quantity
	 * 
	 * Desc : Quantity (measured in terms of UOM) to be used in calculating charges
	 * 
	 * It represents As Weight on an AS WEIGHT line. Often the commodities are rated at a higher weight bracket than their net weight,  to get lower freight charges. In these cases the 'as weight'  is the weight used in calculating the freight charges. Most of the time this is sytem generated. 
	 * 
	 * For some Charge lines where it does not make sense to have a QTY, such as a COD, zeros may be formatted; UOM must be a space in this case.
	 * 
	 * Source: MBF Variable Segment - MBWEIGHT
	 */
	quantity:number = undefined;
	/**
	 * Expanded Name :  Charge Amount
	 * 
	 * Desc : The dollar amount of the charge associated with the line item.
	 */
	amount:number = undefined;
	/**
	 * Expanded Name: Percent Prepaid
	 * 
	 * Desc: Percenatge of the charge amount for the accessorial is to be paid by the shipper. The rest of it is paid by the consignee.
	 */
	prepaidPercentage:number = undefined;
	/**
	 * Expanded Name : Minimum charge indicator
	 * 
	 * Desc : Indicates if the charges associated with the line are the minimum charges for the line item. 
	 * 
	 * Source : MBF: root: MBMINCHG (x 10  of MBFLAG7)
	 * 
	 * Valid Values :  Y/N
	 */
	minimumChargeInd:boolean = undefined;
	/**
	 * Expanded Name :  Description
	 * 
	 * Desc : A textual description for the line item.
	 * 
	 * Source : MBF  Variable Segment :
	 */
	description:string = undefined;
	/**
	 * Expanded Name:  Sequence Number
	 * 
	 * Desc : Sequential number assigned to uniquely identify  each miscelleneous charge for a Shipment. This entity stores all the differnt types of charge lines that appear on the invoice for the shipment. Not all of these lines contribute to the CTS revenue.Some examples are COD, As Weight, Deficit Weight lines etc. 
	 * 
	 * Source : Generated
	 * 
	 * Design Considerations :
	 */
	sequenceNbr:string = undefined;
	archiveControlCd:string = undefined;
	paymentMethodCd:MiscLineItemPaymentMethodCd = undefined;
	checkNbr:string = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 */
	auditInfo:AuditInfo = undefined;
	chrgToCd:LineItemChargeToCd = undefined;
}
/**
 * 
 */
export class Movement {
	/**
	 * 
	 */
	shipmentInstId:number = undefined;
	/**
	 * 
	 */
	sequenceNbr:number = undefined;
	/**
	 * 
	 */
	typeCd:string = undefined;
	/**
	 * 
	 */
	movementReportingSicCd:string = undefined;
	/**
	 * 
	 */
	currentSicCd:string = undefined;
	/**
	 * 
	 */
	movementDateTime:Date = undefined;
	/**
	 * 
	 */
	trailerInstId:number = undefined;
	/**
	 * 
	 */
	trailerLoadedSequenceNbr:number = undefined;
	/**
	 * 
	 */
	trailerIdPrefix:string = undefined;
	/**
	 * 
	 */
	trailerIdSuffixNbr:number = undefined;
	/**
	 * 
	 */
	rshpCreditedInd:boolean = undefined;
	/**
	 * 
	 */
	scacCd:string = undefined;
	/**
	 * 
	 */
	deliveryQualifierCd:string = undefined;
	/**
	 * 
	 */
	obcPickupDlvRouteCd:string = undefined;
	/**
	 * 
	 */
	archiveControlCd:string = undefined;
	/**
	 * 
	 */
	routePrefix:string = undefined;
	/**
	 * 
	 */
	routeSuffix:string = undefined;
	/**
	 * 
	 */
	qlfrReasonCd:string = undefined;
	/**
	 * 
	 */
	customerLocationArrivalDateTime:Date = undefined;
	/**
	 * Unique identifier for the carrier that executed this movement.  Added 04/06/2021  LISG-11313
	 */
	carrierId:number = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * Expanded Name : Movement status
	 * 
	 * Desc : The status of the latest movement for a Shipment from an operations point-of-view. This is a denormalized atribute from the MOVEMENT entity.
	 * 
	 * Source: MBF
	 * Name: Various
	 * 
	 * Valid Values : 
	 * Spaces - Not applicable
	 * 1 - On dock
	 * 2 - On trailer
	 * 3 - Out for delivery
	 * 4-  Interim Delivery
	 * 5 - Final Delivered
	 * 6 - Not Applicable
	 * 6 - No movements allowed
	 * 7 - Cancelled
	 */
	movementStatusCd:MovementStatusCd = undefined;
	/**
	 * 
	 */
	movementException:MovementException[] = undefined;
}
/**
 * 
 */
export class MovementException {
	/**
	 * 
	 */
	shipmentInstId:number = undefined;
	/**
	 * 
	 */
	movementSequenceNbr:number = undefined;
	/**
	 * 
	 */
	sequenceNbr:number = undefined;
	/**
	 * 
	 */
	typeCd:MovementExceptionTypeCd = undefined;
	/**
	 * 
	 */
	piecesCount:number = undefined;
	/**
	 * 
	 */
	rfsdReasonCd:string = undefined;
	/**
	 * 
	 */
	undlvdReasonCd:string = undefined;
	/**
	 * 
	 */
	dmgdCategoryCd:string = undefined;
	/**
	 * 
	 */
	remarks:string = undefined;
	/**
	 * 
	 */
	archiveControlCd:string = undefined;
	/**
	 * 
	 */
	exceptionSequenceNbr:number = undefined;
	/**
	 * 
	 */
	exceptionReportingSicCd:string = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * The first name of the createdById user.
	 */
	createdByFirstName:string = undefined;
	/**
	 * The last name of the createdById user.
	 */
	createdByLastName:string = undefined;
	/**
	 * 
	 */
	movementExceptionAction:MovementExceptionAction[] = undefined;
}
/**
 * Describes the action that is being taken for a shipment exception as well as the Service Center that is responsible for taking the action and the reason for the action.  Added 01/02/2020
 */
export class MovementExceptionAction {
	/**
	 * Part of unique identifier
	 */
	shipmentInstId:number = undefined;
	/**
	 * Part of unique identifier
	 */
	movementSequenceNbr:number = undefined;
	/**
	 * Part of unique identifier
	 */
	movementExceptionSequenceNbr:number = undefined;
	/**
	 * Uniquely identifies a row
	 */
	sequenceNbr:number = undefined;
	/**
	 * Describes the action that is being taken for a shipment exception as well as the Service Center that is responsible for taking the action and the reason for the action.
	 */
	reasonCd:string = undefined;
	/**
	 * Responsible sic could be determined to be different from what SHM_movement has recorded
	 */
	responsibleSicCd:string = undefined;
	/**
	 * Describes what corrective action XPO has taken to address the exception. This is typically shared with the customer.
	 */
	correctiveAction:string = undefined;
	/**
	 * A unique identifier to correlate multiple Invoices. This is used as a batch# when reprint for Email is requested and they need to be processed as a group.
	 * 	This is a value that API gateway assigns for a call. API then loops through creating multiple invoices with the same value
	 */
	correlationId:string = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Indicates whether the carrier was in a position to control or prevent the exception.  Added 03/23/2020 LISG-4722
	 */
	controlInd:boolean = undefined;
}
/**
 * 
 */
export class Remark {
	/**
	 * 
	 */
	shipmentInstId:number = undefined;
	/**
	 * Expanded Name :  Type code
	 * 
	 * Desc : This code can be used to categorize the remarks.  
	 * 
	 * Valid Values : 
	 * 1 - Adhoc Hazardous Material Remarks
	 * 2 - Shipping Remarks
	 * 3 - Authority Line text Remarks
	 * 4 - Delivery Attachment Remarks
	 * 5 - Operational Freight Handling Remarks
	 * 6 - Final Delivery Date Correction Remarks
	 * 
	 * Source: New/Entered
	 * 
	 * Design Considerations : This can be used for subsetting purposes and for programatically pulling certain remarks to display and/or print.
	 */
	typeCd:ShipmentRemarkTypeCd = undefined;
	/**
	 * Expanded Name: Remark Text
	 * 
	 * Desc: Remark Text
	 */
	remark:string = undefined;
	/**
	 * 
	 */
	archiveControlCd:string = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class Shipment {
	/**
	 * Expanded Name : Instance identifier
	 * 
	 * Desc : System generated unique identifier (as per CBD standard).
	 */
	shipmentInstId:number = undefined;
	/**
	 * Expanded Name : Type code 
	 * 
	 * Desc : Identifies the type of shipment.
	 * 
	 * 
	 * Valid Values:
	 * A = Normal Movement(NORM)
	 * B = Master Shipment(MSTR) 
	 * C = Segment of a Master shipment (MSEG)
	 * D = Revenue Bill for Split segments (PTLT)
	 * E = Partial Shipment Segment (PSEG)
	 * G = Claims overage Reporting Bill (OREP)
	 * H = Genral Claims Business Shipment (GCBZ)
	 * I =  Astray Freight Segment (MOVR)
	 * J = Company Business Shipment (COBZ) 
	 * K = Expedite
	 * 
	 * 
	 * Note:
	 * Overage Reporting Bill (OREP) is entered into the system just  to indicate to the claims system there is an overage shipment on dock. 
	 * 
	 * Astray freight(MOVR) is used to move freight to it s correct destination after it accidentally ended up somewhere else and its original PRO number is known.
	 */
	billClassCd:BillClassCd = undefined;
	/**
	 * Expanded Name : PRO number (in text format) 
	 * 
	 * Desc : This number identifies a shipment. This is the identifier given to the customer as a reference.
	 * 
	 * The numbers are printed on labels for each service center.  Labels are put on the Bill-Of-Lading and on the individual pieces of freight for identification.
	 * 
	 * Internal PRO number format is Oppp-sssss-c where 'ppp' is the pro-prefix, 'ssss' is the pro-suffix and 'c' is the check digit (modulus 7). It is usually displayed in the format 'ppp-sssss-c'
	 * 
	 * An expedited shipment may not always have a real PRO number. It may be given a dummy number in the same format and should not be written out to MBF.
	 * 
	 * MBF: root MBPRO
	 */
	proNbr:string = undefined;
	/**
	 * Expanded Name : Bill Status Code
	 * 
	 * Desc : Current status of the Shipment from billing point of view. 
	 * 
	 * Valid Values:
	 * 1 - Unbilled 
	 * 2 - In FBES Suspense Queue
	 * 3 - Billed
	 * 4 - Rated
	 * 5 - Paid
	 * 6 - Cancelled
	 * 
	 * Source : MBF, E-commerce
	 */
	billStatusCd:BillStatusCd = undefined;
	/**
	 * Expanded Name :  Charge to code
	 * 
	 * Desc : Identifies who is responsible for charges for the Shipment - either the Shipper or the Consignee or the responsibility may be split between BOTH parties. For BOTH bills, the split for each line item will be stored along with the line item in the commodity, ac_svc or the misc_line_item table in future. Currently the total PPD and total COLLECT charges are currently stored as separate line items in the misc_line_item entity.
	 * 
	 * Valid Values : 
	 * 	P    -  Prepaid - shipper responsible for all charges
	 * 	C    -  Collect - consignee responsible for all charges
	 * 	B    -  Both - shipper and consignee responsible 
	 * 								-  Valid only for Non-Revenue bills.
	 * 			
	 * Source: MBF : MBPRTPPD, MBPRTCOL
	 */
	chargeToCd:ChargeToCd = undefined;
	/**
	 * Expanded Name : Original terminal SIC
	 * 
	 * Desc : SIC where the Shipment originates.  Usually associated with PRO prefix and can be overriden.
	 * 
	 * Source : MBF: root MBORGIN
	 */
	originTerminalSicCd:string = undefined;
	/**
	 * Expanded Name : Destination terminal SIC
	 * 
	 * Desc : SIC that is scheduled to deliver the Shipment. 
	 * 
	 * Source : MBF: root MBDEST
	 */
	destinationTerminalSicCd:string = undefined;
	/**
	 * Expanded Name : Current SIC
	 * 
	 * Desc : SIC where the Shipment currently is.  
	 * 
	 * This is a denormalization to avoid scanning all movements to find current location.
	 * 
	 * Source : MBF: root MBLOCX
	 */
	currentSicCd:string = undefined;
	/**
	 * Expanded Name : Source Code
	 * 
	 * Desc : Indicates the source or origination of the Pro.  This code is needed for ACOR processing to determine whether to roll back to the initial data.
	 * 
	 * Valid Values:
	 * 	1  - Web        (Pickup Request web page)
	 * 	2  - EDI        (set 204 or set 211)    
	 * 	3  - FTP        (file transfer)
	 * 	4  - FBES    (manual Bill Entry)
	 * 	5  - TCON or LOAD    (i.e. skeleton TCON or non-TCON LOAD)
	 * 	6  - Pickup Request
	 * 
	 * Source : E-commerce
	 */
	sourceCd:ShipmentSourceCd = undefined;
	/**
	 * Expanded Name : Pickup Date                       
	 * 																																																		
	 * Desc : Earliest available date that Shipment can be
	 * picked up from a customer.                        
	 * 																																																		
	 * Source : E-commerce
	 */
	pickupDate:string = undefined;
	/**
	 * Expanded Name : Ready Time                        
	 * 																																																		
	 * Desc : Earliest available time that freight can be
	 * picked up from a customer.                        
	 * 																																																		
	 * Source : E-commerce
	 */
	readyTime:string = undefined;
	/**
	 * Expanded Name : Close Time                      
	 * 																																																
	 * Desc : Latest available time that freight can be
	 * pickup up from a customer.                      
	 * 																																																
	 * Source : E-commerce
	 */
	closeTime:string = undefined;
	/**
	 * Expanded Name : Total pieces count
	 * 
	 * Desc : Total pieces from all commodities in the shipment.
	 * 
	 * This can be derived from the individual commodities, but besides performance, there is a timing issue as well. SMART needs the information when freight is loaded on the trailer (TCON) and it may happen before the bill has been entered into the system. 
	 * 
	 * Source : MBF: root: MBTOTPCS
	 */
	totalPiecesCount:number = undefined;
	/**
	 * Exapnded Name : Motorized piece count
	 * 
	 * Desc : Identifies the number of moves or lifts made with a forklift or a pallet jack to move freight without manual intervention by the driver. This count may be equal to or less than the number of pieces in the shipment.  This is used by the costing system.  
	 * 
	 * Source : MBF root -  MBMTRPCS
	 */
	motorizedPiecesCount:number = undefined;
	/**
	 * Expanded Name : Total weight (in pounds)
	 * 
	 * Desc : Total weight of all the freight being moved as a unit.  If this is a  Mother  bill then this is the total of all of the  Daughter  bills. 
	 * 
	 * Any rating weight allowances or variations (AW, DW, RW, NW) are not included.
	 * 
	 * This can be derived from the individual commodities, but besides performance, there is a timing issue as well. SMART needs the information when freight is loaded on the trailer (TCON) and it may happen before the bill has been entered into the system. 
	 * 
	 * Source : MBF: root: MBTOTWT
	 */
	totalWeightLbs:number = undefined;
	/**
	 * Expanded Name : Default Tariff
	 * 
	 * Desc : This identifies the default qualifying tariff for the shipment. If the rating process does not identify any other tariffs for the customer from rate aids, this is used as the rating tariff. 
	 * 	
	 * Source : MBF root - MBTARIFF
	 */
	defaultTariffsId:string = undefined;
	/**
	 * Expanded Name : Total charges
	 * 
	 * Desc : Includes all charges that contribute to the CTS revenue minus discount.  Includes advance+beyond carrier charges and COD amount.
	 * 
	 * Source : MBF: root: MBTOTAL
	 */
	totalChargeAmount:number = undefined;
	/**
	 * Expanded Name : Total charges in US dollars
	 * 
	 * Desc : Includes all charges that contribute to the CTS revenue minus discount in US dollars.  Includes advance+beyond carrier charges and COD amount.
	 * 
	 * Source : MBF: root: MBTOTAL
	 * 
	 * Design Notes: This field was added as a denorm to keep the total charges in USD. Normally this field is same as the tot charges field. However this field is differnt when the rating currncy is not USD.
	 */
	totalUsdAmount:number = undefined;
	/**
	 * Expanded Name: Split Indicator
	 * 
	 * Desc: Indicates whether the shipment has been split or not. 
	 * 
	 * Valid values: Y/N
	 * 
	 * Source: MBF MBCLAS2 -  This actually replaces the BILL CLASS 2 field in the MBF.
	 */
	splitInd:boolean = undefined;
	/**
	 * Expanded Name : Hazardous Materials indicator
	 * 	
	 * Desc : Indicates whether or not a shipment includes  any commodities with hazardous materials. It is a denormalized attribute for performance puposes. 
	 * 
	 * Valid Values : Y/N
	 */
	hazmatInd:boolean = undefined;
	/**
	 * Expanded Name : Freezable Indicator 
	 * 
	 * Desc : Indicates whether shipment requires special handling to prevent freezing during harsh weather. 
	 * 
	 * Ideally, it should be specified at the commodity level, but has been de-normalized for Shipment, since that is level at which it is currently captured
	 * 
	 * Source: MBF root - MBFZB (x 08  of MBTRAFIC)
	 * 
	 * Valid Values : Y/N
	 * 
	 * Y - Can be damaged by freezing
	 */
	freezableInd:boolean = undefined;
	/**
	 * Expanded Name : Signature service indicator
	 * 
	 * Desc : Indicates if anyone handling the freight is required to sign on a signature sheet. 
	 * 
	 * Source : MBF: root: MBSGSRVC (x 04  of MBFLAG9)
	 * 
	 * Valid Values : Y/N
	 */
	signatureServiceInd:boolean = undefined;
	/**
	 * Expanded Name : Revenue Bill Indicator
	 * 
	 * Desc : Indicates whether the shipment will generate revenue for Con-way or not.
	 * 
	 * Valid Values : 
	 * 
	 * Y - generates revenue
	 * N - does not generate revenue
	 */
	revenueBillInd:boolean = undefined;
	/**
	 * Expanded Name : Manual rating indicator 
	 * 
	 * Desc : Specifies whether the shipment  in question needs to be manually rated or not. If required, it may be changed to a code indicating the reason as well for manually rating it. 
	 * 
	 * It is same as special instructions field on the FBES screen.
	 * 
	 * ==NOTE==
	 * When this indicator is set, it means the rates and charges must be manully reviewed before the bill is marked as RATED. Howerver the auto rating system is allowed to put rates and charges on the bill prior to putting it in a manual entry or manual audit queue.
	 * 
	 * This is different from the indicator AUTO_RATEABLE_IND which indicates whether the auto rating system is even allowed to put rates and charges on the bill.
	 * 
	 * Source : MBF: root: MBSCHDI (x 80  of MBFLAGB)
	 * 
	 * Valid Values : Y/N
	 */
	manualRatingRequiredInd:boolean = undefined;
	/**
	 * Expanded Name : Audit Required Indicator
	 * 																		
	 * Desc : Used to determine if the pro requires auditing after rating. 
	 * 																	
	 * Valid Values :
	 * Y - Pro does require auditing
	 * N - Pro does not require auditing
	 * 					
	 * Source : MBF:  Root: MBFBAUDT (X 01  of MBFLAGI)
	 */
	auditInd:boolean = undefined;
	/**
	 * Expanded Name : Cash indicator
	 * 
	 * Desc : This indicates if cash was collected at the tim eof pick up or should be collected at the time of delivery.
	 * 
	 * This information is also printed on the delivery set to inform the driver to collect cash.
	 * 
	 * Source : MBF: root: MBCASH (x 10  of MBFLAG2)
	 * 
	 * Valid Values : Y/N
	 */
	cashInd:boolean = undefined;
	/**
	 * Expanded Name : Cash collected code                    
	 * 																																																							
	 * Desc : A code indicating whether any cash was          
	 * collected at the time of pick up or delivery or not. Cash may be collected  regardless of whether it was required or not and if  so, the invoice may need to be stopped.                
	 * 																																																							
	 * It has been modelled as a code instead of an indicator 
	 * since it needs to be initialized to a value other than 
	 * NO.                                                    
	 * 																																																							
	 * Source : New                                           
	 * 																																																							
	 * Valid Values :                                         
	 * blank - Initial value                                  
	 * Y - Yes, cash was collected                            
	 * N - No cash collected on delivery
	 */
	cashCollectInd:boolean = undefined;
	/**
	 * Expanded Name : Government Bill of Lading indicator
	 * 
	 * Desc : Indicates whether this is a government shipment or not.
	 * 
	 * Source : MBF: root: MBGBLT (x 01  of MBTRAFIC)
	 * 
	 * Valid Values : Y/N
	 */
	govtBolTrafficInd:boolean = undefined;
	/**
	 * Expanded Name : Guaranteed Indicator
	 * 
	 * Desc : Indicates if the shipment is under the Con-Way Guaranteed Service Program.
	 * 
	 * Values : Y/N
	 * 
	 * Source : MBF, E-commerce
	 */
	guaranteedInd:boolean = undefined;
	/**
	 * Expanded Name : Guaranteed Coupon Indicator
	 * 
	 * Desc : Indicates that a Coupon has been used to allow the shipment to participate in the Con-Way Guaranteed Service Program for free.
	 * 
	 * Values : Y/N
	 * 
	 * Source : BASE
	 */
	guaranteedCouponInd:boolean = undefined;
	/**
	 * Expanded Name : Purge blocked indicator
	 * 
	 * Desc : Indicates that the details cannot be purged. Usually set when there are inconsistencies for Master / MSEG or Part lot/PSEG  shipments. 
	 * 
	 * Valid values : Y/N
	 * 
	 * Source : MBF: root: MBPUBLK (x 10  of MBFLAG3)
	 */
	purgeBlockInd:boolean = undefined;
	/**
	 * Expanded Name: Last MOVR PRO Number 
	 * 
	 * Desc: The Pro number for the last MOVR PRO billed for this shipment.
	 */
	lastMoverPro:string = undefined;
	/**
	 * Expanded Name: Archive Indicator
	 * 
	 * Desc: Indicates whether the record for the PRO is an active one or one retrieved from the archive.
	 */
	archiveInd:boolean = undefined;
	/**
	 * Expanded Name: COD Indicator
	 * 
	 * Desc: Indictaes whether it is a COD shipment or not. It is a denormalized attribute for performance puposes. The actual COD information is in the MISC_LINE_ITEM entity.
	 */
	codInd:boolean = undefined;
	/**
	 * Expanded Name: Discount Code 
	 * 
	 * Desc: This is the discount code that was applied to the shipment when it was rated. 
	 * 
	 * Source: MBF - MBRCCODE
	 */
	discountCd:string = undefined;
	/**
	 * Expanded Name :Movement Reporting SIC Code
	 * 
	 * Desc : SIC where the last movement event for the shipment  happened and reported.  This is a denormalized attribute from the MOVEMENT entity for performance purposes.
	 * 
	 * Source : MBF: root MBMOVE
	 */
	lastMoveRptgSicCd:string = undefined;
	/**
	 * Expanded Name : Movement Timestamp
	 * 
	 * Desc : Date and time, when the last movement for this shipment  occurred. This is a denormalized attribute from the MOVEMENT entity. 
	 * 
	 * Source: MBF - MBMVTM
	 */
	lastMovementDateTime:Date = undefined;
	/**
	 * Expanded Name : Movement status
	 * 
	 * Desc : The status of the latest movement for a Shipment from an operations point-of-view. This is a denormalized atribute from the MOVEMENT entity.
	 * 
	 * Source: MBF
	 * Name: Various
	 * 
	 * Valid Values : 
	 * Spaces - Not applicable
	 * 1 - On dock
	 * 2 - On trailer
	 * 3 - Out for delivery
	 * 4-  Interim Delivery
	 * 5 - Final Delivered
	 * 6 - Not Applicable
	 * 6 - No movements allowed
	 * 7 - Cancelled
	 */
	movementStatusCd:MovementStatusCd = undefined;
	/**
	 * Expanded Name : Delivery qualifier
	 * 
	 * Desc : Code representing results of a specific delivery activity. The values are the same as what used to be  Event that stopped service).
	 * 
	 * This has been denormalized from Movement for performance reasons and should always be kept in sync. 
	 * 
	 * Source : MBF: root: MBD1FLG / MBD2FLG
	 * 
	 * Valid Values :
	 * A= Over Shipment
	 * B=Trapped shipment/MBD1TRAP
	 * C=Hold for customs/MBD1CSTM
	 * D=Undelivered/MBD1UNDL
	 * E=Hold for appointment/MBD1APPT
	 * F=On spotted trailer/MBD1SPOT
	 * G=Cartage
	 * H=Attempted delivery/MBD1ATMP
	 * J=All short
	 * K=Partial short/MBD1PSRT
	 * L=Refused delivery/MBD1RFSD
	 * M=Damaged shipment/MBD1RDMG
	 * N=Suspense/MBD1SUSP     - Not Used
	 * O=Wait/MBD1WAIT              - Not Used
	 * P=Purge Blocked/MBD2PBLK
	 * R=Return Status/MBD2RTRN
	 * T=Transfer/MBD2TSFR
	 * Z=Final delivery/MBD1FINL
	 */
	deliveryQualifierCd:DeliveryQualifierCd = undefined;
	/**
	 * Expanded Name : Rating Currency Code
	 * 
	 * Desc: The rates and charges for the shipment were calculated using this currency
	 * 
	 * Valid values: 
	 * 	
	 * USD - US Dollars
	 * CDN - Canadian Dollars
	 */
	ratingCurrencyCd:RatingCurrencyCd = undefined;
	/**
	 * Expanded Name : Invoicing Currency Code
	 * 
	 * Desc:  PLEASE DO NOT USE THIS ATTRIBUTE. THIS ATTRIBUTE WILL BE DROPPED IN NEAR FUTURE.
	 * 
	 * The total charges for the shipment should be displayed using this currency
	 * 
	 * Valid values: 
	 * 	
	 * USD - US Dollars
	 * CDN - Canadian Dollars
	 */
	invoicingCurrencyCd:InvoiceCurrencyCd = undefined;
	/**
	 * Expanded Name: Currency Converstion Factor
	 * 
	 * Desc: PLEASE DO NOT USE THIS ATTRIBUTE. THIS ATTRIBUTE WILL BE DROPPED IN NEAR FUTURE.
	 * 
	 * Provides the currency conversion factor on the day of shipment pick up between the rating currency and invoicing currency, if they are different.
	 */
	currencyConversionFctr:number = undefined;
	/**
	 * Expanded Name : Required Delivery Date
	 * 
	 * Desc : Date the shipment is required to be delivered
	 * 
	 * Source : E-commerce
	 */
	requiredDeliveryDate:string = undefined;
	/**
	 * Expanded Name : Delivery Time Start (if any)
	 * 
	 * Desc : The start time of the window specified by the customer for delivering the shipment.
	 * 
	 * Source : E-commerce
	 */
	startDeliveryTime:string = undefined;
	/**
	 * Expanded Name : Delivery Time End (if any)
	 * 
	 * Desc : The end time of the window specified by the customer for delivering the shipment.
	 * 
	 * Source : E-commerce
	 */
	endDeliveryTime:string = undefined;
	/**
	 * Expanded Name : International manifest from (port) code
	 * 
	 * Desc : Generated by rating. Identifies port of entry code through which freight will enter the US from another country. This may be associated with the destination city or the customer s broker.
	 * 
	 * Source : MBF: root: MBFROMPT
	 */
	fromPortCd:string = undefined;
	/**
	 * Expanded Name : International  manifest to (port) code
	 * 
	 * Desc : Generated by rating: Identifies the port of entry through which freight will enter another country.  This is either associated with the destination or with the customer s broker.
	 * 
	 * Source : MBF: root: MBTOPORT
	 */
	toPortCd:string = undefined;
	/**
	 * Expanded Name : OBC pickup delivery route
	 * 
	 * Desc : Pickup delivery route code from CAD used for sorting to print delivery sets in this order.
	 * 
	 * Source : MBF: root: MBPDRTE
	 */
	obcPickupDlvRouteCd:string = undefined;
	/**
	 * Expanded Name : Customer Profile Instance Id
	 * 
	 * Desc : A unique, randomly-generated number that identifies a registered customer in the Con-Way Customer Profile Component.
	 * 
	 * Source : Customer Profile Component
	 */
	custProfitabilityInstId:number = undefined;
	/**
	 * Expanded Name :  Rating tariff
	 * 
	 * Desc : This identifies the actual tariff used to rate the shipment. 
	 * 
	 * Source : MBF root - MBRATTAR
	 */
	ratingTariffsId:string = undefined;
	/**
	 * Expanded Name : Rate auditor initials
	 * 
	 * Desc : Initials of the rating auditor who last audited the shipment for rates. 
	 * 
	 * Source : MBF: root: MBRTADID
	 */
	rateAuditorInitials:string = undefined;
	/**
	 * Expanded Name : Rate or rate audit queue name
	 * 
	 * Desc : The name of the rating (or the rate audit) queue used when the shipment was last rated / audited. 
	 * 
	 * Source : MBF: root: MBRATEQ
	 */
	rateOrRateAudtqNm:string = undefined;
	/**
	 * 
	 */
	heaviestCommoditySequenceNo:string = undefined;
	/**
	 * Expanded name:  Service type code.
	 * 
	 * Description:  Defines the specific type of service provided for a shipment.
	 * 
	 * Values:  1 - Normal LTL, 2 - Con-Way Deferred Express.
	 */
	serviceTypeCd:string = undefined;
	/**
	 * Expanded name: Estimated Delivery Date
	 * 
	 * Description: Date that the shipment should be delivered based on standard lane days.
	 */
	estimatedDeliveryDate:string = undefined;
	/**
	 * Expanded name: Freight Bill Delivery Set Print Count
	 * 
	 * Description:
	 * The number of times that a FBDS has been printed for this shipment.
	 */
	fbdsPrintCount:string = undefined;
	/**
	 * Expanded Name : Archive Control Code
	 * 
	 * Desc : Identifies a row of this table as archived or retrieved from archive.  The Princeton Archive tool sets the value upon archive or retrieval of data for selected PROs.
	 * 
	 * FOR USE BY PRINCETON ARCHIVE/RETRIEVE PROCESSING ONLY!
	 * 
	 * Valid Values:
	 * A - Row is flagged for Archive processing
	 * R - Row has been retrieved from archive
	 * blank - row is associated with a currently active PRO (default)
	 */
	archiveControlCd:string = undefined;
	/**
	 * Expanded name: Delivery Information Required Code
	 * 
	 * Desc: This code indicates that there is a requirement to capture delivery information for this shipment because of customer special requirements.  This is usually an EDI customer requirement that must be met.  The name of the person that signed for delivery and the timestamp are also captured in this record.
	 * 
	 * Values: 
	 * R - delivery info is required 
	 * C - delivery informaiton has been captured  and exists with this record
	 * blank - No delivery information required for this shipment
	 */
	deliveryInformationRqdCd:DeliveryInfoRequiredCd = undefined;
	/**
	 * Expanded name: Delivery Signature Name
	 * 
	 * Desc: This is the name of the person that signed for the delivery of the freight.  This name is required by some customers and is passed along to them via EDI.  This name is captured if the DLVR_UNFO_RQD_CD is set.
	 * 
	 * Since a shipment can be delivered in parts, (part short, etc) there can be more than one signature.  This field will hold the name of the last delivery movement.  The previous signatures can be retrieved from shipment history records.
	 */
	deliverySignatureNm:string = undefined;
	/**
	 * Expanded name: Delivery Signature TimeStamp
	 * 
	 * Desc: This is the date and time recorded when the person named in DLVR_SIG_NM_TXT signed for the shipment.
	 */
	deliverySignatureDateTime:Date = undefined;
	/**
	 * Expanded Name : Standard Transit Days (in number of days)
	 * 
	 * Desc : The standard number of days it should take to move a shipment from origin to destination, as defined in the OSS_SVC_STDS_A/B tables. 
	 * 
	 * Source : OSS_SVC_STDS_A/B - TRNST_DAYS
	 */
	standardTransitDays:string = undefined;
	/**
	 * Expanded Name : Actual Transit Days (in number of days)
	 * 
	 * Desc : The number of days it actually takes to move a shipment from shipper to consignee.
	 * 
	 * Source : calculated by subtracting PKUP_DT from the date portion of the (LST_)MVMT_TMST of the qualifying delivery event / movement, adjusted appropriately for non-work days occuring between the two dates.  There are a number of interim delivery qualifiers (DLVRY_QALFR_CD) that can cause the calculation  (SPOT, PSRT?, RDMG?, RFSD?, FINL).
	 */
	actualTransitDays:string = undefined;
	/**
	 * Expanded Name : Transit Movement Sequence Number
	 * 
	 * Desc : The sequence number identifying the movement (qualifying delivery event) for the calculation of ACTL_TRNST_DAYS.
	 * NOTE - VALUE CAN BE ZERO FOR PARTLOT (PTLT) PROS.
	 * 
	 * Source : SHM_MOVEMENT - MVMT_SEQ_NBR
	 */
	transitMovementSequenceNbr:string = undefined;
	/**
	 * Expanded Name : Warranty Indicator
	 * 
	 * Desc : Indicates whether the shipment is eligible for the money-back guarantee if moving between direct points under Tariff CNWY-599.
	 * 
	 * Valid Values : 
	 * 
	 * Y - eligible for warranty
	 * N - not eligible for warranty - default value
	 */
	warrantyInd:boolean = undefined;
	/**
	 * Expanded Name : Warranty Status Code
	 * 
	 * Desc : Identifies the warranty status of a shipment
	 * 
	 * For example, one value of this code would indicate that a warranty  claim has been filed  by the debtor if a shipment has failed service.
	 * 
	 * Valid Values:
	 * 0 - default value; This means either the shipment is not eligible (Warranty flag =  N ) or it is eligible and no one has filed a claim.
	 * 1 - The customer has filed a claim and it has not been audited.
	 * 2 - The customer has filed a claim and it was approved.
	 * 3 - The customer has filed a claim and it was denied.
	 */
	warrantyStatusCd:WarrantyStatusCd = undefined;
	/**
	 * Expanded name: Notification Code
	 * 
	 * Desc: Indicates whether or not an notification exists for this shipment.  If it exists, the details will exist in the SHM NOTFICATION table.  The notification is created in the SCO (Smart4) system.
	 * 
	 * Values:
	 * A - Appointment exists
	 * N - Notification exists
	 * O -Appointment Not Required  
	 * R- Reappointment candidate.  PSRT/RDMG Pro  could be reappointment for delivery
	 * 
	 * blank - Neither exist
	 */
	notificationCd:NotificationCd = undefined;
	/**
	 * Expanded Name: Absolute minimum charge indicator
	 * 
	 * Desc: This inidcates if the charge for this shipment is the absolute minimum charge.
	 */
	absoluteMinimumChargeInd:boolean = undefined;
	/**
	 * Expanded Name: Discount Percentage
	 * 
	 * Desc: This is the percentage discount applied to the freigt chrages and discountable accessorial charges of the shipment.
	 */
	discountPercentage:number = undefined;
	/**
	 * Expanded Name: Pricing Agreement Instance Id
	 * 
	 * Desc: This is the instance id of the pricing agreement that was used to rate the shipment.
	 * 
	 * ==NOTE== Link to BASE 5 PRC component
	 */
	priceAgreementId:number = undefined;
	/**
	 * Expanded Name: Rule set Number
	 * 
	 * Desc: This identifies the rule set of the pricing agreement that was used to rate the shipment.
	 * 
	 * ==NOTE== link to BASE 5 PRC component
	 */
	priceRulesetNbr:string = undefined;
	/**
	 * Expanded Name: Route prefix 
	 * 
	 * Desc: Identifies the last Route the shipment was put on.
	 * 
	 * ==NOTE== Link to SMART 4 SCO component
	 */
	routePrefix:string = undefined;
	/**
	 * Expanded Name: Route Suffix
	 * 
	 * Desc: Identifies the last Route the shipment was put on.
	 * 
	 * ==NOTE== Link to SMART 4 SCO component
	 */
	routeSuffix:string = undefined;
	/**
	 * Expanded Name: Route Type Code
	 * 
	 * Desc: Identifies whether the shipment is currently on a  Route (either planning route or dispatched route) Staged (available) for Routing, or is Not on a route.
	 * 
	 * Valid Values
	 * 				- Not Routed
	 * R - Routed
	 * S - Staged
	 */
	routeTypeCd:RouteTypeCd = undefined;
	/**
	 * Expanded Name : Volume in Cubic feet
	 * 
	 * Desc : The volume of the freight; derived from the dimensions captured by driver, or entered by a CSR or dock worker for an SL  (Shipper Load and Count), or from one of a variety of profiles. For details, see the description of CFT_PRFL_MTHD_CD.
	 * 
	 * 06/21/2016 Cathy Doucet modified description as per Ariel/Sunya.
	 */
	totalVolumeCubicFeet:number = undefined;
	/**
	 * Expanded Name: Total Pallet Count
	 * 
	 * Desc: Total Number of Pallets in the Shipment
	 */
	totalPalletsCount:number = undefined;
	/**
	 * Expanded Name: Area instance Id
	 * 
	 * Desc: Each service center is divided into multiple geographic areas for delivery. This identifies the delivery area of destination service center in which this shipment s consignee is located.
	 * 
	 * Source: SMART 4 Requirement
	 * 
	 * == NOTE ==  Ties back to the geo-area stored in Oracle spatial database for each service center.
	 */
	areaInstId:number = undefined;
	/**
	 * Expanded Name: Auto Rateable Indicator
	 * 
	 * Desc: This indicates whether the auto rating system is allowed to put rates and charges on the bill. This is irrespective of whether the bill needs to be manually reviewed or not before being marked RATED. 
	 * 
	 * Valid Values: 
	 * Y - Auto rating system may put rates and charges
	 * N - Auto Rating system should not out or change any rates and charges on the bill
	 */
	autoRateableInd:boolean = undefined;
	/**
	 * Expanded Name: Motorized-Move Pieces Known Indicator
	 * 
	 * Desc: This indicates whether MTRZD_PCS_CNT is known or applicable.. 
	 * 
	 * Valid Values: 
	 * Y - Yes, MTRZD_PCS_CNT is known/applicable
	 * N - No, MTRZD_PCS_CNT is NOT known/applicable
	 * 
	 * Source:  FBES
	 */
	motorizedPiecesKnownInd:boolean = undefined;
	/**
	 * Expanded Name : Estimated Transit Days (in number of days)
	 * 
	 * Desc : The standard number of days it should take to move a shipment from origin to destination, as defined in the OSS_SVC_STDS_A/B tables, adjusted by +1 if LATE_TENDER_IND =  Y 
	 * 
	 * Source : STD_TRNST_DAYS +1, if LATE_TENDER_IND =  Y
	 */
	estimatedTransitDays:string = undefined;
	/**
	 * Expanded Name : Calculated Service Days (in number of days)
	 * 
	 * Desc : The number of days it takes to move a shipment from shipper until it reaches a service stopping event.
	 * 
	 * Source : calculated by subtracting PKUP_DT from the date portion of the (LST_)MVMT_TMST of the first service-stopping event / movement, adjusted appropriately for non-work days occuring between the two dates.  There are a number of interim delivery qualifiers (DLVRY_QALFR_CD) that can cause the calculation (ie. stop service).
	 */
	calculatedServiceDays:string = undefined;
	/**
	 * Expanded Name : Calculated Service Timestamp
	 * 
	 * Desc : The timestamp of a service stopping event.
	 * 
	 * Source : (LST_)MVMT_TMST of the first service-stopping event / movement.  There are a number of interim delivery qualifiers (DLVRY_QALFR_CD) that can cause the calculation (ie. stop service).
	 */
	calculatedServiceDateTime:Date = undefined;
	/**
	 * Expanded Name : Calculated Movement Sequence Number
	 * 
	 * Desc : The sequence number identifying the movement (service-stopping event) for the calculation of CALC_SVC_DAYS.
	 * NOTE - VALUE CAN BE ZERO FOR PARTLOT (PTLT) PROS.
	 * 
	 * Source : SHM_MOVEMENT - MVMT_SEQ_NBR
	 */
	calculatedMovementSequenceNbr:string = undefined;
	/**
	 * Expanded Name : Difference of Calculated Days
	 * 
	 * Desc : Difference between CALC_SVC_DAYS and EST_TRNST_DAYS.
	 * 
	 * Source : CALC_SVC_DAYS - EST_TRNST_DAYS.
	 */
	diffCalculatedDays:string = undefined;
	/**
	 * Expanded Name : Difference of Transit Days
	 * 
	 * Desc : Difference between ACTL_TRNST_DAYS and EST_TRNST_DAYS.
	 * 
	 * Source : ACTL_TRNST_DAYS - EST_TRNST_DAYS.
	 */
	diffTransitDays:string = undefined;
	/**
	 * Expanded Name : Late Tender Code
	 * 
	 * Desc : Indicates whether shipment could not be picked up in time (due to late tender by shipper) to make service.
	 * 
	 * Values: Y - Late Tender; blank or N - Not Late Tender
	 * 
	 * Source : Bill Entry/FBES; can be populated at Pick-up Request create time if a skeleton PRO is available; may be changed in Corrections
	 */
	lateTenderCd:LateTenderCd = undefined;
	/**
	 * Expanded Name : Shipment Service Status Code
	 * 
	 * Desc : Indicates whether shipment met service standards.
	 * 
	 * Values: Fail, Success, Exempt, Unknown
	 * 
	 * Source : Calculated
	 */
	shipmentServiceStatusCd:ServiceStatusCd = undefined;
	/**
	 * Expanded Name : Service Calculation Status Code
	 * 
	 * Desc : Indicates whether shipment service calculations have been performed.
	 * 
	 * Values:
	 * 			0 - Not Calculated Yet
	 * 			1 - Cannot Calculate (all variables not defined)
	 * 			? - Calculated from (service stopping event or appointment - replace '?' with DLVRY_QALFR_CD values)
	 * 
	 * Source : Calculated
	 */
	serviceCalculatedStatusCd:ServiceCalculationStatusCd = undefined;
	/**
	 * Expanded Name : Exemption Reason Code
	 * 
	 * Desc : Indicates why a shipment may be exempt from meeting service standards.
	 * 
	 * Values:
	 * 			blank - Not Exempt;
	 * 			any valid exemption reason code
	 * 
	 * Source : OPS_EXEMPT_SHPMT; shipment exemptions are created immediately upon full trailer and service center exemption events.
	 */
	exemptReasonCd:ExemptReasonCd = undefined;
	/**
	 * Expanded Name : Pickup Date Back-Date Indicator
	 * 
	 * Desc : Indicates whether a shipment pickup date was backdated during bill entry.
	 * 
	 * Values:
	 * 			Y - Shipment pickup date was backdated at bill entry
	 * 			N - Shipment pickup date = bill entry date
	 * 
	 * Source : Bill Entry/FBES.
	 */
	pickupBackdateInd:boolean = undefined;
	/**
	 * Expanded Name: FOOD OR POISON CODE
	 * 
	 * Desc; Indicates whether the shipment contains food or posion. Assumption: A shipment will never contain both.
	 * 
	 * Valid Values:
	 * 
	 * 1 - Neither
	 * 2 - Food
	 * 3 -  Poison
	 */
	foodPoisonCd:FoodPoisonCd = undefined;
	/**
	 * Expanded Name: Rating Override Code
	 * 
	 * Desc: Indicates if a rating override has been indicated during billing. For example to use a spot quote or lineal feet rating.
	 * 
	 * Valid Values:
	 * 1: Spot Quote Number Specified for rating
	 * 2: Lineal Feet Rating should be used
	 */
	ratingOverrideCd:string = undefined;
	/**
	 * Expanded Name : Pick-Up Time
	 * 
	 * Desc : Time when the shipment was picked up. This will be required for Late Tender shipments and optional for other shipments.
	 * 
	 * Source : Bill Entry/FBES; can be populated at Pick-up
	 * Request create time if a skeleton PRO is available;
	 * may be changed in Corrections
	 */
	pickupTime:string = undefined;
	/**
	 * Expanded Name : Service Start Date
	 * 
	 * Desc : Business date on which the service clock starts.  This value will be calculated automatically for all shipments, based on the values of PKUP_DT and LATE_TENDER_CD.  If a shipment is tendered late, the service start date will be set to the next business date after the pick-up date.
	 * 
	 * Source : Will be populated at pkup request create time if a skeleton PRO is available, during bill entry, through the late tender correction screen.
	 */
	serviceStartDate:string = undefined;
	/**
	 * Expanded Name: Inspected Indicator
	 * 
	 * Desc: Indicates whether the shipment has been inspected or not.
	 */
	inspectedInd:boolean = undefined;
	/**
	 * Expanded Name: Spot Quote ID
	 * 
	 * Desc: The Spot Quote ID that was entered during Billing. This may be different from the one that was used for rating.  For the latest Spot Quote ID that was used for raing, please look at the rating info record.
	 */
	spotQuoteId:number = undefined;
	/**
	 * Expanded Name: Shipper to Consignee Miles
	 * 
	 * Desc: This captures the mileage from shipper to consignee as calculated by the Milemaker package (Rand McNally)
	 * 
	 * Added by C.Doucet 05/06/09 as per Shibashis
	 */
	shipperToConsigneeMiles:number = undefined;
	/**
	 * Expanded name: Shipper loaded trailer code
	 * 
	 * Desc: Identifies whether a shipper loaded a trailer, and if so, where Con-way took possession of trailer. 
	 * 
	 * Values: 
	 * N Â Shipper did not load trailer (default)
	 * H Â Hook loaded; a trailer is loaded by shipper and picked up by Con-way at shipper s site 
	 * D Â Dock Drop; a trailer is loaded by shipper and dropped at Con-way dock by shipper
	 */
	shipperLoadedTrlrCd:ShipperLoadedTrailerCd = undefined;
	/**
	 * Expanded Name: Consignee unloaded trailer code
	 * 
	 * Desc: Identifies whether a consignee unloaded a trailer, and if so, where consignee took possession of trailer. 
	 * 
	 * Values:
	 * N Â Consignee did not unload trailer (default)
	 * D Â Dock Pickup; a loaded trailer is picked up at Con-way dock by consignee to be unloaded by consignee 
	 * S Â Spot loaded; a loaded trailer is left at the consignee site for consignee to unload
	 */
	consigneeUnloadTrlrCd:ConsigneeUnloadedTrailerCd = undefined;
	/**
	 * Expanded Name: Lineal Foot Total Number
	 * 
	 * Desc: This is the length in feet that a shipment will take up in a trailer.  This dimension is typically used when the freight is long and narrow and cannot have anything stacked on top of it (e.g. carpet rolls).  For this reason it takes a larger portion of the trailer volume than a shipment of the same weight that is packaged on pallets.  This value can then be used in the planning of trailer loads and to rate shipments that have these characteristics.
	 */
	linealFootTotalNbr:string = undefined;
	/**
	 * Expanded Name: Single Shipment Acquired Indicator                                                                                                                                                Desc:                                                                                                      This indicates whether this was the only Shipment that we acquired from the Customer at the time that we received this one.
	 * Values:
	 * Y Â Yes:  This was the only Shipment Acquired at the time that we received this one.
	 * N Â No: There were other Shipments Acquired at the time that we received this one.
	 */
	singleShipmentAcquiredInd:boolean = undefined;
	/**
	 * Desc: This code identifies the method in which Con-way received the freight from the customer.
	 * Current values are: 
	 * PU Â Regular Pickup:  This is when the driver goes to the customer dock and loads the Shipment on the trailer to return to the service center.
	 * HL Â Hook Loaded: This is when the driver goes to the customer site and pickup a loaded trailer that this Shipment was on.
	 * DD Â Dock Drop:  This is when the customer delivers the Shipment to a Con-way Service Center, so no driver went to the Customer site
	 */
	shipmentAcquiredTypeCd:ShipmentAcquiredTypeCd = undefined;
	/**
	 * Expanded Name : Loose pieces count
	 * 
	 * Desc : Total loose pieces from all commodities in the shipment.
	 * 
	 * Count of non Motor Move pieces associated with a shipment. This count plus Motor Moves defines the total number of pieces moving within the Con-way system for Operations productivity.  This does not necessarily balance with the total pieces or commodity pieces entered during bill entry.
	 */
	loosePiecesCount:number = undefined;
	/**
	 * Expanded Name: PUP_VOLUME_PERCENTAGE
	 * 
	 * Desc: This is the volume of the shipment expressed as the percentage of a full pup (28 feet long trailer).
	 */
	pupVolumePercentage:number = undefined;
	/**
	 * Desc: Instance id of the Pickup Request that is associated with this Shipment during the Confirm On-Board process.
	 */
	purInstId:number = undefined;
	/**
	 * Expanded Name: Bulk Liquid Indicator
	 * 
	 * Desc: Identifies the shipment as containing bulk liquid.  If volume is known, this applies to shipments >119 gallons.
	 * 
	 * Added 8/5/14 by Paul Bramhall for Tanker Endorsement Project.
	 * -------------------------------------------------------------
	 */
	bulkLiquidInd:boolean = undefined;
	/**
	 * 
	 */
	cubicFeetProfileMthdCd:ShipmentVolumeMethodCd = undefined;
	/**
	 * 
	 */
	cubicFeetProfileTypeCd:ShipmentVolumeTypeCd = undefined;
	/**
	 * In the event a customer requires a shipment to travel from origin to destination 
	 * without the loading of any additional shipments on the trailer(s), the shipment is considered Exclusive Use.
	 */
	exclusiveUseInd:boolean = undefined;
	/**
	 * Indicates that the customer would like to insure the shipment due to excessive value of the freight.
	 */
	excessiveValueInd:boolean = undefined;
	/**
	 * The value of the freight as declared by the customer.
	 */
	declaredValueAmount:number = undefined;
	/**
	 * Indicates that this shipment is marked as a Mexico Door to Door shipment.
	 */
	mexicoDoorToDoorInd:boolean = undefined;
	/**
	 * Indicates that this shipment is time date critical and operations needs to call for a delivery appointment.
	 */
	callForAppointmentInd:boolean = undefined;
	/**
	 * Indicates whether the payment terms were flipped on a bill after biller submit, based on input shipment party data.
	 */
	debtorTermFlipInd:boolean = undefined;
	/**
	 * Identifier for unit of work on Shipment.  Added 02/13/2020
	 */
	transactionId:string = undefined;
	/**
	 * Indicates that the PRO is exempted to be tracked via piece level. SLC (Shipper Loaded Count) shipments are generally exempted. Pickups with 15 or more pieces are also allowed to be exempted.    Added 12/10/2020  LISG-9067 Renamed 01/07/2021
	 */
	handlingUnitExemptionInd:boolean = undefined;
	/**
	 * An explanation of why a pro is exempted from piece level tracking.  Added 12/10/2020   LISG-9067 Renamed 01/07/2021
	 */
	handlingUnitExemptionReason:string = undefined;
	/**
	 * An appointment should be created for delivering the shipment.  Added 03/11/2021 Cathy Doucet LISG-10771
	 */
	appointmentRequiredInd:boolean = undefined;
	/**
	 * The consignee should be notified upon arrival of the shipment at destination. During the notification, if the consignee requires an appointment, an appointment will be created, and the shipment will be charged for both services.  Added 03/11/2021  Cathy Doucet LISG-10771.
	 */
	destinationNotifyInd:boolean = undefined;
	/**
	 * The shipment total weight at the time of reweigh (if a reweigh occurred).  Added 06/28/2021  LISG-13269
	 */
	reweighWeightLbs:number = undefined;
	/**
	 * Indicates if the freight has been poorly packaged or not by the Shipper.  Added 05/04/2022  LISG-16763
	 * 
	 * Valid Values:
	 * N - freight is NOT packaged poorly (default)
	 * Y - freight IS packaged poorly
	 */
	poorlyPackagedInd:boolean = undefined;
	/**
	 * Indicates that one or more of the handling units associated with this shipment has become separated from the others.Added 05/16/2022 LISG-16851
	 */
	handlingUnitSplitInd:boolean = undefined;
	/**
	 * Indicates that one or more of the handling units associated with this shipment is missing.Added 05/16/2022 LISG-16851
	 */
	handlingUnitPartialInd:boolean = undefined;
	/**
	 * PLT PROs crossing border that happened to be split requires a MOVR PRO when some of the handling units have crossed border already. This identifies the MOVR PRO associated to the parent PRO which will move this handling unit across border.  Added 06/14/2022 LISG-17035
	 */
	moverSuffix:string = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * 
	 */
	handlingUnit:HandlingUnit[] = undefined;
	/**
	 * 
	 */
	remark:Remark[] = undefined;
	/**
	 * 
	 */
	movement:Movement[] = undefined;
}
/**
 * 
 */
export class TimeDateCritical {
	/**
	 * 
	 */
	shipmentInstId:number = undefined;
	/**
	 * Expanded: Time Date Critical Source Code
	 * 
	 * Desc: Where the Time Date Critical information was entered.
	 * 
	 * Valid Values:
	 * BIL=Bill Entered
	 * PUR=Pick Up Request
	 * EDI=EDI
	 * WEB=Web
	 * COR=Corrections
	 */
	tdcSourceCd:string = undefined;
	/**
	 * Expanded: Time Date Critical Date Type Code
	 * 
	 * Desc: What kind the dates on the TDC shipment is/are.  There are 3 main flavors of date on Time Date Critical shipments: Deliver on a date, Deliver by a date, or a Deliver sometime within a date range  
	 * 
	 * Valid Values:
	 * ON=On
	 * BY=By
	 * RNG=Range
	 */
	tdcDateTypeCd:string = undefined;
	/**
	 * Expanded: Time Date Critical Date #1
	 * 
	 * Desc: There are 3 flavors of date for a TDC shipment.  This attribute captures the following flavor of date: ON, BY or FROM
	 */
	tdcDate1:string = undefined;
	/**
	 * Expanded: Time Date Critical Date #2
	 * 
	 * Desc: There are 3 flavors of date for a TDC shipment.  This attribute captures the TO date if the type is a date range.
	 */
	tdcDate2:string = undefined;
	/**
	 * Expanded: Time Date Critical Time Type
	 * 
	 * Desc: What kind the times on the TDC shipment is/are.  There are 4 main flavors of time for Time Date Critical shipments: Deliver at a time, Deliver before a time, Deliver after a time, or a Deliver sometime within a time range  
	 * 
	 * Valid Values:
	 * AT=At
	 * BEF=Before
	 * AFT=After
	 * RNG=Range
	 */
	tdcTimeTypeCd:string = undefined;
	/**
	 * Expanded: Time Date Critical Time #1
	 * 
	 * Desc: There are 4 flavors of time for a TDC shipment.  This attribute captures the following flavor of time: AT, AFTER, BEFORE, FROM
	 */
	tdcTime1:string = undefined;
	/**
	 * Expanded: Time Date Critical Time #2
	 * 
	 * Desc: There are 4 flavors of time for a TDC shipment.  This attribute captures the TO time if the type is a time range.
	 */
	tdcTime2:string = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 */
	auditInfo:AuditInfo = undefined;
}
export class XrtAttributeFilter {
	equals:string = undefined;
	min:string = undefined;
	max:string = undefined;
	startsWith:string = undefined;
	values:string[] = undefined;
	query:string = undefined;
	exclude:string = undefined;
	radius:string = undefined;
	radiusMeasurementUnit:string = undefined;
	lat:number = undefined;
	lon:number = undefined;
	points:XrtGeoPoint[] = undefined;
	convert:boolean = undefined;
	contains:string = undefined;
	distanceFeature:XrtDistanceFeature = undefined;
	exists:boolean = undefined;
}
export class XrtDistanceFeature {
	point:XrtGeoPoint = undefined;
	distance:string = undefined;
}
export class XrtGeoPoint {
	lat:number = undefined;
	lon:number = undefined;
}
export class XrtSortExpression {
	isDescendingSort:boolean = undefined;
	column:string = undefined;
	direction:string = undefined;
}
export class XrtSearchQueryHeader {
	pageNumber:number = undefined;
	sortExpressions:XrtSortExpression[] = undefined;
	pageSize:number = undefined;
	fields:string[] = undefined;
	excludeFields:string[] = undefined;
}
export class XrtSearchRespHeader {
	/**
	 * Duration of query
	 */
	duration:number = undefined;
	/**
	 * Current page number of result set
	 */
	pageNumber:number = undefined;
	/**
	 * Number of rows contained in a page
	 */
	pageSize:number = undefined;
	/**
	 * Number of results
	 */
	resultCount:number = undefined;
}
export class XrtExportQueryHeader {
	fields:string[] = undefined;
	fileType:string = undefined;
}
/**
 * Information about global accessorials and how to calculate their charge amounts
 */
export class AccessorialChargePricing {
	/**
	 * This code also identifies the accessorial charge.
	 */
	accessorialCd:string = undefined;
	/**
	 * Indicates whether the charge for the accesorial service is discountable or not.
	 */
	accessorialDiscInd:boolean = undefined;
	/**
	 * This is the unit of measure for specifying the maximum and minimum limits for the accessorial service.
	 */
	accessorialUom:string = undefined;
	/**
	 * 1- Flat Rate per UOM
	 * 2 - % of Specified Charge type
	 */
	chargeTypeCd:string = undefined;
	/**
	 * Indicates whether the AC is a literal or not.
	 */
	literalInd:boolean = undefined;
	/**
	 * This is the maximum charge amount that will be charged if the accessorial service was performed.
	 */
	overrideMaxChgAmt:MonetaryAmount = undefined;
	/**
	 * The maximum units for which this charge applies
	 */
	overrideMaxUnitCount:number = undefined;
	/**
	 * This indicates if the charges for the accessorial service are tiered by weight or other units of measure.
	 */
	overrideMinChgAmt:MonetaryAmount = undefined;
	/**
	 * The minimum units for which this charge applies.
	 */
	overrideMinUnitCount:number = undefined;
	/**
	 * This indicates if the accessorial charge will always be charged on every shipment for the customer that gets rated using this ruleset irrespective of whether the Accessorial service was performed or not.
	 * Valid Values:
	 * Y - Always Apply
	 * N- Apply only when service is performed
	 */
	alwaysApplyInd:string = undefined;
	/**
	 * This is the override type that overrides the default AC service. Based on this type code charges will be derived. i.e., charges can be from standard default or the override value.
	 * Valid Values: 
	 * 1. Selected without changes (Charges will float)
	 * 2. Modified (Charges are frozen as modified)
	 * 3. Waived (Charges are frozen at 0.00)
	 * 4. Frozen (Charges are fozen at the default value during ruleset setup)
	 * 5. Modified-Float (Charges will float, but will use min/max units from override)
	 */
	overrideTypeCode:string = undefined;
	/**
	 * Specifies the type of charge which is used for calculating the charges for the accessorial service using the RATE_PCT. 
	 * Valid Values: 
	 * 
	 * 1: COD Amount
	 * 2: Linehaul Charges
	 * 3: Advance Amount
	 * 4: Order Notify Amount
	 * 5: Excess Value Amount Declared
	 */
	overridePctTypeCode:string = undefined;
	/**
	 * This is the rate at which the accessorial service is charged. This is mutually exclusive with ratePct and will not be specified if ratePct is specified.
	 */
	overrideAmt:MonetaryAmount = undefined;
	/**
	 * This specfies the percentage to be used for calculating the charges for the accessorial service. Mutually exclusive with rateAmt
	 */
	overridePct:number = undefined;
	termCreditCd:string = undefined;
	/**
	 * This indicates if the charges for the accessorial service are tiered by weight or other units of measure.
	 */
	tieredInd:boolean = undefined;
	tierSeqNbr:number = undefined;
}
/**
 * Accessorial overrides from the Pricing Agreement
 */
export class AccessorialOverride {
	/**
	 * accessorial code to be overridden
	 */
	accessorialCd:string = undefined;
	/**
	 * Valid Values: 
	 * 1. Selected without changes (Charges will float)
	 * 2. Modified (Charges are frozen as modified)
	 * 3. Waived (Charges are frozen at 0.00)
	 * 4. Frozen (Charges are fozen at the default value during ruleset setup)
	 */
	accOverrideTypeCd:string = undefined;
}
/**
 * Information about the Contract Terms for the Pricing Agreement
 */
export class AgreementContractTerm {
	/**
	 * Information about the Pricing Agreement
	 */
	prcAgreement:PricingAgreementId = undefined;
	/**
	 * This indicates whether the discount percentage will be displayed in the discount line text on the customers invoice. For example, whether the discount line should be
	 * CON-WAY DISOCUNT SAVES YOU 70% or 
	 * CON-WAY DISCOUNT SAVES YOU
	 * 
	 * Note: Even if the indicator is turned on, it will not display the discount on a minimum charge shipment.
	 * 
	 * Valid Values: 
	 * Y- Display Discount
	 * N- Do not Display Discount
	 */
	displayDiscountInd:string = undefined;
	/**
	 * This is the number of days the customer has for paying the freight charges. This will update the default payment terms in CIS when the agreement is approved. When the agreement is expired or cancelled it will reset the CIS value to default 15 days.
	 */
	paymentTerm:number = undefined;
	/**
	 * This pallet weight value is used for two purposes:
	 * The first is to provide an average weight per pallet for calculating the total shipment pallet weight tolerance for Con-Firm / re-weigh when the PLLT_WAIVER_IND is set to Y. This is also use to indicate the average weight per pallet for calculating reduced weight (reduced charge) on palletized shipments when the REDUCE_PLLT_WGT_IND is set to Y.
	 */
	avgPalletWeight:Weight = undefined;
	/**
	 * Indicates that the customer has a pallet weight allowance for re-weigh purposes. A value of 'Y' also requires that there to be a value in AVG_PLLT_WGT_LBS. Some customers may have a reduced weight (reduced charge) allowance for the weight of their pallets (see REDUCE_PLLT_WGT_IND ), but that same customer doesn't necessarily get a pallet weight allowance for re-weigh purposes, unless this indicator is set.
	 */
	palletWaiverInd:string = undefined;
	/**
	 * Indicates that the customer is not be charged for the weight of their pallets. A value of 'Y' also requires that there to be a value in AVG_PLLT_WGT_LBS. Some customers may have a pallet weight allowance for re-weigh purposes (see PLLT_WAIVER_IND), but that same customer doesn't necessarily get a reduced weight (reduced charge) for the weight of the pallet(s) on their shipments, unless this indicator is set.
	 */
	reducedPalletWgtInd:string = undefined;
}
export class ClassOverrideBasic {
	/**
	 * commodityClassCode
	 */
	commodityClass:string = undefined;
	/**
	 * Commodity class that overrides the commodityClass
	 */
	overrideClass:string = undefined;
}
/**
 * Commodity class overrides in the Pricing Agreement
 */
export class ClassOverridexx {
	/**
	 * Commodity NMFC class
	 */
	commodityClass:string = undefined;
	/**
	 * NMFC item that along with class uniquely defines the type of Freight
	 */
	commodityNmfcItem:string = undefined;
	/**
	 * FAK that overrides the NMFC class code and NMFC Item
	 */
	commodityFak:string = undefined;
}
/**
 * Information about the Class Rate Ruleset
 */
export class ClassRate {
	/**
	 * Indicates whether any AC has been overridden for the ruleset
	 */
	acOverrideInd:string = undefined;
	agrmtContractTerm:AgreementContractTerm = undefined;
	/**
	 * Identifies the rate audit queue the shipments will be put into if they are rated using this rule set.
	 */
	auditQueueName:string = undefined;
	/**
	 * Indicates whether any Class has been overridden for the ruleset. Valid values are Y or N
	 */
	classOverrideInd:string = undefined;
	classOverride:ClassOverrideBasic[] = undefined;
	commodityPricingInd:string = undefined;
	commodityPricing:CommodityPricing[] = undefined;
	corpFsc:FuelSurcharge = undefined;
	/**
	 * This indicates if this ruleset should be used to rate a a shipment even if this ruleset is not from the debtor of the shipment and the debtor of the shipment has his own ruleset that is applicable to the shipment.
	 */
	debtorOverrideInd:string = undefined;
	/**
	 * Indicates if the ruleset is defined with default discount. Values are Y or N
	 */
	defaultDiscInd:string = undefined;
	/**
	 * Indicates whether Deferred Override exists.
	 */
	deferredOverrideInd:string = undefined;
	/**
	 * Indicates if the discounts are defined by weight bracket. In this case the value in the DISC_PCT attribute should be ignored
	 */
	discByWgtInd:string = undefined;
	discountPgm:DiscountPgm = undefined;
	discountTier:DiscountTier[] = undefined;
	/**
	 * Identifies the entry queue the shipments will be put into if they qualify to be rated using this rule set.
	 * 
	 * If this is non blank, the pro will not be auto rated.
	 */
	entryQueueName:string = undefined;
	/**
	 * This is the discount that will be applied when the fall back tariff is used to rate the shipment
	 */
	fallBackDiscPct:number = undefined;
	/**
	 * This field is applicable for frozen rate bases or tariffs. When this rule set cannot rate a shipment using a frozen rate base the fall back tariff will be used to rate the shipment.
	 */
	fallBackTariffName:string = undefined;
	/**
	 * Indicates whether the FSC has been overridden or waived for the ruleset. 
	 * Valid Values: 
	 * 
	 * 1- FSC neither Waived nor Overridden 
	 * 2 - FSC Waived
	 * 3 - FSC Overridden (Other FSC Tariff)
	 * 4 - FSC Overridden (CNWY190 Tariff)
	 * 5 - Universal (this value indicates the overrides are defined in universal ruleset i.e., rs seq nbr zero record)
	 */
	fscOverrideCd:string = undefined;
	/**
	 * Name of the tariff that governs how the fuel surcharge will be applied by the rule set.
	 */
	fscTariffName:string = undefined;
	/**
	 * Indicates that the bill should be held for manual processing following any autorating.
	 */
	holdForManualRateInd:string = undefined;
	/**
	 * Indicates whether the rule set applies on various combinations of direct and interline shipments
	 * Valid Values: 
	 * 1 - Applies on Advance Carrier Interline only
	 * 2 - Applies on Direct and Advance Carrier Interline only
	 * 3 - Applies on Advance and Beyond interline but not on direct shipments
	 * 4 - Applies on all shipments
	 * 5 - Applies on Beyond Interline shipments only
	 * 6 - Applies on Direct Shipments only
	 * 7 - Applies on Direct and Beyond Interline only
	 */
	interlineApplyCd:string = undefined;
	/**
	 * The discount percentage to be applied for lineal foot rates. This is only valid if the Lineal foot rates are overridden
	 */
	linealFtDiscPct:number = undefined;
	/**
	 * Indicates whether a shipment qualified to be rated using this rule set is exempt from lineal foot rates or not.
	 */
	linealFtExemptInd:string = undefined;
	/**
	 * Indicates whether the lineal foot has been overridden for the ruleset.
	 */
	linealFtOverrideInd:string = undefined;
	linealFtRate:LinealFeetRate[] = undefined;
	/**
	 * Indicates lineal foot queuing is required for shipments rated with this ruleset 
	 * 
	 * Valid Values: 
	 * 
	 * Y- Yes (automatic queuing of lineal foot shipments)
	 * N - No (no LF queuing is required).
	 */
	linealFtQueInd:string = undefined;
	/**
	 * extra services that are not accessorial fees that are associated with a customer's pricing
	 */
	miscSvcOverride:MiscSvcOverride = undefined;
	/**
	 * Indicates whether NMFC has been overridden or not. Values are Y or N
	 */
	nmfcOverrideInd:string = undefined;
	/**
	 * NMFC numbers for which the class is overridden to a different class value.
	 */
	nmfcOverride:NmfcClassOverride[] = undefined;
	nonTieredAccessorial:AccessorialChargePricing[] = undefined;
	/**
	 * This indicates if another parties discounts or the default discounts applicable to certain regions should apply to the bill if these dicounts result in a lower rate for the customer.
	 */
	overrideAllowedInd:string = undefined;
	ratingTariffName:string = undefined;
	/**
	 * Effective date of the Ruleset customer record
	 */
	rulesetCustEffDate:Date = undefined;
	/**
	 * Expiry date of ruleset customer record
	 */
	rulesetCustExpDate:Date = undefined;
	/**
	 * Effective Date of the Ruleset
	 */
	rulesetEffDate:Date = undefined;
	/**
	 * Expiry date of the Ruleset
	 */
	rulesetExpDate:Date = undefined;
	/**
	 * Ruleset Sequence Number
	 */
	rulesetNbr:number = undefined;
	/**
	 * Ruleset rating instruction
	 */
	ratingInstr:string = undefined;
	/**
	 * Name of the rules tariff applicable to the rule set
	 */
	rulesTariffName:string = undefined;
	/**
	 * This is the factor by which the rates from the rating tariff will be multiplied while rating a shipment. The Pricing analysts can set this field. The AEs and NAEs will be allowed to view this field but they will not be able to change it.
	 */
	tariffFactor:number = undefined;
	/**
	 * This is the factor by which the rates from the rating tariff minimum charge will be multiplied while rating a shipment. The Pricing analysts can set this field. The AEs and NAEs will be allowed to view this field but they will not be able to change it.
	 */
	tariffMcFactor:number = undefined;
	tieredAccessorial:AccessorialChargePricing[] = undefined;
	weightRestriction:WeightRestriction = undefined;
	/**
	 * Indicates whether any weight restrictions exist for the ruleset
	 */
	weightRestrInd:string = undefined;
}
/**
 * Information about the commodity rates for a ruleset
 */
export class CommodityPricing {
	/**
	 * A system assigned sequential number to uniquely
	 * identify the rate entries
	 */
	seqNbr:number = undefined;
	/**
	 * Identifies the lowest unit count for which the commodity pricing is to be applied
	 */
	lowRangeCnt:number = undefined;
	/**
	 * Identifies the highest unit count for which the commodity pricing is to be applied.
	 */
	highRangeCnt:number = undefined;
	/**
	 * Minimum Charge Amount - A shipment rated using this ruleset's commodity pricing may not be charged less than this amount.
	 */
	minChargeAmt:MonetaryAmount = undefined;
	/**
	 * Price per unit (Ex. $35 per each pallet).
	 */
	unitRate:MonetaryAmount = undefined;
	/**
	 * Rate type is used for various total charge calculation types.
	 * 
	 * Valid values:
	 * 1 - Per Unit (Ex. Total Charge will be $150.00 (50*3), rate per unit is $50 and 3 unit are used).
	 * 2 - Total Charges (Ex. Total Charge will be $60 for one unit, $100 for two units, $140 for three units). 
	 * 3 - Both (Ex. Total charge will be $ 250 (175+60) for 5 units, i.e., for $175 for first four units and $60 per each additional unit).
	 */
	rateTypeCode:string = undefined;
	/**
	 * Desc: 
	 * 1. When rt_typ_cd is '2', this is the total charge for the all the commodity units together.
	 * 2. When rt_typ_cd is '3', this is the base rate that will be added to the (unit_rt * (total units - base_rt_unit_cnt)).
	 */
	baseRate:MonetaryAmount = undefined;
	/**
	 * This is the number of units for which the base rate is applied. It must be specified when rt_typ_cd is '3'.
	 */
	baseRateUnitCount:number = undefined;
	/**
	 * This indicates how the fuel surcharge to be applied.
	 * 
	 * Valid Values:
	 * 0 - FSC applies.
	 * 1 - FSC is waived for all the shipments.
	 * 2 - FSC is waived for intra SIC shipments only.
	 * 3 - FSC is waived for intra state shipments only.
	 * 4 - FSC is waived for intra US shipments only.
	 * 5 - FSC is waived for intra Canadian shipments only.
	 */
	fscCode:string = undefined;
}
/**
 * Information about the Discount Program related to a Ruleset
 */
export class DiscountPgm {
	/**
	 * System assigned sequential number to unqiuely identify each discount program for a ruleset
	 */
	discountPgmSeqNbr:number = undefined;
	/**
	 * Applicable discount in %. This attribute does not have a value when the discounts are by weight bracket
	 */
	discountPct:number = undefined;
	/**
	 * Applicable discount in %, when minimum charges are applied to a line item.
	 */
	minChrgDiscountPct:number = undefined;
	/**
	 * Absolute Minimum Charge Amount - A shipment rated using the rule set may not be charged less than this amount.
	 */
	absMinChargeAmt:number = undefined;
}
/**
 * Tiered discount structure
 */
export class DiscountTier {
	/**
	 * This is the lower limit of the range of weight or volume for which the ruleset is applicable
	 */
	lowRange:number = undefined;
	/**
	 * This is the higher limit of the range of the weight or volume for which the ruleset is applicable
	 */
	highRange:number = undefined;
	/**
	 * Applicable discount pct
	 */
	discountPct:number = undefined;
	rngLabelText:string = undefined;
}
/**
 * Information about the Fuel Surcharge 
 */
export class FuelSurcharge {
	typeCode:string = undefined;
	chargeAmt:MonetaryAmount = undefined;
	pct:number = undefined;
	tlFactorNbr:number = undefined;
}
/**
 * Information about the Lineal Feet pricing from the Ruleset
 */
export class LinealFeetRate {
	/**
	 * A system assigned sequential number to uniquely identify each lineal foot rate line for a ruleset.
	 */
	seqNbr:number = undefined;
	/**
	 * This identifies the trailer type to which the overridden lineal foot rate applies.
	 * 
	 * Valid Values:
	 * 1 - Single 28 Feet
	 * 2 - Double 28 Feet
	 * 3 - Single 40 Feet
	 */
	trailerTypeCode:string = undefined;
	/**
	 * This specifies the minimum loading space on the trailer the shipment should occupy to be rated using this rule set.
	 */
	minLoadSpaceFt:number = undefined;
	/**
	 * This specifies the maximum loading space on the trailer the shipment should occupy to be rated using this rule set.
	 */
	maxLoadSpaceFt:number = undefined;
	/**
	 * This is the rate per mile lineal feet rate.
	 */
	rateAmt:MonetaryAmount = undefined;
	/**
	 * This is the minimum amount that will be charged for the shipment that qualifies for rating using these overridden rate.
	 */
	minChargeAmt:MonetaryAmount = undefined;
	/**
	 * This is the minimum miles for which this rates apply.
	 */
	minMiles:number = undefined;
	/**
	 * This is the maximum miles for which this rates apply.
	 */
	maxMiles:number = undefined;
	/**
	 * Defines the unit of measure for the rate
	 * 
	 * Valid Values: 
	 * 
	 * 1 - per mile per feet
	 * 2 - per mile
	 */
	rateUom:string = undefined;
}
/**
 * Information about extra services that are not accessorial fees that are associated with a customer's pricing
 * 
 * 
 */
export class MiscSvcOverride {
	/**
	 * A system assigned sequential number to uniquely
	 * identify each service for a Pricing rule set.
	 */
	seqNbr:number = undefined;
	/**
	 * Identifies the type of charge for the Service. For certain type of services it may be a discount amount or percentage that will be applied to the shipment. For other type of services it may be an extra charge amount or percentage applied to the shipment.
	 * 
	 * Valid Values: 
	 * 1. Deferred Service Discount
	 */
	chargeTypeCode:string = undefined;
	/**
	 * This is the amount that will either be charged or discounted for this service based on the charge type.
	 */
	svcAmt:MonetaryAmount = undefined;
	/**
	 * This is the percentage of total charge of the shipment that will be either discounted or applied to the bill for the service based on the chareg type
	 */
	svcPct:number = undefined;
}
/**
 * NMFC Clase overrides in the Pricing Agreement
 */
export class NmfcClassOverride {
	/**
	 * A system assigned sequential number to uniquely
	 * identify each NMFC number included or excluded for a Pricing rule set.
	 */
	seqNbr:number = undefined;
	/**
	 * Commodity NMFC class
	 */
	commodityClass:string = undefined;
	/**
	 * NMFC class that overrides the commodity class
	 */
	overrideClass:string = undefined;
}
/**
 * NMFC Item Number overrides in the Pricing Agreement
 */
export class NmfcItemOverride {
	/**
	 * Commodity NMFC Item Number
	 */
	nmfcItemNbr:string = undefined;
	/**
	 * Override NMFC Item Number for commodity
	 */
	nmfcItemOverride:string = undefined;
}
/**
 * Information about the Id of the Pricing Agreement
 */
export class PricingAgreementId {
	/**
	 * This is the unique database key of the agreement.
	 */
	agreementId:string = undefined;
	/**
	 * The version of the  pricing agreement
	 */
	agreementCustVersion:string = undefined;
	/**
	 * The sequence number within the version of pricing agreement
	 */
	agreementSeqNbr:string = undefined;
	/**
	 * The instance ID of the customer that negotiated this pricing agreement
	 */
	leadAcctId:string = undefined;
	/**
	 * Values :
	 * 1 - LTL Pricing
	 * 2 - Spot Quote
	 * 3 - Pallet Pricing
	 */
	paTypeCd:string = undefined;
	/**
	 * Indicates if Pricing Agreement is active. Valid values are Y or N.This is set to N when either the Pricing Agreement Active Ind = N or the Ruleset Active Ind = N
	 */
	activeInd:string = undefined;
}
/**
 * Ruleset Identification Information
 */
export class RulesetId {
	agreementId:string = undefined;
	/**
	 * Ruleset Sequence Number
	 */
	rulesetNbr:number = undefined;
}
/**
 * Information about the Weight restrictions in class rates ruleset
 */
export class WeightRestriction {
	minWgt:Weight = undefined;
	maxWgt:Weight = undefined;
	minWgtPerPce:Weight = undefined;
	maxWgtPerPce:Weight = undefined;
}
export class PalletCommodity {
	palletTypeCd:string = undefined;
	palletCount:number = undefined;
	palletWeight:number = undefined;
	palletStackableInd:boolean = undefined;
	palletVolume:number = undefined;
	palletVolumeUom:string = undefined;
}
/**
 * Indicates whose ruleset was actually used for rating: S - Shipper; C - Consignee; B - Bill-to (Third Party).
 */
export class PricingAgreement {
	prcAgreementInstId:number = undefined;
	prcRulesetNbr:number = undefined;
	prcAgreementNbrTxt:string = undefined;
	ratingCustomerNbr:number = undefined;
	prcAgreementCustomerAcctId:string = undefined;
	overrideFactor:number = undefined;
	/**
	 * Pricing Agreement Absolute Minimum Charge Amount
	 */
	agreementAmcAmt:number = undefined;
	/**
	 * Pricing Agreement Effective Date
	 */
	pricingAgreementEffectiveDate:Date = undefined;
	/**
	 * Offshore Tariff
	 */
	offshoreTariff:string = undefined;
	/**
	 * Indicates whose ruleset was actually used for rating: S - Shipper; C - Consignee; B - Bill-to (Third Party).
	 */
	rulesetOwnerCd:string = undefined;
	/**
	 * This indicates whether the agreement was used to rate the shipment.
	 */
	agreementAppliedInd:boolean = undefined;
	/**
	 * This indicates whether the bill-to is related to the shipper, consignee, both, or a third party.  Permitted values: S - shipper, C - consignee, B - Both Shipper and Consignee, and N - Third Party.
	 */
	billToRelationship:string = undefined;
}
export class Rating {
	rateRequestTypeCd:RateRequestTypeCd = undefined;
	rateRequestSubTypeCd:string = undefined;
	requestId:number = undefined;
	cts599RatingInd:boolean = undefined;
	rerateInd:boolean = undefined;
	requestAccOnlyInd:boolean = undefined;
	disallowDeficitWeightRatingInd:boolean = undefined;
	doNotApplyFakInd:boolean = undefined;
	doNotOverrideFscInd:boolean = undefined;
	doNotApplySpecialDiscountInd:boolean = undefined;
	useClassRatesInd:boolean = undefined;
	overrideDiscountInd:boolean = undefined;
	consumerCallingProgram:string = undefined;
	reducedWeightCommodityLineCnt:number = undefined;
}
export class RatingAccessorial {
	sequenceNbr:number = undefined;
	accessorialCd:string = undefined;
	/**
	 * Description of the Accessorial
	 */
	accessorialDesc:string = undefined;
	minChargeInd:boolean = undefined;
	/**
	 * Accessorial Amount
	 */
	chargeAmt:number = undefined;
	unitRate:number = undefined;
	unitQty:number = undefined;
	maxChargeInd:boolean = undefined;
	chargeTypeDesc:string = undefined;
	rateTypeCd:string = undefined;
	literalInd:boolean = undefined;
}
/**
 * ***Needs Review
 */
export class RatingCommodityLine {
	/**
	 * Might be versions of a commodity line - original, corrected, as-rated
	 */
	commodityLineTypeCd:string = undefined;
	/**
	 * sequence on shipment
	 */
	shipmentSequenceNbr:number = undefined;
	dimensionMeasurement:DimensionMeasurement = undefined;
	dimensions:Dimensions = undefined;
	/**
	 * commodity and packing info including hazmat
	 */
	commodity:CommodityInfo = undefined;
	commoditySubLines:CommodityInfo[] = undefined;
	/**
	 * this will have the rate and charge information. if weight was changed based on rated as, then the new weight will be in quantity. The class will be reflected in asRatedNmfcClass
	 */
	charge:Charge = undefined;
	/**
	 * this is an FAK that has overridden the as entered class when rated
	 */
	asRatedNmfcClass:string = undefined;
	/**
	 * This is value declared by the shipper. This might have implications for the claims.
	 */
	declaredValue:MonetaryAmount = undefined;
	/**
	 * Commodity Line Override Charge Amount.
	 */
	overrideCharge:MonetaryAmount = undefined;
	/**
	 * These are refenence numbers provided by the party on the bill (shipper) specific to this commodity line.
	 */
	suppRefs:SupplementalReference[] = undefined;
	references:ReferenceNumber[] = undefined;
}
/**
 * Account IDs and address
 */
export class RatingParty {
	/**
	 * Database key for customer
	 */
	acctInstId:string = undefined;
	/**
	 * Business key for customer
	 */
	acctMadCd:string = undefined;
	/**
	 * Primary name
	 */
	name:string = undefined;
	/**
	 * Applies to mailing address of a person, where the address is actually in another entity's name.
	 */
	careOfName:string = undefined;
	/**
	 * Address line that includes Street name, direction and optionally building number, unit# etc. 
	 * e.g. 1000 SW Broadway st
	 */
	addressLine1:string = undefined;
	/**
	 * Address line that includes Street name, direction and optionally building number, unit# etc. 
	 * e.g. 1000 SW Broadway st
	 */
	addressLine2:string = undefined;
	/**
	 * PO Box typically applies to a mailing address. This is the standard PO Box# of an address.
	 */
	postOfficeBox:string = undefined;
	/**
	 * City Name part of the address.
	 */
	cityName:string = undefined;
	/**
	 * County Name part of the address.
	 */
	countyName:string = undefined;
	/**
	 * Standard Postal abbreviation for state name. Example: OR for Oregon.
	 */
	stateCd:string = undefined;
	/**
	 * standard Postal abbreviation for a country. Example: CA for Canada.
	 */
	countryCd:string = undefined;
	/**
	 * Postal Code are aka Zip code in USA. US and Mexico use 5 digit postal codes. Canada uses 6 char postal codes.
	 */
	postalCd:string = undefined;
	/**
	 * This is a 4 digit extension to US 5 digit zip code.
	 */
	usZip4:string = undefined;
	/**
	 * Describes the location type of a rating party.
	 */
	ratingPartyLocationTypeCd:RatingPartyLocationTypeCd = undefined;
}
export class RatingRequest {
	chargeToCd:ChargeToCd = undefined;
	guaranteedInd:boolean = undefined;
	overrideRatingTariff:string = undefined;
	advanceScacCd:string = undefined;
	advanceRevenueAmt:number = undefined;
	beyondScacCd:string = undefined;
	beyondRevenueAmt:number = undefined;
	overrideDiscountPct:number = undefined;
	overrideMinimumChargeDiscountPct:number = undefined;
	overrideFscPct:number = undefined;
	overrideFuelPrice:number = undefined;
	rulesTarifffDate:string = undefined;
	fuelTariffDate:string = undefined;
	discountSourceCd:string = undefined;
	overrideRulesTariff:string = undefined;
	overrideFuelTariff:string = undefined;
	classOverride:string = undefined;
	overrideAbsoluteMinimumChargeAmt:number = undefined;
	/**
	 * The Dynamic Pricing external rule ID for Dynamic Pricing evaluation.
	 */
	dpExternalRuleId:string = undefined;
	/**
	 * The Dynamic Pricing quote number for Dynamic Pricing evaluation.
	 */
	dpQuoteNbr:string = undefined;
}
export class RatingResponse {
	/**
	 * Density Linehaul Charge Amount
	 */
	densityLinehaulAmt:number = undefined;
	/**
	 * Density Calculated Class Code
	 */
	densityCalculatedClassCd:string = undefined;
	/**
	 * Density Source Type Code
	 */
	densitySourceTypeCd:string = undefined;
	/**
	 * Commodity As Weight or Deficit Weight Description
	 */
	asDeficitWeightDescription:string = undefined;
	/**
	 * Commodity As Weight or Deficit Weight
	 */
	asDeficitWeight:number = undefined;
	/**
	 * Commodity As Weight or Deficit Rate
	 */
	asDeficitWeightRate:number = undefined;
	/**
	 * Commodity As Weight or Deficit Amount
	 */
	asDeficitWeightAmt:number = undefined;
	/**
	 * Rated As Weight
	 */
	ratedAsWeight:number = undefined;
	/**
	 * Rating Tariff Name
	 */
	ratingTariff:string = undefined;
	/**
	 * Rating Tariff Version
	 */
	tariffVersion:string = undefined;
	/**
	 * Rate Base Tariff Name
	 */
	infoRatingTariff:string = undefined;
	/**
	 * Rules Tariff Name
	 */
	rulesTariff:string = undefined;
	/**
	 * Rules Tariff Version
	 */
	rulesTariffVersion:number = undefined;
	/**
	 * Fuel Surcharge Tariff Name
	 */
	fuelSurchargeTariff:string = undefined;
	/**
	 * Rating Currency Code
	 */
	ratingCurrencyCd:string = undefined;
	/**
	 * Applicable Discount Percentage
	 */
	applicableDiscountPct:number = undefined;
	/**
	 * Actual Discount Percentage
	 */
	actualDiscountPct:number = undefined;
	/**
	 * Fuel Price Amount
	 */
	fuelPriceAmt:number = undefined;
	/**
	 * Fuel Price Effective Date
	 */
	fuelPriceEffectiveDate:string = undefined;
	/**
	 * FSC Rate Type Unit of Measue
	 */
	fscRateUom:string = undefined;
	/**
	 * FSC Rate Type
	 */
	fscRateNbr:number = undefined;
	/**
	 * FSC Overrride Code
	 */
	fscOverrideInd:string = undefined;
	/**
	 * Discount Source Code
	 */
	discountSourceCd:string = undefined;
	/**
	 * Currency Exchange Rate
	 */
	currencyExchangeRate:number = undefined;
	/**
	 * Class Override Code
	 */
	classOverrideCd:string = undefined;
	/**
	 * Multiple Class Indicator
	 */
	multipleClassInd:string = undefined;
	/**
	 * Invoicing Currency Code
	 */
	invoicingCurrencyCd:InvoiceCurrencyCd = undefined;
	/**
	 * Reduced Weight
	 */
	reducedWeight:number = undefined;
	/**
	 * Rating Origin Zip Code
	 */
	ratingOrigZipCd:string = undefined;
	/**
	 * Rating Destination Zip Code
	 */
	ratingDestZipCd:string = undefined;
	/**
	 * Tariff Type Code
	 */
	tariffTypeCd:string = undefined;
	/**
	 * Revenue Amount
	 */
	ctsRevenueAmt:number = undefined;
	/**
	 * Revenue Amount in USD
	 */
	ctsRevenueAmtInUsd:number = undefined;
	/**
	 * Volume Shipment Base Charge Amount
	 */
	trueLtlPricingBaseChargeAmt:number = undefined;
	/**
	 * Volume Shipment Factor Number
	 */
	trueLtlPricingFactorNbr:number = undefined;
	/**
	 * Volume Shipment Miles Rate
	 */
	trueLtlPricingPerMileRate:number = undefined;
	/**
	 * Absolute Minimum Charge Amount
	 */
	absoluteMinimumChargeAmt:number = undefined;
	/**
	 * Rating Tariff Effective Date
	 */
	ratingTariffEffectiveDate:string = undefined;
	/**
	 * Absolute Minimum Charge Indicator
	 */
	absoluteMinimumChargeInd:boolean = undefined;
	/**
	 * Corporate Absolute Minimum Charge Indicator
	 */
	corporateAbsoluteMinimumChargeInd:string = undefined;
	/**
	 * The unique identifier for the Dynamic Pricing Rule.
	 */
	dpRuleHeaderId:number = undefined;
	/**
	 * The Dynamic Pricing external rule ID for Dynamic Pricing evaluation.
	 */
	dpExternalRuleId:string = undefined;
	/**
	 * The calculated CDI (Common Discount Impact) percentage from the applicable pricing agreement.
	 */
	agreementCdiPercentage:number = undefined;
	/**
	 * This indicates whether the dynamic shipment is rated with Absolute Minimum Charge (AMC).  Permitted values: DP_RULE, DP_GLOBAL, AGREMENT, and TARIFF.
	 */
	amcAppliedCd:string = undefined;
	/**
	 * This indicates whether there are dynamic pricing accessorials. Permitted values: DP_RULE, DP_GLOBAL, AGREMENT, and CNWY199.
	 */
	accessorialAppliedCd:string = undefined;
}
export class RatingRule {
	/**
	 * The name for the rate rules.
	 */
	rateRulesName:string = undefined;
	/**
	 * The value associated with the rate rules.
	 */
	rateRulesValue:string = undefined;
}
export class RatingShipmentRequest {
	proNbr:string = undefined;
	pickupDate:string = undefined;
	estimatedDeliveryDate:string = undefined;
	destTerminalSic:string = undefined;
	origTerminalSic:string = undefined;
	materializedPcs:number = undefined;
	hazmatInd:boolean = undefined;
	freezableInd:boolean = undefined;
	totalPalletCnt:number = undefined;
	shipmentInstId:number = undefined;
	codAmt:number = undefined;
	manualRatingRequiredInd:boolean = undefined;
	invoiceCurrencyCd:InvoiceCurrencyCd = undefined;
	spotQuoteNbr:number = undefined;
	floorSpace:number = undefined;
	floorSpaceUom:string = undefined;
	equipmentTypeCd:EquipmentTypeCd = undefined;
	shipmentValue:number = undefined;
	dimensions:Dimensions = undefined;
	dimensionMeasurement:DimensionMeasurement = undefined;
	diameter:number = undefined;
	grossWeight:Weight = undefined;
	linealFootTotalNbr:number = undefined;
	linealUom:string = undefined;
	totalVolume:number = undefined;
	totalVolumeUom:string = undefined;
	/**
	 * The code that is associated with the party that is responsible for paying for the shipment.
	 */
	chargeToCd:ChargeToCd = undefined;
	/**
	 * This indicates whether the shipment is under the XPO Guaranteed Service Program.
	 */
	guaranteedInd:boolean = undefined;
	/**
	 * This overrides the rating tariff that is specified in the rule set during rating.
	 */
	overrideRatingTariff:string = undefined;
	/**
	 * The overriding discount percentage.
	 */
	overrideDiscountPercentage:number = undefined;
	/**
	 * The Absolute Minimum Charged (AMC) to be used during rating.
	 */
	overrideAbsoluteMinimumChargeAmount:number = undefined;
}
export class RatingShipmentResponse {
	/**
	 * codAmt
	 */
	codAmt:number = undefined;
	/**
	 * Shipper To Consignee Miles
	 */
	totalDistance:number = undefined;
	/**
	 * Number of Trailers
	 */
	trailerCnt:number = undefined;
	/**
	 * Offshore US Port Identifier
	 */
	offshoreUSPort:string = undefined;
	/**
	 * Offshore Zone Code
	 */
	offshoreZoneCd:number = undefined;
	/**
	 * Carrier SCAC Code
	 */
	scac:string = undefined;
	/**
	 * Carrier SCAC Name
	 */
	carrierName:string = undefined;
	/**
	 * Warranty Eligible Indicator
	 */
	warrantyInd:boolean = undefined;
	/**
	 * Spot Quote instance id
	 */
	spotQuoteInstId:number = undefined;
}
export class RatingTotal {
	/**
	 * Total Accessorial Amount
	 */
	totalAccessorialAmt:number = undefined;
	/**
	 * Total Charge Amount
	 */
	totalChargeAmt:number = undefined;
	/**
	 * Linehaul Charge Amount
	 */
	linehaulChargeAmt:number = undefined;
	/**
	 * Total Discount Amount
	 */
	totalDiscountAmt:number = undefined;
	/**
	 * Total FSC Amount
	 */
	totalFscAmt:number = undefined;
	/**
	 * Total Tax Amount
	 */
	totalTaxAmt:number = undefined;
	/**
	 * Total Weight
	 */
	totalWeight:number = undefined;
	/**
	 * Offshore Accessorial Amount
	 */
	offshoreAccessorialChargeAmt:number = undefined;
	/**
	 * Offshore Linehaul Charge Amount
	 */
	offshoreLinehaulChargeAmt:number = undefined;
	/**
	 * Offshore FSC Charge Amount
	 */
	offshoreFscChargeAmt:number = undefined;
	/**
	 * Total Charge Amount in USD
	 */
	totalChargeAmtInUsd:number = undefined;
}
export class TransactionContext {
	/**
	 * Details of the user making this request
	 */
	user:User = undefined;
	/**
	 * The authorization token used to invoke this API
	 */
	authorization:string = undefined;
	/**
	 * The ID of the application using this API
	 */
	srcApplicationId:string = undefined;
	/**
	 * Http Method
	 */
	httpMethod:string = undefined;
	/**
	 * Passed from f5 to find out where they came from
	 */
	xForwardedFor:string = undefined;
	/**
	 * A unique ID for the business transaction.
	 * The purpose of this id is to tie multiple computer transactions across multiple technologies for a given business transaction.
	 * The scope of the business transaction is determined by the initiator of the transaction.
	 * The same id should be used across multiple tiers for a given business transaction.
	 * For example, bill entry will use the same correlationId to record the bill details in the database and by TIBCO in backsyncing to DB2. 
	 * This will generated by the API gateway if one is not passed by the client.
	 */
	correlationId:string = undefined;
	/**
	 * A unique ID for the database transaction.
	 * This will be generated by the REST resource bean before invoking the EJB.
	 */
	transactionId:string = undefined;
}
/**
 * Conversation message details.
 */
export class XFlowConversation {
	applicationInstId:string = undefined;
	conversationId:number = undefined;
	participants:string[] = undefined;
	message:XFlowMessage[] = undefined;
}
/**
 * Conversation message details.
 */
export class XFlowConversationGroup {
	groupId:number = undefined;
	groupOwner:string = undefined;
	groupDescription:string = undefined;
	groupCreateBy:string = undefined;
	participants:string[] = undefined;
	conversations:XFlowConversation[] = undefined;
	messages:XFlowMessage[] = undefined;
}
/**
 * Item information.
 */
export class XFlowItem {
	itemInstId:number = undefined;
	queueName:string = undefined;
	itemStatus:string = undefined;
	priorityCd:string = undefined;
}
/**
 * Item Count.
 */
export class XFlowItemCount {
	queueName:string = undefined;
	newItemCount:number = undefined;
}
/**
 * Conversation message details.
 */
export class XFlowMessage {
	messageId:number = undefined;
	text:string = undefined;
	attachmentURL:string = undefined;
	sender:string = undefined;
	date:Date = undefined;
	groupId:number = undefined;
	items:XFlowItem[] = undefined;
}
